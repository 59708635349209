<app-modal 
  header="ATTENTION" 
  [(visible)]="visible"
  rejectText="Close">
  <div class="text-center">
    This chase has an open pend. Please resolve or close prior to submission.<br /><br/>
    <span class="bold">Pend </span><a (click)="openPendModal()">{{chaseDetailState.chasePendId}}</a> -
    {{pendInfo}}
  </div>
</app-modal>

<app-pend-modal
  class="resolve-pend-warning-modal__pend-modal"
  [(visible)]="isPendModalVisible" 
  (onHide)="closeModal()"
  (onUpdate)="onUpdate.emit()"
  [(chases)]="selectedChases" 
  [clinical]="clinical" 
  [pageType]="pageType">
</app-pend-modal>  
