import { IAutoMapper } from "../../../../../../core/automapper/interfaces";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { DynamicEntityAttribute } from "../../../../../api/member-validation/dynamic-entity-attribute.model";

export const mapNonCompliantInfo = (automapper: IAutoMapper): void => {
  automapper
    .createMap("ExclusionAndContra", "NonCompliantInfo")
    .forMember("reasonInfo", o => {
      const options = JSON.parse(o?.reason?.options).map(automapper.curry("default", "SelectableInput"));
      o.reason.options = options;
      return automapper.map("default", "DynamicEntityAttribute", o.reason);
    })
    .forMember("dateInfo", o => automapper.map("default", "DynamicEntityAttribute", o.date))
    .forMember("dateOfDeathInfo" , o => {
      if (!o.dateOfDeath) { return null; } // skip mapping when property not available.
      return automapper.map("default", "DynamicEntityAttribute", o.dateOfDeath);
    })
    .forMember("pageNumberInfo", o => automapper.map("default", "DynamicEntityAttribute", o.pageNumber));
};

export class NonCompliantInfo {
  reasonInfo: DynamicEntityAttribute;
  dateInfo: DynamicEntityAttribute;
  dateOfDeathInfo: DynamicEntityAttribute;
  pageNumberInfo: DynamicEntityAttribute;


  constructor(options: {
    reasonInfo?: DynamicEntityAttribute;
    dateInfo?: DynamicEntityAttribute;
    dateOfDeathInfo?: DynamicEntityAttribute;
    pageNumberInfo?: DynamicEntityAttribute;
  } = {}) {
    this.reasonInfo = options.reasonInfo || new DynamicEntityAttribute();
    this.dateInfo = options.dateInfo || new DynamicEntityAttribute();
    this.dateOfDeathInfo = options.dateOfDeathInfo || new DynamicEntityAttribute();
    this.pageNumberInfo = options.pageNumberInfo || new DynamicEntityAttribute();
  }


  get reason(): string {
    return this.reasonInfo.value as string || "";
  }
  set reason(value: string) {
    this.reasonInfo.value = value;
  }

  get reasonOptions(): SelectableInput[] {
    return this.reasonInfo.options;
  }

  set reasonOptions(options: SelectableInput[]) {
    this.reasonInfo.options = options;
  }

  get date(): string {
    return this.dateInfo.value as string || "";
  }
  set date(value: string) {
    this.dateInfo.value = value;
  }

  get dateOfDeath(): string {
    return this.dateOfDeathInfo.value as string || "";
  }
  set dateOfDeath(value: string) {
    this.dateOfDeathInfo.value = value;
  }

  get pageNumber(): number {
    return +this.pageNumberInfo.value || null;
  }
  set pageNumber(value: number) {
    this.pageNumberInfo.value = value;
  }


  getAttributes(): DynamicEntityAttribute[] {
    const reasonInfo = new DynamicEntityAttribute(this.reasonInfo);
    reasonInfo.options = null;

    const dateInfo = new DynamicEntityAttribute(this.dateInfo);
    dateInfo.options = null;

    const dateOfDeathInfo = new DynamicEntityAttribute(this.dateOfDeathInfo);
    dateOfDeathInfo.options = null;

    const pageNumberInfo = new DynamicEntityAttribute(this.pageNumberInfo);
    pageNumberInfo.options = null;
    return [reasonInfo, dateInfo, dateOfDeathInfo, pageNumberInfo];
  }
}
