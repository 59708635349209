import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { filter, take } from "rxjs/operators";
import { SubSink } from "subsink";
import { UserToken } from "../../../../../../../auth/user-token.model";
import { MessagingService } from "../../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../../core/messaging/severity-type.enum";
import { LocalService } from "../../../../../../../core/storage/local.service";
import { UserService } from "../../../../../../../core/user/user.service";
import { ListItem } from "../../../../../../../shared/list/list-item";
import { PendStatus } from "../../../../../../../shared/pend/pend-status.enum";
import { ArrayHelper } from "../../../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../../../utilities/contracts/number-helper";
import { ChaseSearchRequest } from "../../../../../../api/chase-search/chase-search-request-model";
import { ChaseSearchService } from "../../../../../../api/chase-search/chase-search.service";
import { EntityError } from "../../../../../../api/member-validation/entity-error.model";
import { WorkflowStatusDb } from "../../../../../../api/workflow/workflow-status-db.enum";
import { ClinicalService } from "../../../../../dashboard/clinical/clinical.service";
import { FunctionalRole } from "../../../../../dashboard/retrieval/functional-role.enum";
import { ChartService } from "../../../../chase-detail/chase-detail-chart/chart.service";
import { RiskError } from "../../../../chase-detail/chase-detail-chart/risk/risk-error-modal/risk-error.model";
import { RiskState } from "../../../../chase-detail/chase-detail-chart/risk/risk-state.model";
import { RiskService } from "../../../../chase-detail/chase-detail-chart/risk/risk.service";
import { ChaseDetailState } from "../../../../chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../../../chase-detail/chase-detail-state.service";
import { ChaseDetailService } from "../../../../chase-detail/chase-detail.service";
import { DocumentViewerSessionService } from "../../../document-viewer-session.service";
import { ChaseDetailV2ChartRiskService } from "../risk.service";

@Component({
  selector: "member-risk-submit",
  templateUrl: "./risk-submit.component.html",
  styleUrls: ["./risk-submit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskSubmitComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  private pdfScreenTab = "chasedetailWindow";
  chaseDetails: ListItem[] = [];
  chaseDetailState: ChaseDetailState;
  chaseId: number;
  formGroup = new FormGroup({});
  isResolvePendWarningVisible = false;
  localStorageIsWindowOpen = "isWindowOpen";
  localStorageKey = "popOutAction";
  mainTab: any = null;
  user: UserToken;
  winRef: any;
  isReadOnly = false;
  errorsChecked = false;
  private openChaseAndUserInfo = "openChaseAndUserInfo";

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly chartService: ChartService,
    private readonly chaseDetailService: ChaseDetailService,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly chaseSearchService: ChaseSearchService,
    private readonly documentViewerSessionService: DocumentViewerSessionService,
    private readonly localService: LocalService,
    private readonly messagingService: MessagingService,
    private readonly riskService: RiskService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly chaseDetailV2ChartRiskService: ChaseDetailV2ChartRiskService,
    private readonly clinicalService: ClinicalService
  ) { }

  get status(): string {
    const statusItem = this.chaseDetails.find(item => item.key === "Workflow Status");
    return statusItem ? statusItem.value : "";
  }

  get hasUnresolvedPend(): boolean {
    return this.chaseDetailState.hasPend &&
      (this.chaseDetailState.pendStatusId === PendStatus.New || this.chaseDetailState.pendStatusId === PendStatus.InProgress);
  }

  get isOverread(): boolean {
    return this.chaseDetailState.workflowStatus === WorkflowStatusDb.Overread;
  }

  get isOverread2(): boolean {
    return this.chaseDetailState.workflowStatus === WorkflowStatusDb.Overread2;
  }

  get functionalRoleId(): number {
    switch (this.chaseDetailState.workflowStatus) {
      case WorkflowStatusDb.Abstraction:
        return FunctionalRole.ABSTRACTOR;
      case WorkflowStatusDb.Overread:
        return FunctionalRole.OVERREAD;
      case WorkflowStatusDb.Overread2:
        return FunctionalRole.CLIENTOVERREAD;
      default:
        return 0;
    }
  }

  ngOnInit(): void {
    this.user = this.userService.getUserToken();
    this.sink.add(
      this.riskService.data
        .pipe(
          filter(riskState => NumberHelper.isGreaterThan(riskState.encounterCount, 0)),
          take(1)
        )
        .subscribe(riskState => this.enableSubmitTab(riskState)),
      this.activatedRoute.parent.paramMap.subscribe(params => {
        this.chaseId = NumberHelper.isGreaterThan(+params.get("chaseGd"), 0) ? +params.get("chaseGd") : 0;
      }),
      this.chaseDetailService.chaseDetailsChangeObserver.subscribe(chaseDetails => {
        this.chaseDetails = chaseDetails;
        // this.getMemberValidation();
      }),
      this.chaseDetailStateService.state.subscribe(state => {
        this.chaseDetailState = state;
        // this.riskService.setData({ assignedToCurrentUser: this.assignedToCurrentUser });
        this.changeDetector.markForCheck();
      }),
      this.riskService.data.subscribe(riskState => {
        if (ArrayHelper.isAvailable(riskState.errors) && !this.errorsChecked && NumberHelper.isGreaterThan(riskState.errors.length, 1)) {
          this.submit();
        } else if (ArrayHelper.isAvailable(riskState.errors) && !this.errorsChecked && riskState.errors.length === 1) {
          this.riskService.setData({ errors: [] });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  submit(): void {

    if (this.isReadOnly) {
      return;
    }

    if (this.hasUnresolvedPend && (this.isOverread || this.isOverread2)) {
      this.isResolvePendWarningVisible = true;
      return;
    }

    this.submitAndRouteToDashboard();

    // TODO: We should add formgroup validation in the encounter tab section.
    // const valid = this.getValidation();

    // if (valid) {
    //   if (this.formGroup.valid) {
    //     this.submitAndRouteToDashboard();
    //   } else {
    //     this.markAllAsDirtyAndTouched(this.formGroup);
    //     this.formService.updateDom.next();
    //   }
    // } else {
    //   this.messagingService.showToast("Please complete the checklist.", SeverityType.ERROR);
    // }
  }

  updateChaseData() {
    this.sink.add(
      this.chaseDetailService.getSummaryItems(this.chaseId)
        .subscribe(items => {
          this.chaseDetailService.chaseDetailsChange.next(items);
          this.setPendDetails(items);
        })
    );
  }

  setPendDetails(items: ListItem[]): void {
    items.forEach(x => {
      switch (x.key) {
        case "PendStatusId":
          this.chaseDetailStateService.setData({
            pendStatusId: Number(x.value),
            hasPend: NumberHelper.isGreaterThan(Number(x.value), 0),
          });
          break;
        case "Pend Code":
          this.chaseDetailStateService.setData({
            pendCode: x.value,
          });
          break;
        case "PendCodeName":
          this.chaseDetailStateService.setData({
            pendName: x.value,
          });
          break;
        case "ChasePendId":
          this.chaseDetailStateService.setData({
            chasePendId: Number(x.value),
          });
          break;
        case "PendStatusName":
            this.chaseDetailStateService.setData({
              pendStatusName: x.value,
            });
            break;
        case "Status":
          this.chaseDetailStateService.setData({ reportingStatusName: x.value });
          break;
        default:
          break;
      }
    });
  }

  private submitAndRouteToDashboard(): void {

    this.chartService.submit2(this.chaseId, this.status).subscribe(entityErrors => {
      this.errorsChecked = true;
      if (ArrayHelper.isAvailable(entityErrors)) {
        const errors = entityErrors.map(this.getRiskError.bind(this)) as RiskError[];
        this.riskService.setData({ errors });
      } else {
        this.messagingService.showToast("Submission Succeeded!", SeverityType.SUCCESS);
        this.removeOpenChaseInfo();
        this.submitNavigation();
      }
    });
  }

  private removeOpenChaseInfo(): void {
    const localChaseUserInfo =  this.localService.get(this.openChaseAndUserInfo, null);
    if (localChaseUserInfo) {
      this.localService.delete(this.openChaseAndUserInfo);
    }
  }

  private enableSubmitTab(riskState: RiskState): void {
    if (!riskState.assignedToCurrentUser) {
      this.isReadOnly = true;
      return;
    }

    const enable = this.chaseDetailV2ChartRiskService.isSubmitEnabled(riskState);
    if (!enable) {
      this.router.navigate(["../"], { relativeTo: this.activatedRoute });
    }
  }

  private getRiskError(entityError: EntityError): RiskError {
    const entity = this.riskService.data.value.encounters.reduce(
      (acc, encounter) => {
        if (acc != null) {
          return acc;
        } else if (encounter.entityId === entityError.entityId) {
          return encounter;
        } else {
          return encounter.diagnoses.find(diagnosis => diagnosis.entityId === entityError.entityId);
        }
      },
      null);

    const riskError = new RiskError({
      entityId: entity?.entityId,
      parentEntityId: entity?.parentEntityId,
      message: entityError.validationMessage,
    });

    return riskError;
  }

  private markAllAsDirtyAndTouched = (control: AbstractControl): void => {
    control.markAsDirty();
    control.markAsTouched();

    const controls = (control as any).controls || {};
    const keys = Object.keys(controls);
    if (ArrayHelper.isAvailable(keys)) {
      keys.forEach(key => this.markAllAsDirtyAndTouched(controls[key]));
    }
  }

  private submitNavigation(): void {
    this.getQueueOrNextChase();
  }

  private getQueueOrNextChase(): void {
    const request = new ChaseSearchRequest(
      null, null, null, this.functionalRoleId, null, this.user.userId, null, null, null, null, null, null, 0, 1, null, null, null, null, null, this.chaseDetailState.reportingStatusName
    );
    this.chaseSearchService.chaseSearch(request).subscribe(data => {
      if (ArrayHelper.isAvailable(data)) {
        this.navigateToChase(data[0].chaseId, true);
      } else {
        this.router.navigate(["dashboard", "clinical"]);
      }
    });
  }

  private navigateToChase(chaseId: number, reload: boolean = false): void {
    const path = "/members/chase";

    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([`${path}/${chaseId}`], { state: { reload } });
      this.handleNavigateAfterSubmit(path, chaseId);
    }
    );
  }

  private handleNavigateAfterSubmit(path: string, chaseId: number) {
    if (this.mainTab && this.localService.get(this.localStorageIsWindowOpen, null) === "1") {
      // api call only in collapse view
      const pdfThumbnailsListView: any = document.getElementsByClassName("thumbnail__singleExpand")[0];
      if (pdfThumbnailsListView) {
        pdfThumbnailsListView.style.setProperty("display", "block");
      }
      setTimeout(
        () => {
          this.localService.delete(this.localStorageKey);
          this.openOnce(`${window.location.origin}/${path}/${chaseId}`, this.pdfScreenTab);
          // select the pdf icon for collapse view
          const pdfThumbnails: any = document.getElementsByClassName("thumbnail__singleExpand")[0].children[0];
          pdfThumbnails.click();
          if (pdfThumbnailsListView) {
            pdfThumbnailsListView.style.setProperty("display", "none");
          }
        },
        500);
      setTimeout(
        () => {
          this.localService.put(this.localStorageKey, "false");
          this.localService.put(this.localStorageIsWindowOpen, "1");
        },
        1000);
    }
  }

  private openOnce(url, target) {
    // open a blank "target" window
    // or get the reference to the existing "target" window
    this.winRef = window.open("", target, "");
    // if the "target" window was just opened, change its url
    if (this.winRef.location.href !== url) {
      this.winRef.location.href = url;
    }
    return this.winRef;
  }

}
