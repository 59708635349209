import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { ActionButton } from "../../../../../../zdevcontrols/action-button/action-button.model";
import { DevControllerService } from "../../../../../../zdevcontrols/dev-controller/dev-controller.service";
import { DynamicEntityAttribute } from "../../../../../api/member-validation/dynamic-entity-attribute.model";
import { ChartService } from "../chart.service";
import { ExlcusionAndContra } from "./exclusion-and-contra.model";
import { NonCompliantInfo } from "./non-compliant-info.model";

@Component({
  selector: "member-exclusion-and-contra",
  templateUrl: "./exclusion-and-contra.component.html",
  styleUrls: ["./exclusion-and-contra.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExclusionAndContraComponent implements OnInit, OnChanges {
  private getExclusionAndContraSubscription: Subscription;
  private contraAndExclusion = new ExlcusionAndContra();
  private chaseId: number;
  private reasonOptions: SelectableInput[] = [];
  @Input() enabled = true;
  @Input() chaseIdForAudit: number;
  @Input() workflowStatus: string;
  @Input() isOverread = false;
  @Output() onErrorExCon = new EventEmitter<string>();
  @Output() onLoadExclusions = new EventEmitter<boolean>();
  contrasAndExclusions: NonCompliantInfo[] = [];

  private pMeasureCode;
  @Input() set measureCode(value: string) {
    this.pMeasureCode = value;
    this.getExclusionAndContra();
  }

  get measureCode(): string {
    return this.pMeasureCode;
  }

  private pMeasureYear;
  @Input() set measureYear(value: number) {
    this.pMeasureYear = value;
    this.getExclusionAndContra();
  }

  get measureYear(): number {
    return this.pMeasureYear;
  }

  get areOptionsAvailable(): boolean {
    return ArrayHelper.isAvailable(this.reasonOptions);
  }

  constructor(
    private readonly route: ActivatedRoute,
    private readonly chartService: ChartService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly devControllerService: DevControllerService
  ) { }

  ngOnInit() {
    this.route.parent.paramMap.subscribe(params => {

      if ((NumberHelper.isGreaterThan(+params.get("chaseGd"), 0))) {
        this.chaseId = +params.get("chaseGd");
      } else if ((NumberHelper.isGreaterThan(this.chaseIdForAudit, 0))) {
        this.chaseId = this.chaseIdForAudit;
      } else {
        this.chaseId = 0;
      }

      this.getExclusionAndContra();
    });

    this.devControllerService.push([
      new ActionButton({
        name: "Refresh Exclusion and Contra",
        action: () => {
          this.getExclusionAndContra();
        },
      }),
    ]);

    this.chartService.addExclusionEvent$().subscribe(() => {
      if (!this.areOptionsAvailable) {
        this.onErrorExCon.emit("No exclusions available");
        return;
      }
      const exclusion = this.getCleanExclusion();
      this.contrasAndExclusions.push(exclusion);
      this.changeDetector.markForCheck();
    });
  }

  private getCleanExclusion(): NonCompliantInfo {
    const exclusion = new NonCompliantInfo();
    Object.keys(exclusion).forEach((key: keyof NonCompliantInfo) => {
      (exclusion[key] as DynamicEntityAttribute).chaseId = this.chaseId;
      (exclusion[key] as DynamicEntityAttribute).entityTypeId = 20;
      switch (key) {
        case "reasonInfo":
          exclusion[key].attributeCode = "ExclusionType";
          exclusion[key].attributeId = 148;
          break;
        case "dateInfo":
          exclusion[key].attributeCode = "ExclusionDate";
          exclusion[key].attributeId = 62;
          break;
        case "dateOfDeathInfo":
          exclusion[key].attributeCode = "DateOfDeath";
          exclusion[key].attributeId = 572;
          break;
        case "pageNumberInfo":
          exclusion[key].attributeCode = "ChartPageNumber";
          exclusion[key].attributeId = 28;
          break;
        default:
          break;
      }
    });
    exclusion.reasonOptions = [...this.reasonOptions];
    return exclusion;
  }

  onExclusionContraError(event: string): void {
    this.onErrorExCon.emit(event);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.workflowStatus) {
      if (changes.workflowStatus.isFirstChange()) { return; }

      if (changes.workflowStatus.currentValue !== changes.workflowStatus.previousValue) {
        this.getExclusionAndContra();
      }
    }

  }

  getExclusion(item: ExlcusionAndContra): NonCompliantInfo {
    return item.exclusion;
  }

  get contra(): NonCompliantInfo {
    return this.contraAndExclusion.contra;
  }

  get lessThanSeven(): NonCompliantInfo {
    return this.contraAndExclusion.lessThanSeven;
  }

  get hasLessThanSeven(): boolean {
    return this.contraAndExclusion.hasLessThanSeven;
  }


  getExclusionAndContra(): void {
    if (
      StringHelper.isAvailable(this.measureCode) &&
      NumberHelper.isGreaterThan(this.chaseId, 0) &&
      NumberHelper.isGreaterThan(this.measureYear, 0)
    ) {
      if (this.getExclusionAndContraSubscription) {
        this.getExclusionAndContraSubscription.unsubscribe();
      }

      this.getExclusionAndContraSubscription = this.chartService
        .getExclusionAndContra(this.chaseId, this.measureCode, this.measureYear, this.workflowStatus)
        .subscribe(data => {
          this.contraAndExclusion = data;
          this.contrasAndExclusions = [...data.exclusionList];
          this.reasonOptions = [...data.exclusionOptions];
          this.changeDetector.markForCheck();
          if (!this.areOptionsAvailable) {
            this.onLoadExclusions.emit(false);
            return;
          }
          this.onLoadExclusions.emit(true);
        });
    }
  }

  onDelete(index: number): void {
    this.contrasAndExclusions.splice(index, 1);
    this.contrasAndExclusions = [...this.contrasAndExclusions];
    this.changeDetector.markForCheck();
  }

  onSave(event: { index: number; exclusion: NonCompliantInfo}): void {
    this.contrasAndExclusions[event.index] = event.exclusion;
    this.contrasAndExclusions = [...this.contrasAndExclusions];
    this.changeDetector.markForCheck();
  }

  trackByIndex(index) {
    return index;
  }
}
