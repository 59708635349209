// tslint:disable:max-line-length
import { LookerDashboardType } from "../looker-dashboard-type.enum";
import { HideFilters } from "../models/analytics-hide-filters.enum";
import { AnalyticsHedisModel } from "./analytics-hedis.model";

export class AnalyticsHedisDashboard {


  private static readonly additionalFilters = ["Project", "MemberId", "MemberFirstName", "MemberLastName", "MemberDob", "AbstractionDate", "MemberKey", "Address", "NPI", "ChaseId"];
  private static readonly hedisDashboards = {
    "/analytics/hedis/overview": _ => new AnalyticsHedisModel({ dashboardType: LookerDashboardType.HedisNew,
                                                                filters: ["Project", "Measure", "Address", "HealthPlan", "ContractNumber", "Products", "LOB", "RetrievalOwner"] }),
    "/analytics/hedis/retrieval/summary": _ => new AnalyticsHedisModel({ dashboardType: LookerDashboardType.RetrievalSummaryNew,
                                                                         filters: ["Project", "Measure", "Address", "HealthPlan", "ContractNumber", "Products", "LOB", "RetrievalOwner", "ExpectedRetrieval", "ProjectStatus", "VendorName", "SpecialHandling", "RetrievedDate", "VendorInvoiceType"] }),
    "/analytics/quality/dw/retrieval/summary": _ => new AnalyticsHedisModel({ dashboardType: LookerDashboardType.RetrievalSummaryDw,
                                                                              filters: ["Project"], hideFilters: [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.PROJECT_NAME2, HideFilters.CUSTOMER_NAME, HideFilters.PROJECT_TYPE_ID2, HideFilters.TEMPLATE_ID2] }),
    "/analytics/hedis/retrieval/psr": _ => new AnalyticsHedisModel({ dashboardType: LookerDashboardType.PSR }),
    "/analytics/hedis/retrieval/emr": _ => new AnalyticsHedisModel({ dashboardType: LookerDashboardType.EMR }),
    "/analytics/hedis/retrieval/ft": _ => new AnalyticsHedisModel({ dashboardType: LookerDashboardType.FT }),
    "/analytics/hedis/retrieval/docintake": _ => new AnalyticsHedisModel({ dashboardType: LookerDashboardType.DocIntake }),
    "/analytics/hedis/retrieval/docqa": _ => new AnalyticsHedisModel({ dashboardType: LookerDashboardType.DocQA }),
    "/analytics/hedis/pends/summary": _ => new AnalyticsHedisModel({ dashboardType: LookerDashboardType.PendSummaryNew,
                                                                     filters: ["Project", "Measure", "Address", "HealthPlan", "ContractNumber", "PendOwner", "Products", "LOB", "RetrievalOwner", "PendBy", "PendDate"] }),
    "/analytics/hedis/reports/open_gaps_by_member": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.OpenGapsByMember, filters: AnalyticsHedisDashboard.additionalFilters });
    },
    "/analytics/hedis/reports/open_gaps_by_measure": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.OpenGapsByMeasure,
        filters: ["Project", "Measure", "MemberId", "MemberFirstName", "MemberLastName", "MemberDob", "AbstractionDate", "MemberKey", "Address", "NPI", "ChaseId"],
      });
    },
    "/analytics/hedis/gap_status": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.GapStatus, filters: ["HealthPlan", "Measure", "ContractNumber", ...AnalyticsHedisDashboard.additionalFilters] });
    },
    "/analytics/quality/dw/gap_status": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.GapStatusDW,
        filters: ["Project"],
        hideFilters: [HideFilters.PROJECT_ID, HideFilters.ORG_ID, HideFilters.USER_ID, HideFilters.PROJECT_TYPE_ID, HideFilters.TEMPLATE_ID, "Project RetrievalType"] });
    },
    "/analytics/quality/reports/dw/sample_compliance_report": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.SampleComplianceReportDW,
        filters: ["Project"],
        hideFilters: [HideFilters.PROJECT_ID, HideFilters.ORG_ID, HideFilters.USER_ID] });
    },
    "/analytics/hedis/member_additional_details": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.MemberAdditionalDetails,
        filters: ["Project", "Measure", "MemberId", "MemberFirstName", "MemberLastName", "MemberDob", "Address", "AbstractionDate", "ChaseId"],
      });
    },
    "/analytics/hedis/reports/project_summary": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.HedisProjectSummary, filters: ["Project", "Measure"] });
    },
    "/analytics/hedis/reports/quality_nlp_performance_by_gap": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.BotOverreadPerformanceByGap, filters: ["Project", "Measure", "Gap", "ResponseDate", "HealthPlan", "ContractNumber"] });
    },
    "/analytics/hedis/reports/quality_nlp_performance_by_member": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.BotOverreadPerformanceByMember, filters: ["Project", "MemberKey", "MemberId", "ResponseDate", "HealthPlan", "ContractNumber", "Measure"],
      });
    },
    "/analytics/hedis/reports/quality_nlp_performance_by_provider": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.BotOverreadPerformanceByProvider, filters: ["Project", "Address", "NPI", "ResponseDate", "HealthPlan", "ContractNumber", "Measure"] });
    },
    "/analytics/hedis/reports/quality_nlp_results_overview": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.BotOverreadResultAnalysis,
        filters: ["Project", "MemberId", "Measure", "AbstractionDate", "MemberKey", "Address", "NPI", "ChaseId", "Gap", "ResponseDate", "HealthPlan", "ContractNumber"],
      });
    },
    "analytics/hedis/clinical_productivity_overview": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ClinicalProductivityOverview, filters: ["Project", "Measure", "Address"],
      });
    },
    "/analytics/hedis/reports/pend_report": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.PendReport, filters: ["Project", "PendDate", "PendStatus", "PendCode", "HealthPlan", "ContractNumber", "PendOwner", "PendBy"],
      });
    },
    "/analytics/hedis/gap_performance": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.GapPerformance, filters: ["Project", "MemberId", "MemberFirstName", "MemberLastName", "MemberDob", "Address", "ChaseId"],
      });
    },
    "/analytics/hedis/chart_routing": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ChartRouting, filters: ["CreateDate"],
      });
    },

    "/analytics/hedis/clinical/summary": _ => new AnalyticsHedisModel({ dashboardType: LookerDashboardType.ClinicalSummaryNew,
                                                                        filters: [
                                                                          "Project",
                                                                          "Measure",
                                                                          "Address",
                                                                          "HealthPlan",
                                                                          "ContractNumber",
                                                                          "Products",
                                                                          "LOB",
                                                                          "RetrievalOwner",
                                                                          "AbstractionDate",
                                                                          "AbstractionBy",
                                                                        ] }),
    "/analytics/hedis/clinical/mrr": _ => new AnalyticsHedisModel({ dashboardType: LookerDashboardType.MRR }),
    "/analytics/hedis/clinical/or1": _ => new AnalyticsHedisModel({ dashboardType: LookerDashboardType.OR1 }),
    "/analytics/hedis/clinical/or2": _ => new AnalyticsHedisModel({ dashboardType: LookerDashboardType.OR2 }),
    "/analytics/hedis/reports/dw/member_measure_compliance_report": () => new AnalyticsHedisModel(
      { dashboardType: LookerDashboardType.MemberComplianceReport, filters: ["Project", "Measure"] }),
    "/analytics/hedis/reports/dw/eve_quality_match_rate_report": () => new AnalyticsHedisModel(
      { dashboardType: LookerDashboardType.QualityMatchRateReport, filters: ["Project", "Measure"],
        hideFilters: [HideFilters.PROJECT_ID, HideFilters.ORGANIZATION_ID, HideFilters.USER_ID, HideFilters.PROJECT_NAME, HideFilters.MEASURE2, HideFilters.IS_TEST_PROJECT, HideFilters.VALIDATION_RESULT] }),
    "/analytics/hedis/reports/project_status_report": _ => new AnalyticsHedisModel({ dashboardType: LookerDashboardType.ProjectStatus,
                                                                                     filters: ["Project", "HealthPlan", "ContractNumber", "Products", "LOB", "RetrievalOwner"] }),
    "/analytics/hedis/reports/top50_aids_with_open_chases": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.Top50AIDsWithOpenChases,
                                       filters: ["Project", "Products", "LOB", "RetrievalOwner", "Measure"] });
    },
    "/analytics/hedis/reports/sample_compliance_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.SampleComplianceReport, filters: ["Project", "Measure", "SampleCompliance", "Measure"] });
    },
    "/analytics/hedis/reports/coder_productivity_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.CoderProductivityReport,
                                       filters: ["Project", "HealthPlan", "ContractNumber", "Products", "LOB", "RetrievalOwner", "Measure", "UserName", "CompletionDate"] });
    },
    "/analytics/hedis/reports/member_measure_non_compliant_summary_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.MemberMeasureNonCompliantSummaryReport, filters: ["Project", "Measure"] });
    },
    "/analytics/hedis/reports/training_feedback_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.TrainingFeedbackReport,
                                       filters: ["Project", "HealthPlan", "ContractNumber", "Products", "LOB", "RetrievalOwner", "Measure"] });
    },
    "/analytics/hedis/reports/member_measure_compliance_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.MemberMeasureComplianceReport, filters: ["Project", "Measure"] });
    },
    "/analytics/hedis/reports/chases_in_delivery_with_images": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.ChasesInDeliveryWithImages, filters: ["Project"] });
    },
    "/analytics/hedis/reports/record_count_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.RecordCountReport, filters: ["Project"] });
    },
    "/analytics/hedis/reports/provider_validation_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.ProviderValidationReport, filters: ["Project"] });
    },
    "/analytics/hedis/reports/ft_daily_summary_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.FTDailySummaryReport, filters: ["Project"] });
    },
    "/analytics/hedis/reports/chase_numerator_level_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.ChaseNumeratorLevelReport,
                                       filters: ["Project", "AbstractionDate", "HealthPlan", "ContractNumber", "Products", "LOB", "RetrievalOwner", "SampleCompliance", "Compliance", "Numerator", "Measure", "AbstractionBy"] });
    },
    "/analytics/hedis/reports/clinical_productivity_and_accuracy-Overall": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.ClinicalProductivityAndAccuracyOverall, filters: ["Project", "AbstractionDate", "LOB", "RetrievalOwner", "Products", "AbstractionBy"] });
    },
    "/analytics/quality/reports/dw/clinical_accuracy_by_chase_with_error_detail_report": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ClinicalAccuracyByChaseWithErrorDetailReport,
        filters: ["Project"],
        hideFilters: [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID],
      });
    },
    "/analytics/hedis/reports/clinical_accuracy_by_chase_with_error_detail_report": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ClinicalAccuracyByChaseWithErrorDetailReportLegacy,
        filters: ["Project", "Measure", "AbstractionDate", "HealthPlan", "ContractNumber", "Products", "LOB", "RetrievalOwner", "AbstractionBy"]});
    },
    "/analytics/hedis/bot_overread_overview": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.BotOverreadOverview, filters: ["Project", "Measure", "Address"] });
    },
    "/analytics/hedis/reports/retrieval_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.RetrievalReport, filters: ["Project", "ChartReceived"] });
    },
    "/analytics/hedis/reports/clinical_productivity_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.ClinicalProductivityReport, filters:
        ["Project", "Measure", "CompletionDate", "HealthPlan", "ContractNumber", "Products", "LOB", "RetrievalOwner", "UserName"] });
    },
    "/analytics/hedis/reports/clinical_qa_report_by_measure": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.ClinicalQAReportByMeasure, filters: ["Project", "DEDate"] });
    },
    "/analytics/hedis/reports/client_overread2_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.ClientOverread2Report,
                                       filters: ["Project", "Overread2Date", "HealthPlan", "ContractNumber", "Products", "LOB", "RetrievalOwner", "Measure", "Overread2By"] });
    },
    "/analytics/quality/reports/dw/intake_productivity_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.IntakeProductivityReport,
                                       filters: ["Project"],
                                       hideFilters: [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID] });
    },
    "/analytics/hedis/reports/intake_productivity_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.IntakeProductivityReportLegacy,
                                       filters: ["Project", "UpdateDate", "HealthPlan", "ContractNumber", "Products", "LOB", "RetrievalOwner", "Measure", "UpdatedBy"]});
    },
    "/analytics/hedis/reports/user_productivity_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.UserProductivityReport, filters: ["Project", "StartDate", "EndDate", "Measure", "UserName"] });
    },
    "/analytics/quality/reports/dw/call_count_detail_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.CallCountDetailReport,
                                       filters: ["Project"],
                                       hideFilters: [HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.PROJECT_ID] });
    },
    "/analytics/hedis/reports/call_count_detail_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.CallCountDetailReportLegacy,
                                       filters: ["Project", "WorkflowStatus", "HealthPlan", "ContractNumber", "Products", "LOB", "RetrievalOwner"]});
    },
    "/analytics/hedis/reports/retrieval_report_by_chase": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.RetrievalReportByChase, filters: ["Project", "RetrievalType", "RetrievalDate"] });
    },
    "/analytics/hedis/reports/provider_outreach_by_contact_type_report": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ProviderOutreachByContactTypeReport, filters: ["Project", "ContactDate", "ContactMethod", "CallType"],
      });
    },
    "/analytics/quality/reports/dw/clinical_accuracy_by_measure": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.ClinicalAccuracyByMeasure,
                                       filters: ["Project"],
                                       hideFilters: [HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.PROJECT_ID] });
    },
    "/analytics/hedis/reports/clinical_accuracy_by_measure": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.ClinicalAccuracyByMeasureLegacy,
                                       filters: ["Project", "Measure", "AbstractionDate", "HealthPlan", "ContractNumber", "Products", "LOB", "RetrievalOwner", "CodedBy"]});
    },
    "/analytics/hedis/reports/sftp_report": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.SftpReport,
        filters: ["RetrievalType", "FileCreateDate"],
        hideFilters: [HideFilters.PROJECT_ID] });
    },
    "/analytics/quality/reports/dw/sftp_report": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.SftpReportDW,
        filters: [],
        hideFilters: [HideFilters.USER_ID, HideFilters.ORG_ID] });
    },
    "/analytics/hedis/reports/hedis_chase_count_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.HedisChaseCountReport, filters: ["Project", "StartDate", "EndDate"] });
    },
    "/analytics/hedis/reports/prospective_chase_count_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.ProspectiveChaseCountReport, filters: ["Project", "StartDate", "EndDate"] });
    },
    "/analytics/hedis/reports/load_count_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.LoadCountReport, filters: ["Project", "LoadDate"] });
    },
    "/analytics/hedis/reports/manually_created_chases_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.ManuallyCreatedChasesReport, filters: ["Project", "DataLoadDate"] });
    },
    "/analytics/hedis/reports/chase_move_back_report": _ => {
      return new AnalyticsHedisModel({ dashboardType: LookerDashboardType.ChaseMoveBackReport, filters: ["Project", "AbstractionDate", "LOB", "RetrievalOwner", "Products"] });
    },
    "/analytics/hedis/reports/nrc_list_by_measure": _ => new AnalyticsHedisModel({ dashboardType: LookerDashboardType.NRCListByMeasure, filters: ["Measure", "Address"] }),
    "/analytics/hedis/reports/detail_clinical_productivity_and_accuracy_overall": _ => new AnalyticsHedisModel({ dashboardType: LookerDashboardType.DetailClinicalProductivityAndAccuracyOverall,
                                                                                                                 filters: ["Project", "AbstractionDate", "Products", "LOB", "RetrievalOwner", "Measure"] }),
    "/analytics/hedis/reports/member_centric_details_report": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.MemberCentricDetailsReport, filters: ["Project", "MemberId", "MemberFirstName", "MemberLastName", "MemberDobFromTo", "MemberGender", "AddressGroup"],
      });
    },
    "/analytics/hedis/reports/member_centric_overview": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.MemberCentricOverview, filters: ["Project", "AddressGroup"],
      });
    },
    "/analytics/hedis/program_retrieval_projections": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ProgramRetrievalProjections, filters: ["BusinessDate"],
      });
    },
    "/analytics/hedis/retrieval/intake_sla_dashboard": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.IntakeSLADashboard, filters: ["organization"],
      });
    },
    "/analytics/hedis/reports/clinical_pends_with_compliance_change": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ClinicalPendsWithComplianceChange, filters: ["Project", "PendStatus", "PendCode", "PendDate"],
      });
    },
    "/analytics/quality/reports/dw/chase_move_back_history_report": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ChaseMoveBackHistoryReport,
        filters: ["Project", "HealthPlan", "ContractNumber", "Products", "LOB", "RetrievalOwner", "MovebackBy", "UserName", "Measure", "MovebackDate"],
        hideFilters: [HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.PROJECT_ID, HideFilters.HEALTH_PLAN, HideFilters.CONTRACT_NUMBER,
                      HideFilters.CO_RETRIEVAL, HideFilters.PRODUCT, HideFilters.LINE_OF_BUSINESS, HideFilters.MOVEBACK_DATE, HideFilters.MOVEBACK_BY] });
    },
    "/analytics/hedis/reports/chase_move_back_history_report": _ => {
      return new AnalyticsHedisModel({
        filters: ["Project", "Products", "LOB", "RetrievalOwner", "Measure", "MovebackDate"],
        dashboardType: LookerDashboardType.ChaseMoveBackHistoryReportLegacy});
    },
    "/analytics/hedis/reports/dob_discrepancy": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.DobDiscrepancy,
        filters: ["Project", "HealthPlan", "ContractNumber", "Products", "LOB", "RetrievalOwner", "Measure", "AbstractionBy", "AbstractionDate"],
      });
    },
    "/analytics/hedis/reports/member_centric_chase_count": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.MemberCentricChaseCount, filters: ["Project"],
      });
    },
    "/analytics/hedis/reports/member_centric_chart_count": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.MemberCentricChartCount, filters: ["Project"],
      });
    },
    "/analytics/hedis/reports/customer_invoice_detail": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.CustomerInvoiceDetail, filters: ["Project", "BillingDate", "Measure", "UserName"],
      });
    },
    "/analytics/hedis/reports/chase_submeasure_report": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ChaseSubMeasureReport,
        filters: ["Project", "Products", "LOB", "Measure", "Numerator", "WorkflowStatus", "Compliance", "Products", "LOB", "RetrievalOwner", "SampleCompliance"],
      });
    },
    "/analytics/quality/reports/coding_duration_report": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.CodingDurationReport,
        filters: ["Project", "CompletionDate"],
        hideFilters: [HideFilters.PROJECT_ID, HideFilters.ORG_ID, HideFilters.USER_ID2, HideFilters.COMPLETION_DATE, HideFilters.PROJECT_NAME2],
      });
    },
    "/analytics/quality/reports/dw/coding_duration_report": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.CodingDurationReportDW,
        filters: ["Project"],
        hideFilters: [HideFilters.PROJECT_ID, HideFilters.ORG_ID, HideFilters.USER_ID2, HideFilters.PROJECT_NAME2],
      });
    },
    "/analytics/quality/reports/stalled_documents_report": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.StalledDocumentsReport,
        filters: ["RetrievalType", "FileCreateDate"],
        hideFilters: [HideFilters.ORG_ID, HideFilters.ORGANIZATION_NAME],
      });
    },
    "/analytics/quality/reports/dw/stalled_documents_report": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.StalledDocumentsReportDW,
        filters: [],
        hideFilters: [HideFilters.ORG_ID, HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORGANIZATION_NAME],
      });
    },
    "/analytics/quality/dw/reports/top_aids_with_open_chases": _ => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.TopAidsWithOpenChases,
        filters: ["Project"],
        hideFilters: [HideFilters.ORG_ID, HideFilters.PROJECT_ID, HideFilters.USER_ID],
      });
    },
    "/analytics/quality/reports/provider_gateway_activity_report": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ProviderGatewayActivityReport,
        filters: ["Address", "DocumentQueueId", "DocumentStatus", "ConfirmationNumber", "ProviderGatewayPin"],
        hideFilters: [HideFilters.ORG_ID, HideFilters.ORGANIZATION_NAME, HideFilters.DOCUMENT_ID, HideFilters.DOCUMENT_STATE, HideFilters.AID,
                      HideFilters.AID_GROUP, HideFilters.CONFIRMATION_NUMBER, HideFilters.PROVIDER_GATEWAY_PIN],
      });
    },
    "/analytics/quality/reports/dw/coder_productivity_report": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.CoderProductivityReportDW,
        filters: ["Project"],
        hideFilters: [HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.PROJECT_ID],
      });
    },
    "/analytics/quality/reports/dw/client_overread2_report": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ClientOverread2ReportDW,
        filters: ["Project"],
        hideFilters: [HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.PROJECT_ID],
      });
    },
    "/analytics/quality/reports/dw/contacts_report": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ContactsReport,
        filters: ["Project"],
        hideFilters: [HideFilters.USER_ID2, HideFilters.ORG_ID, HideFilters.PROJECT_ID, HideFilters.PROJECT_NAME2, HideFilters.CLIENT_NAME, HideFilters.CONTACT_METHOD_ID],
      });
    },
    "/analytics/quality/reports/dw/quality_aid_feedback_summary_and_detail_report": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.AidFeedbackSummaryDW,
        filters: ["Project"],
        hideFilters: [HideFilters.PROJECT_ID, "User_id", HideFilters.ORG_ID],
      });
    },
    "/analytics/quality/reports/chase_move_report": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ChaseMoveReport,
        filters: ["Project", "HealthPlan", "ContractNumber", "LOB", "Products", "RetrievalOwner", "ExpectedRetrieval", "MoveDateStart", "MoveDateEnd"],
        hideFilters: [HideFilters.USER_ID2, HideFilters.ORG_ID, HideFilters.PROJECT_ID, HideFilters.HEALTH_PLAN, HideFilters.CONTRACT_NUMBER,
                      HideFilters.CO_RETRIEVAL2, HideFilters.PRODUCT, HideFilters.LINE_OF_BUSINESS, HideFilters.PROJECT_NAME, HideFilters.MOVE_DATE, HideFilters.EXPECTED_RETRIEVAL2] ,
      });
    },
    "/analytics/quality/reports/dw/chase_move_report": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ChaseMoveReportDW,
        filters: ["Project"],
        hideFilters: [HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.PROJECT_ID, HideFilters.PROJECT_NAME] ,
      });
    },
    "/analytics/quality/reports/dw/training_feedback_report": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.TrainingFeedbackReportDW,
        filters: ["Project"],
        hideFilters: [HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.PROJECT_ID] ,
      });
    },
    "/analytics/quality/reports/dw/new_pages_added_report": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.NewPagesAddedReport,
        filters: ["Project"],
        hideFilters: [HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.PROJECT_ID, HideFilters.PROJECT_NAME2, HideFilters.CLIENT_NAME],
      });
    },
    "/analytics/quality/reports/dw/eve_reviewed_vs_eve_found_report": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.EVEReviewedVsEVEFoundReport,
        filters: ["Project"],
        hideFilters: [HideFilters.PROJECT_ID, HideFilters.ORG_ID, HideFilters.USER_ID2, HideFilters.PROJECT_NAME2],
      });
    },
    "/analytics/quality/reports/dw/clinical_productivity_and_accuracy-overall": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.DetailClinicalProductivityAndAccuracyOverallDW,
        filters: ["Project"],
        hideFilters: [HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.PROJECT_ID],
      });
    },
    "/analytics/quality/reports/dw/clinical_productivity_report": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ClinicalProductivityReportDW,
        filters: ["Project"],
        hideFilters: [HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.PROJECT_ID],
      });
    },
    "/analytics/quality/reports/dw/chase_numerator_level_report": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ChaseNumeratorLevelReportDW,
        filters: ["Project"],
        hideFilters: [HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.PROJECT_ID],
      });
    },
    "/analytics/quality/dw/overview": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.QualityOverview,
        filters: ["Project"],
        hideFilters: [HideFilters.PROJECT_ID, HideFilters.ORG_ID, HideFilters.USER_ID2, HideFilters.PROJECT_TYPE_ID, HideFilters.TEMPLATE_ID],
      });
    },
    "/analytics/quality/dw/clinical/summary": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ClinicalSummaryDW,
        filters: ["Project"],
        hideFilters: [HideFilters.PROJECT_ID, HideFilters.ORG_ID, HideFilters.USER_ID, HideFilters.PROJECT_TYPE_ID2, HideFilters.TEMPLATE_TYPE_ID],
      });
    },
    "/analytics/quality/dw/document_validation_report": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.DocumentValidationReportDW,
        filters: ["Project"],
        hideFilters: [HideFilters.PROJECT_ID, HideFilters.ORG_ID, HideFilters.USER_ID],
      });
    },
    "/analytics/quality/dw/user_productivity_dashboard": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.UserProductivityDashboard,
        filters: [],
        hideFilters: [
          HideFilters.ORG_ID,
          "So Name",
        ],
      });
    },
    "/analytics/quality/reports/chase_counts_by_chase_statuses": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ChaseCountsByStatusReport,
        filters: ["Project", "HealthPlan", "ContractNumber", "Products", "LOB", "RetrievalOwner", "CurrentChaseStatus", "CurrentProcessStep", "ExpectedRetrieval", "VendorInvoiceType", "VendorName"],
        hideFilters: [
          HideFilters.USER_ID2,
          HideFilters.ORG_ID,
          HideFilters.PROJECT_ID,
          HideFilters.HEALTH_PLAN,
          HideFilters.CONTRACT_NUMBER,
          HideFilters.PROJECT_NAME2,
          HideFilters.PRODUCT,
          HideFilters.LINE_OF_BUSINESS,
          HideFilters.CO_RETRIEVAL2,
          "Current_Chase_Status",
          "Current_Process_Step",
          "Expected_Retrieval",
          "Vendor_Name",
          "Vendor_Invoice_Type",
          "Chase_Tags",
          "Organization_Name",
        ],
      });
    },
    "/analytics/quality/dw/chart_routing": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ChartRoutingDashboard,
        filters: [],
        hideFilters: [
          HideFilters.USER_ID2,
          HideFilters.ORG_ID,
          HideFilters.PROJECT_ID,
        ],
      });
    },
    "/analytics/quality/reports/dw/pend_report": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.PendReportDW,
        filters: ["Project"],
        hideFilters: [HideFilters.ORG_ID, HideFilters.PROJECT_ID, HideFilters.USER_ID],
      });
    },
    "/analytics/quality/dw/pends/summary": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.PendsSummaryDW,
        filters: ["Project"],
        hideFilters: [HideFilters.ORG_ID, HideFilters.PROJECT_ID, HideFilters.USER_ID2],
      });
    },
    "/analytics/quality/reports/dw/dob_discrepancy": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.DobDiscrepancyDW,
        filters: ["Project"],
        hideFilters: [HideFilters.ORG_ID, HideFilters.PROJECT_ID, HideFilters.USER_ID2],
      });
    },
    "/analytics/quality/reports/dw/Chase_Status_Underlying": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ChaseStatusUnderlyingReport,
        filters: ["Project"],
        hideFilters: [HideFilters.ORG_ID, HideFilters.PROJECT_ID, HideFilters.USER_ID],
      });
    },
    "/analytics/quality/reports/dw/Life_of_Chase": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.LifeOfAChaseDW,
        filters: ["Project"],
        hideFilters: [HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.PROJECT_ID, HideFilters.HEALTH_PLAN2, HideFilters.CONTRACT_NUMBER,
                      HideFilters.CO_RETRIEVAL2, HideFilters.PRODUCT, HideFilters.LINE_OF_BUSINESS, HideFilters.MEASURE2, HideFilters.PROJECT_NAME2, "Chase_ID", "Customer_Name"],
      });
    },
    "/analytics/quality/reports/dw/load_error": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.DataLoadErrorReport,
        filters: ["Project"],
        hideFilters: [
          HideFilters.PROJECT_ID,
          HideFilters.USER_ID,
          HideFilters.ORG_ID,
          "Object_Name"],
      });
    },
    "/analytics/quality/reports/dw/annotations": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.AnnotationsReport,
        filters: ["Project"],
        hideFilters: [
          HideFilters.PROJECT_ID,
          HideFilters.PROJECT_NAME,
          HideFilters.PROJECT_NAME2,
          HideFilters.PROJECT_TYPE_ID,
          HideFilters.ORGANIZATION_ID,
          HideFilters.ORG_ID,
          HideFilters.ORGANIZATION_NAME,
          HideFilters.USER_ID,
          "Project ID",
          "OrganizationID",
          "Customer Name",
        ],
      });
    },
    "/analytics/quality/reports/dw/chase_submeasure_report": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ChaseSubMeasureReportDW,
        filters: ["Project"],
        hideFilters: [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.PROJECT_NAME2],
      });
    },
    "/analytics/quality/reports/dw/project_status_report": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.ProjectStatusDW,
        filters: ["Project"],
        hideFilters: [HideFilters.PROJECT_ID, HideFilters.ORG_ID, HideFilters.USER_ID],
        });
    },
    "/analytics/quality/reports/dw/sla": () => {
      return new AnalyticsHedisModel({
        dashboardType: LookerDashboardType.SLAMilestoneReport,
        filters: ["Project"],
        hideFilters: [HideFilters.PROJECT_ID, HideFilters.ORG_ID, HideFilters.USER_ID],
        });
    },
  };

  static getDashboardType(route: string): AnalyticsHedisModel {
    if (this.hedisDashboards.hasOwnProperty(route)) {
      return this.hedisDashboards[route]();
    }
  }
}
