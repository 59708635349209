import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { SubSink } from "subsink";
import { ChaseDetailState } from "../../chase-detail-state.model";
import { ChaseDetailStateService } from "../../chase-detail-state.service";

@Component({
  selector: "member-resolve-pend-warning-modal",
  templateUrl: "./resolve-pend-warning-modal.component.html",
  styleUrls: ["./resolve-pend-warning-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// TODO: Duplicate with current-pends.component, except for linking pend to pend detail. If we have the same code in 3 places, let's refactor
export class ResolvePendWarningModalComponent implements OnInit, OnDestroy {
  private sink = new SubSink();

  pVisible = false;
  @Input()
  get visible(): boolean {
    return this.pVisible;
  }
  set visible(value: boolean) {
    this.pVisible = value;
    this.visibleChange.emit(this.visible);
  }

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onUpdate = new EventEmitter<null>(true);

  @Input() clinical = true;
  @Input() pageType = "";

  chaseDetailState: ChaseDetailState;
  selectedChases: any[];
  isPendModalVisible = false;

  constructor(
    private chaseDetailStateService: ChaseDetailStateService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.sink.add(this.chaseDetailStateService.state.subscribe(state => {
      this.chaseDetailState = state;
      this.changeDetector.markForCheck();
    }));
  }

  ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  get pendInfo(): string {
    return `${this.chaseDetailState.pendCode} - ${this.chaseDetailState.pendName}`;
  }

  openPendModal(): void {
    this.selectedChases = [{
      chasePendId: this.chaseDetailState.chasePendId,
      pendCode: this.chaseDetailState.pendCode,
      chaseId: this.chaseDetailState.chaseId,
      pendStatusId: this.chaseDetailState.pendStatusId },
    ];
    this.isPendModalVisible = true;
    this.visible = false;
  }

  closeModal(): void {
    this.selectedChases = [];
  }
}
