import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import domtoimage from "dom-to-image";
import { finalize } from "rxjs/operators";
import { SubSink } from "subsink";
import { AuthService } from "../../../../../auth/auth.service";
import { UserToken } from "../../../../../auth/user-token.model";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { LocalService } from "../../../../../core/storage/local.service";
import { UserService } from "../../../../../core/user/user.service";
import { DynamicControl } from "../../../../../dynamic-forms/dynamic-control.model";
import { DynamicFormEvent } from "../../../../../dynamic-forms/dynamic-form-event.model";
import { FactoryComponent } from "../../../../../dynamic-forms/factory/factory.component";
import { DynamicArray } from "../../../../../dynamic-forms/form-arrays/dynamic-array.model";
import { DynamicGroup } from "../../../../../dynamic-forms/form-groups/dynamic-group.model";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { DynamicInput } from "../../../../../dynamic-forms/inputs/dynamic-input.model";
import { DocumentPageViewerComponent } from "../../../../../shared/document/document-page-viewer/document-page-viewer.component";
import { DocumentViewerSessionService } from "../../../../../shared/document/document-page-viewer/document-viewer-session.service";
import { Legend } from "../../../../../shared/legend/legend.model";
import { ListItem } from "../../../../../shared/list/list-item";
import { MemberCentricChase } from "../../../../../shared/membercentric-doc-attachment/membercentric-chase.model";
import { MenuService } from "../../../../../shared/menu/menu.service";
import { PendStatus } from "../../../../../shared/pend/pend-status.enum";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { ObjectHelper } from "../../../../../utilities/contracts/object-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { ActionButton } from "../../../../../zdevcontrols/action-button/action-button.model";
import { DevControllerService } from "../../../../../zdevcontrols/dev-controller/dev-controller.service";
import { ChaseSearchRequest } from "../../../../api/chase-search/chase-search-request-model";
import { ChaseSearchService } from "../../../../api/chase-search/chase-search.service";
import { DynamicEntityAttribute } from "../../../../api/member-validation/dynamic-entity-attribute.model";
import { DynamicEntity } from "../../../../api/member-validation/dynamic-entity.model";
import { EntityError } from "../../../../api/member-validation/entity-error.model";
import { EntityType } from "../../../../api/member-validation/entity-type.enum";
import { MemberValidation } from "../../../../api/member-validation/member-validation.model";
import { MemberValidationService } from "../../../../api/member-validation/member-validation.service";
import { WorkflowStatusDb } from "../../../../api/workflow/workflow-status-db.enum";
import { AnnotationService } from "../../../../widget/annotations/annotation.service";
import { ChaseDetailForAuditItem } from "../../../audit/chase-detail-for-audit-item.model";
import { FunctionalRole } from "../../../dashboard/retrieval/functional-role.enum";
import { ProjectType } from "../../../project/project-type.enum";
import { ServiceOrgId } from "../../../service-org-admin/service-org-config/service-org-id.enum";
import { CodingOptimizationMode } from "../../chase-detail-v2/chase-detail-v2-chart/coding-optimization-mode.enum";
import { LOCALSTORAGE_PAGEAFTERSCREENSYNC } from "../../chase-detail-v2/chase-detail-v2-chart/local-storage-keys";
import { ChaseDetailState } from "../chase-detail-state.model";
import { ChaseDetailStateService } from "../chase-detail-state.service";
import { ChaseDetailService } from "../chase-detail.service";
import { Attribute } from "./attribute.model";
import { COMPLIANT_NUMERATOR_LIST_ADMIN, MEMBER_VALIDATION_RESULT, NLP_REVIEW_RESULT } from "./attributes";
import { AuditPageEntryService } from "./audit-page-entry/audit-page-entry.service";
import { ChartService } from "./chart.service";
import { ComplianceComponent } from "./compliance/compliance.component";
import { OverreadFeedbackStatus } from "./overread-feedback/overread-feedback-status.enum";
import { OverreadFeedbackService } from "./overread-feedback/overread-feedback.service";
import { RiskEntity } from "./risk/risk-entity.model";
import { RiskError } from "./risk/risk-error-modal/risk-error.model";
import { RiskState } from "./risk/risk-state.model";
import { RiskService } from "./risk/risk.service";

@Component({
  selector: "member-chase-detail-chart",
  templateUrl: "./chase-detail-chart.component.html",
  styleUrls: ["./chase-detail-chart.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RiskService],
})
export class ChaseDetailChartComponent implements OnInit, OnDestroy {
  @Input() chaseDetailsForAudit: ChaseDetailForAuditItem;
  @Input() enablePopUpBtn = true;
  @Input() compliantInfoError = "";
  private sink = new SubSink();
  private user: UserToken;
  chaseDetails: ListItem[] = [];
  fromPage: string;
  formGroup = new FormGroup({});
  formModels: DynamicControl[];
  isChartMaxed = false;
  chaseId: number;
  memberValidationData = new MemberValidation();
  memberIsValid = false;
  auditMode = false;
  hasResearchButtons = false;
  researchButtonsValidated = false;
  isCatalyticResultsVisible = false;
  isResolvePendWarningVisible = false;
  showConfirmation = false;
  deleteEvent: DynamicFormEvent;
  chaseDetailState: ChaseDetailState;
  isFormReloading = false;
  isManagerReview = false;
  isFormReloadingComplete = false;
  showSuccessToast = true;
  hasNoDocPages = false;
  isExpandedViewFromUrl = false;
  showMemberInputs = true;
  pcInfo: string;
  dataEntryLegend: Legend[];
  chaseStatusToDisplayDe = "";
  miscellaneousEntity: DynamicEntity;
  @ViewChild("containerBody", { static: true }) containerBody: ElementRef;
  @ViewChild(ComplianceComponent, { static: false }) complianceGridComponent: ComplianceComponent;
  selectedPageNumber: number;
  isPopOutBtnEnabled = true;
  isSplitScreenEnabled = false;
  mainTab: any = null;
  duplicateTab: boolean = null;
  localStorageDiagnosisSelection = "diagnosisSelection";
  localStorageIsStorageCheckRequired = "isStorageCheckRequired";
  localStorageIsWindowOpen = "isWindowOpen";
  localStorageKey = "popOutAction";
  localStoragePageNumber = "pageNumber";
  localStoragePageReload = "isReloadPage";
  localStorageSelectedChaseId = "selectedChaseId";
  localStorageSkipDefaultView = "skipDefaultView";
  localStroageOriginalView = "originalView";
  private pdfScreenTab = "chasedetailWindow";
  winRef: any;
  isResearchDisable = false;
  isAddButtonEnabled = false;

  ocrDataAvailable = false;
  isOverreadQuery = false;
  isStatusNotManagerReview = false;
  chaseOverreadDetail: any;
  displayEncountersWithNoHcc: boolean;
  private openChaseAndUserInfo = "openChaseAndUserInfo";

  constructor(
    private auditPageEntryService: AuditPageEntryService,
    private chaseSearchService: ChaseSearchService,
    private documentViewerSessionService: DocumentViewerSessionService,
    private localService: LocalService,
    private overreadFeedbackService: OverreadFeedbackService,
    private readonly authService: AuthService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly chartService: ChartService,
    private readonly chaseDetailService: ChaseDetailService,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly devControllerService: DevControllerService,
    private readonly formService: FormService,
    private readonly memberValidationService: MemberValidationService,
    private readonly messagingService: MessagingService,
    private readonly riskService: RiskService,
    private readonly router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    public menuService: MenuService,
    public annotationService: AnnotationService
  ) { }

  ngOnInit() {

    if (this.localService.get(this.localStorageKey, null) === "false") {
      this.isPopOutBtnEnabled = false;
    }
    this.containerBody?.nativeElement?.offsetParent?.classList.add("container-body-wrap");
    this.user = this.userService.getUserToken();
    this.route.parent.paramMap.subscribe(params => {
      this.isExpandedViewFromUrl = this.route.snapshot.url[0]?.path === "expanded";
      this.fromPage = params.get("fromPage");

      // TODO: Make sure the chase id comes from the url.
      if ((NumberHelper.isGreaterThan(+params.get("chaseGd"), 0)) && (this.chaseId !== +params.get("chaseGd"))) {
        this.chaseId = +params.get("chaseGd");
      } else if ((NumberHelper.isGreaterThan(this.chaseDetailsForAudit.chaseId, 0))) {
        this.chaseId = this.chaseDetailsForAudit.chaseId;
        this.auditMode = true;
      } else {
        this.chaseId = 0;
      }
      if (this.router.url.includes("auditquery")) {
        this.auditMode = false;
      }
      this.annotationService.getAnnotations$(this.chaseId).subscribe(() => {
        this.annotationService.refreshAnnotations(true);
        this.changeDetector.markForCheck();
      });

      this.riskService.setData(new RiskState({
        chaseId: this.chaseId,
        auditMode: this.auditMode,
      }));

      DocumentPageViewerComponent.documentId.next(this.chaseId);

      if ((NumberHelper.isGreaterThan(this.chaseId, 0))) {
        this.getForm();
      }
    });

    this.sink.add(
      this.chaseDetailService.chaseDetailsChangeObserver.subscribe(chaseDetails => {
        this.chaseDetails = chaseDetails;
        // show/hide split screen functionality based on configration
        const popOutStatus = this.chaseDetails.find(item => item.key === "IsOrganizationIdsEnabledForSplitScreen")?.value;
        this.isSplitScreenEnabled = popOutStatus === "True";
        if (this.isSplitScreenEnabled) {
          setTimeout(() => {
            // hide the pdf expand icon
            const expandIcon: any = document.getElementById("iconMaximize");
            if (expandIcon) {
              expandIcon.style.setProperty("display", "none");
            }
          },         0);
        }
        this.riskService.setData({ assignedToCurrentUser: this.assignedToCurrentUser });
      }),
      this.chaseDetailStateService.state.subscribe(state => {
        this.chaseDetailState = state;
        this.displayEncountersWithNoHcc = Number(state.projectConfiguration?.codingOptimizationMode) === CodingOptimizationMode.HIDE_DX;
        const isStatusOverreadFeedbackOrClient =
          this.chaseDetailState.reportingStatusName === "Overread Feedback" ||
          this.chaseDetailState.reportingStatusName === "Client Overread" ||
          this.chaseDetailState.reportingStatusName === "Downloaded" ||
          this.chaseDetailState.reportingStatusName === "Available for Download" ||
          this.chaseDetailState.hasPend;
        if (!this.isOverreadQuery && this.chaseDetailState.chaseOverreadFeedback && isStatusOverreadFeedbackOrClient) {
          this.overreadfeedbackQuery();
          this.isOverreadQuery = true;
        }
        this.changeDetector.markForCheck();
      }),
      this.chaseDetailService.ocrDataAvailableChange.subscribe(res => {
        this.ocrDataAvailable = res;
        this.changeDetector.markForCheck();
      }),
      this.chartService.refreshCodingForm
        .subscribe(isRefreshCodingForm => {
          if (isRefreshCodingForm) {
            this.refreshCodingForm();
            this.changeDetector.markForCheck();
          }
        })
    );

    this.devControllerService.push([
      new ActionButton({
        name: "Refresh Form",
        action: () => {
          this.getForm();
        },
      }),
      new ActionButton({
        name: "Enable All",
        action: () => {
          this.formGroup.enable();
          this.changeDetector.markForCheck();
        },
      }),
    ]);
    this.createDataEntryLegend();
    setTimeout(() => {
      if (this.isPopOutBtnEnabled && this.localService.get(this.localStorageIsWindowOpen, null) === "1") {
        this.maintainStorage();
        this.localService.put(this.localStorageSelectedChaseId, this.router.url);
      }
    },         1000);

    // manage the condition for not call the localstorage event
    if (this.localService.get(this.localStorageKey, null)) {
      this.isPopOutBtnEnabled = (this.localService.get(this.localStorageKey, null) === "true");
    }
    if (this.isPopOutBtnEnabled && this.localService.get(this.localStorageIsWindowOpen, null) === "1") {
      this.localService.put(this.localStorageIsStorageCheckRequired, "false");
      setTimeout(() => {
        this.localService.delete(this.localStorageIsStorageCheckRequired);
      },         3000);
    }
    this.handlePopupEvent();
  }

  codingClasses(element: FactoryComponent): string {
    const elementHeight = element?.component?.element?.nativeElement?.nextElementSibling?.offsetHeight;

    return elementHeight !== 0 ? "coding-container-section__hedis-form" : "";
  }
  ngOnDestroy() {
    if (this.duplicateTab) {
      this.localService.delete(this.localStorageIsWindowOpen);
    }
    this.mainTab = null;
    this.duplicateTab = null;
    this.localService.delete(this.localStorageKey);
    this.localService.delete(this.localStoragePageReload);
    this.localService.delete(this.localStoragePageNumber);
    this.localService.delete(this.localStorageDiagnosisSelection);
    this.localService.delete(this.localStorageIsStorageCheckRequired);
    this.localService.delete(this.localStorageSelectedChaseId);
    this.localService.delete(this.localStorageSkipDefaultView);
    this.localService.delete(this.localStroageOriginalView);

    this.sink.unsubscribe();
  }

  private overreadfeedbackQuery(): void {
    this.overreadFeedbackService.getOverreadFeedbackDetail(this.chaseId).subscribe(overreadDetail => {
      this.chaseOverreadDetail = overreadDetail;
      const isManagerOrClinicalLead =  this.user.functionalRoleIds.some(roleId => [FunctionalRole.PROJECTADMINISTRATOR, FunctionalRole.CLINICALLEAD].includes(roleId));
      const isStatusManger = overreadDetail.some(x => x.status  === OverreadFeedbackStatus.MANAGER);
      const isStatusAbstractorReview = overreadDetail.some(x => x.status  === OverreadFeedbackStatus.ABSTRACTOR);
      if (ArrayHelper.isAvailable(overreadDetail) && isManagerOrClinicalLead && isStatusManger) {
          this.isManagerReview = true;
          this.changeDetector.markForCheck();
      } else if (ArrayHelper.isAvailable(overreadDetail) && overreadDetail[0].overreaderUserId === this.user.userId && isStatusAbstractorReview) {
        this.isStatusNotManagerReview = false;
      } else if (ArrayHelper.isAvailable(overreadDetail) && (overreadDetail[0].abstractorUserId === this.user.userId || overreadDetail[0].overreaderUserId === this.user.userId)) {
        this.isStatusNotManagerReview = true;
      }
    });
  }

  get isHedis(): boolean {
    return this.projectType === ProjectType.HEDIS;
  }

  get isRisk(): boolean {
    return this.projectType === ProjectType.RISK;
  }
  get isIva(): boolean {
    return this.projectType === ProjectType.IVA;
  }

  get isInAnyOverread(): boolean {
    return this.isOverread || this.isOverread2;
  }

  get isOverread(): boolean {
    return this.chaseDetailState.workflowStatus === WorkflowStatusDb.Overread;
  }

  get isOverread2(): boolean {
    return this.chaseDetailState.workflowStatus === WorkflowStatusDb.Overread2;
  }

  get useRisk(): boolean {
    return this.isHcc || this.isHst || this.isMcd;
  }

  get showMedicalRecordDob(): boolean {
    return this.isHedis || this.useRisk;
  }

  get showMemberValidation(): boolean {
    return !this.isAdrv;
  }

  get showIncorrectMemberValidationInputs(): string {
    return !this.showMemberInputs ? "coding-container-section__incorrectMemberValidation" : "";
  }

  /* HACK: Separate by Measure until we can fix with ScreenObject */
  get isHcc(): boolean {
    return this.measureCode === "HCC";
  }

  get isHst(): boolean {
    return this.measureCode === "HST";
  }

  get isAtt(): boolean {
    return this.measureCode === "ATT";
  }

  get isEnr(): boolean {
    return this.measureCode === "ENR";
  }

  get isDob(): boolean {
    return this.measureCode === "DOB";
  }

  get isGen(): boolean {
    return this.measureCode === "GEN";
  }

  get isDobGen(): boolean {
    return this.measureCode === "DOB/GEN";
  }

  get isRxcp(): boolean {
    return this.measureCode === "RXCP";
  }

  get isRxcm(): boolean {
    return this.measureCode === "RXCM";
  }

  get isAdrv(): boolean {
    return this.measureCode === "ADRV";
  }

  get isMcd(): boolean {
    return this.measureCode === "MCD";
  }

  /* End HACK */


  get assignedToId(): number {
    const assignedToIdItem = this.chaseDetails.find(item => item.key === "Assigned To Id");
    if (assignedToIdItem == null) {
      return null;
    }

    return +assignedToIdItem.value;
  }

  get assignedToCurrentUser(): boolean {
    if (this.assignedToId == null) {
      return true;
    }

    return this.assignedToId === this.authService.userId;
  }

  get isStatusAvailable(): boolean {
    return StringHelper.isAvailable(this.status);
  }

  get isMemberValidOrIsAdrv(): boolean {
    return this.memberIsValid || this.isAdrv;
  }

  get isEnabled(): boolean {
    // If Chart page displayed from Audit Module, make the chart page READ-ONLY
    // TODO: Fix logic to remove the ternary.
    return this.auditMode ? false : (this.isMemberValidOrIsAdrv && this.assignedToCurrentUser);
  }

  get projectType(): ProjectType {
    const projectTypeIdItem = this.chaseDetails.find(item => item.key === "Project Type Id");
    return (projectTypeIdItem ? Number(projectTypeIdItem.value) : null) as ProjectType;
  }

  get memberId(): number {
    const memberIdItem = this.chaseDetails.find(item => item.key === "Member ID");
    return memberIdItem ? +memberIdItem.value : -1;
  }

  get status(): string {
    const statusItem = this.chaseDetails.find(item => item.key === "Workflow Status");
    return statusItem ? statusItem.value : "";
  }

  get measureCode(): string {
    const statusItem = this.chaseDetails.find(item => item.key === "Measure ID");
    return statusItem ? statusItem.value : "";
  }

  get measureYear(): number {
    const statusItem = this.chaseDetails.find(item => item.key === "MeasureYear");
    return statusItem ? Number(statusItem.value) : 0;
  }

  get reportingStatusName(): boolean {
    return this.chaseDetailState.reportingStatusName === "Bot QA selection" || this.chaseDetailState.reportingStatusName === "Bot Processing" || this.chaseDetailState.reportingStatusName === "Bot QA2 selection"
      || this.chaseDetailState.reportingStatusName === "Bot QA2 Processing" || this.chaseDetailState.reportingStatusName === "Overread selection" || this.chaseDetailState.reportingStatusName === "Overread2 selection";
  }

  get showNLPResults(): boolean {
    return this.chaseDetailState.displayNlpResults && !this.reportingStatusName && (this.isOverread || this.showNLPForOverread2);
  }

  get showNLPForOverread2(): boolean {
    return this.isOverread2 && this.serviceOrgForNlpOr2.includes(this.user.organizationId);
  }

  get serviceOrgForNlpOr2(): number[] {
    return [ServiceOrgId.ANTHEM];
  }

  get isOcrDataAvailable(): boolean {
    return this.ocrDataAvailable;
  }

  get isOverreadFeedbackEnabled(): boolean {
    return this.chaseDetailState.chaseOverreadFeedback;
  }

  get hasUnresolvedPend(): boolean {
    return this.chaseDetailState.hasPend &&
      (this.chaseDetailState.pendStatusId === PendStatus.New || this.chaseDetailState.pendStatusId === PendStatus.InProgress);
  }

  get codingStyles(): string {
    return this.auditMode ? "coding-container coding-container--audit" : "coding-container";
  }

  get memberCodingStyles(): string {
    return this.isMemberCentricChase ? "coding-container__member-chase-container" : "";
  }

  get isMemberCentricChase(): boolean {
    return this.chaseDetailState.isMemberCentric;
  }

  get siblingChases(): { chaseId: number; entityId: number }[] {
    const chases = [];
    if (this.isMemberCentricChase) {
      this.chaseDetailState.memberCentricChases.forEach(x => {
        if (x.assignedToUserId === this.user.userId && x.workflowStatusId === this.chaseDetailState.workflowStatus && x.chaseId !== this.chaseId) {
          chases.push({ chaseId: x.chaseId, entityId: x.memberValidationEntityId });
        }
      });
    }
    return chases;
  }

  get functionalRoleId(): number {
    switch (this.chaseDetailState.workflowStatus) {
      case WorkflowStatusDb.Abstraction:
        return FunctionalRole.ABSTRACTOR;
      case WorkflowStatusDb.Overread:
        return FunctionalRole.OVERREAD;
      case WorkflowStatusDb.Overread2:
        return FunctionalRole.CLIENTOVERREAD;
      default:
        return 0;
    }
  }
  get isServiceProviderVisible(): boolean {
    if (this.isHedis) {
      return StringHelper.isAvailable(this.chaseDetailState.projectConfiguration?.dataEntryProviderConfirmationEnabled)
        ? (this.chaseDetailState.projectConfiguration.dataEntryProviderConfirmationEnabled === "1") : true;
    } else {

      return false;
    }
  }

  get isDemographicRequried(): boolean {
    if (this.isHedis) {
      return StringHelper.isAvailable(this.chaseDetailState.projectConfiguration?.demographicRequired)
        ? (this.chaseDetailState.projectConfiguration.demographicRequired === "1") : true;
    } else {

      return false;
    }
  }

  get isSubmitButtonDisabled(): boolean {
    this.pcInfo = "";
    if (this.pendCode === "PC304" && this.hasUnresolvedPendStatus) {
      this.pcInfo = "Resolve the PEND";
      return true;
    }
    return StringHelper.isAvailable(this.compliantInfoError) || !this.isEnabled || this.showNLPResults;
  }

  get hasUnresolvedPendStatus(): boolean {
    const statusName = this.chaseDetails.find(item => item.key === "PendStatusId");
    return statusName ? (parseInt(statusName.value, 10) === PendStatus.New || parseInt(statusName.value, 10) === PendStatus.InProgress) : false;
  }

  get pendCode(): string {
    const pendCode = this.chaseDetails.find(item => item.key === "Pend Code");
    return pendCode ? pendCode.value : "";
  }

  get isSourceAdminCompliance(): boolean {
    this.miscellaneousEntity = this.chaseDetailState.miscellaneousEntityFromChaseEntityData;
    return this.miscellaneousEntity?.attributes.some(attribute => attribute.attributeId === COMPLIANT_NUMERATOR_LIST_ADMIN.attributeId);
  }

  trackByIndex(index, item) {
    return index;
  }

  setResearchButtonsValidated(value: boolean): void {
    this.researchButtonsValidated = value;
  }

  setResearchButtonsEnabled(value: boolean): void {
    this.hasResearchButtons = value;
  }

  submit(): void {
    if (this.hasUnresolvedPend && (this.isOverread || this.isOverread2) && !this.duplicateTab) {
      this.isResolvePendWarningVisible = true;
      return;
    }

    const valid = this.getValidation();

    if (valid && !this.isSubmitButtonDisabled) {
      if (this.formGroup.valid || this.formGroup.status === "DISABLED") {
        this.submitAndRouteToDashboard();
      } else {
        this.markAllAsDirtyAndTouched(this.formGroup);
        this.formService.updateDom.next();
      }
    } else {
      this.messagingService.showToast("Please complete the checklist.", SeverityType.ERROR);
    }
  }

  toggleCodingData(event: boolean): void {
    const status = event ? WorkflowStatusDb[WorkflowStatusDb.Abstraction] : WorkflowStatusDb[WorkflowStatusDb.Overread];
    this.isFormReloading = true;
    this.chaseStatusToDisplayDe = status;
    if (this.isIva || this.isRisk) {
      this.riskService.loadDataByStatus(this.chaseId, status, false, () => {
        this.getMemberValidation();
        this.changeDetector.markForCheck();
      });
    } else {
      this.chartService.getOverreadFeedbackConfiguration(this.chaseId, status).subscribe(items => {
        this.formModels = items;
        this.formGroup = this.formService.createFormGroup(items);
        this.getMemberValidation();
        this.isFormReloading = false;
        this.changeDetector.markForCheck();
      });
    }
  }

  toggleMemberCentricChase(chasedId: number) {
    const path = this.router.url.substr(0, this.router.url.lastIndexOf("\/"));
    this.router.navigateByUrl(`${path}/${chasedId}`);
    this.changeDetector.markForCheck();
  }

  getMemberCentricButtonStyle(chase: MemberCentricChase): string {

    let memberCentricButton = "coding-container__member-chase-button";

    if (chase.status === "Available for Download") {
      memberCentricButton += " submitted-chase";
    } else if (chase.chaseId === this.chaseId) {
      memberCentricButton += " active-chase";
    } else if (chase.assignedToUserId === this.authService.userId) {
      memberCentricButton += " assigned-chase";
    } else if (chase.assignedToUserId !== this.authService.userId) {
      memberCentricButton += " unassigned-chase";
    }

    return memberCentricButton;
  }

  getMeasureButtonIcon(chase: MemberCentricChase) {
    return chase.status === "Available for Download" ? "check" : "";
  }

  getPopOutPDFViewerStyle() {
    return this.mainTab ? {} : (!this.isPopOutBtnEnabled ? { right: "0rem" } : "");
  }

  getPopOutStyle() {
    return this.mainTab ? {} : (!this.isPopOutBtnEnabled ? { display: "none" } : "");
  }

  handlePopupEvent(): void {
    window.addEventListener("storage", event => this.storageHandler(event));

    window.addEventListener("beforeunload", () => {
      const tabstage = this.mainTab ? "1" : "0";
      this.localService.put(this.localStoragePageReload, tabstage);
      // keep the flag, when reload happen on main tab
      if (tabstage === "0") {
        this.localService.delete(this.localStorageIsWindowOpen);
      }
    });
    window.addEventListener("unload", () => {
      if (this.localService.get(this.localStorageKey, null) === "false" &&
        this.localService.get(this.localStorageIsStorageCheckRequired, null) === null) {
        if (this.localService.get(this.localStorageSkipDefaultView, null) == null) {
          this.localService.put(this.localStroageOriginalView, "true");
          window.close();
        }
        this.localService.delete(this.localStorageSkipDefaultView);
      }
    });
  }

  updateChaseData() {
    this.sink.add(
      this.chaseDetailService.getSummaryItems(this.chaseId)
        .subscribe(items => {
          this.chaseDetailService.chaseDetailsChange.next(items);
          this.setPendDetails(items);
        })
    );
  }

  setPendDetails(items: ListItem[]): void {
    items.forEach(x => {
      switch (x.key) {
        case "PendStatusId":
          this.chaseDetailStateService.setData({
            pendStatusId: Number(x.value),
            hasPend: NumberHelper.isGreaterThan(Number(x.value), 0),
          });
          break;
        case "Pend Code":
          this.chaseDetailStateService.setData({
            pendCode: x.value,
          });
          break;
        case "PendCodeName":
          this.chaseDetailStateService.setData({
            pendName: x.value,
          });
          break;
        case "ChasePendId":
          this.chaseDetailStateService.setData({
            chasePendId: Number(x.value),
          });
          break;
        case "PendStatusName":
            this.chaseDetailStateService.setData({
              pendStatusName: x.value,
            });
            break;
        case "Status":
          this.chaseDetailStateService.setData({ reportingStatusName: x.value });
          break;
        default:
          break;
      }
    });
  }

  private storageHandler(event) {
    try {
      if (event.storageArea !== localStorage) { return; }
      if (this.localService.get(this.localStorageKey, null) === null) { return; }
      if (this.localService.get(this.localStorageIsStorageCheckRequired, null) !== null && this.localService.get(this.localStorageKey, null) === "false") {
        return;
      }
      for (const key in localStorage) {
        if (localStorage.hasOwnProperty(key)) {
          switch (key) {
            case this.localStorageKey:
              this.isPopOutBtnEnabled = (this.localService.get(key, null) === "true");
              this.duplicateTabAction();
              break;
            case this.localStoragePageNumber:
            case this.localStorageDiagnosisSelection:
              this.selectedPageNumber = Number(this.localService.get(key, null));
              const selectedPage: any = document.getElementById("CurrentPageNumber");
              selectedPage.value = this.selectedPageNumber;
              selectedPage.click();
              this.localService.put(LOCALSTORAGE_PAGEAFTERSCREENSYNC.key, this.selectedPageNumber);
              setTimeout(() => {
                this.localService.delete(this.localStoragePageNumber);
                this.localService.delete(this.localStorageDiagnosisSelection);
              },         0);
              break;
            case this.localStoragePageReload:
              if (this.mainTab == null) {
                this.mainTab = this.localService.get(key, null) === "1";
                if (this.mainTab) {
                  this.duplicateTab = false;
                } else {
                  this.localService.put(this.localStorageIsWindowOpen, "1");
                  this.duplicateTab = true;
                }
              }
              break;
            case this.localStroageOriginalView:
              this.localService.delete(this.localStroageOriginalView);
              this.closeURL();
              break;
            case this.localStorageSelectedChaseId:
              if (window.name === this.pdfScreenTab && window.location.href !== window.location.origin + this.localService.get(this.localStorageSelectedChaseId, null)) {
                window.location.href = window.location.origin + this.localService.get(this.localStorageSelectedChaseId, null);
                this.localService.delete(this.localStorageSelectedChaseId);
                this.localService.put(this.localStorageSkipDefaultView, "1");
              }
              break;
            default:
              break;
          }
        }
      }
    } catch { }
  }

  private duplicateTabAction() {
    if (this.duplicateTab == null || this.duplicateTab) {
      // hide user error notification
      const messageNotification: any = document.getElementsByTagName("app-messages")[0];
      if (messageNotification) {
        messageNotification.style.setProperty("display", "none");
      }
      // hide the data entry view if 2 tab as pdf viewer load
      const pdfSectionFullView: any = document.getElementsByClassName("chart-container")[0];
      pdfSectionFullView.style.setProperty("right", "0rem");
      // remove nevigation
      document.getElementsByTagName("platform-navigation")[0].querySelector("div").style.display = "none";
      // hide the upper bar
      document.getElementsByTagName("member-chase-detail")[0].querySelector("div").style.display = "none";
      // cover the bottom area
      const bottomArea: any = document.getElementsByClassName("chase-detail-chart-container")[0];
      bottomArea.style.setProperty("height", "calc(100vh - 4px)");
      // pdf left portion adjustment
      const pdfLeftArea: any = document.getElementsByTagName("app-document-page-thumbnails")[0];
      // pdfLeftArea.style.setProperty("height", "calc(100vh - 4px)"); // if show thumbnail in second tab
      if (pdfLeftArea) {
        pdfLeftArea.style.setProperty("display", "none");
      }
      // pdf left portion adjustment
      const pdfLeftArea1: any = document.getElementsByTagName("cdk-virtual-scroll-viewport")[0];
      if (pdfLeftArea1) {
        pdfLeftArea1.style.setProperty("height", "calc(100vh - 44px)");
      }
      // Pdf section till bottom
      const pdfSection: any = document.getElementsByClassName("document")[0];
      pdfSection.style.setProperty("height", "calc(100vh - 44px)");

    } else if (!this.duplicateTab) {
      // hide the data entry view if 2 tab as pdf viewer load
      const pdfSectionGridView: any = document.getElementsByClassName("chart-container")[0];
      pdfSectionGridView.style.right = null;
      // keep nevigation
      document.getElementsByTagName("platform-navigation")[0].querySelector("div").style.display = "flex";
      // keep the upper bar
      document.getElementsByTagName("member-chase-detail")[0].querySelector("div").style.display = "block";
      // select the pdf icon and click on expended thumbnail
      // fakeAttribute used to avoid the multiple time click
      const pdfThumbnails: any = document.getElementsByClassName("thumbnail__singleExpand")[0].children[1];
      const fakeAttribute = pdfThumbnails.getAttribute("thumbnailShowed");
      if (!fakeAttribute) {
        pdfThumbnails.setAttribute("thumbnailShowed", true);
        pdfThumbnails.click();
      }
      const pdfThumbnailsListView: any = document.getElementsByClassName("thumbnail__singleExpand")[0];
      if (pdfThumbnailsListView) {
        pdfThumbnailsListView.style.setProperty("display", "none");
      }
      // main tab remove the scroll bar
      const bottomArea: any = document.getElementsByClassName("chase-detail-chart-container")[0];
      bottomArea.style.setProperty("height", "calc(100vh - 128px)");
    }
  }

  private makeOriginalView() {
    this.localService.delete(this.localStorageIsWindowOpen);
    this.localService.delete(this.localStoragePageNumber);
    this.localService.delete(this.localStoragePageReload);
    this.isPopOutBtnEnabled = true;
    this.localService.delete(this.localStorageKey);
    const pdfThumbnailsListView: any = document.getElementsByClassName("thumbnail__singleExpand")[0].children[0];
    if (pdfThumbnailsListView) {
      pdfThumbnailsListView.style.pointerEvents = "auto";
      pdfThumbnailsListView.click();
    }
  }

  closeURL() {
    try {
      this.makeOriginalView();
      this.winRef.close();
      window.location.reload();
    } catch {
      window.close();
      window.location.reload();
    }
  }

  openURL() {
    this.maintainStorage();
    this.openOnce(window.location.origin + this.router.url, this.pdfScreenTab);
  }

  private maintainStorage() {
    this.localService.put(this.localStorageKey, "false");
    this.localService.put(this.localStorageIsWindowOpen, "1");
    this.localService.put(this.localStoragePageReload, "0");
    this.mainTab = true;
    this.duplicateTab = false;
  }

  private openOnce(url, target) {
    // open a blank "target" window
    // or get the reference to the existing "target" window
    if (window.name === target) {
      window.name = "";
    }
    this.winRef = window.open("", target, "");
    // if the "target" window was just opened, change its url
    if (this.winRef.location?.href !== url) {
      this.winRef.location.href = url;
    }
    return this.winRef;
  }

  private getValidation(): boolean {
    return !this.hasResearchButtons || (this.hasResearchButtons && this.researchButtonsValidated);
  }

  private submitAndRouteToDashboard(): void {
    if (this.isHedis) {
      this.chartService.submit(this.chaseId, this.status).subscribe(() => {
        this.messagingService.showToast("Submission Succeeded!", SeverityType.SUCCESS);
        this.removeOpenChaseInfo();
        this.submitNavigation();
      });
    } else if (this.useRisk) {
      this.chartService.submit2(this.chaseId, this.status).subscribe(entityErrors => {
        if (ArrayHelper.isAvailable(entityErrors)) {
          const errors = entityErrors.map(this.getRiskError.bind(this)) as RiskError[];
          this.riskService.setData({ errors });
        } else {
          this.messagingService.showToast("Submission Succeeded!", SeverityType.SUCCESS);
          this.removeOpenChaseInfo();
          this.submitNavigation();
        }
      });
    } else {
      this.chartService.submit2(this.chaseId, this.status).subscribe(entityErrors => {
        if (ArrayHelper.isAvailable(entityErrors)) {
          this.chaseDetailStateService.setData({ entityErrors });
        } else {
          this.messagingService.showToast("Submission Succeeded!", SeverityType.SUCCESS);
          this.removeOpenChaseInfo();
          this.submitNavigation();
        }
      });
    }
  }


  private removeOpenChaseInfo(): void {
    const localChaseUserInfo =  this.localService.get(this.openChaseAndUserInfo, null);
    if (localChaseUserInfo) {
      this.localService.delete(this.openChaseAndUserInfo);
    }
  }

  private submitNavigation(): void {
    if (this.isMemberCentricChase && ArrayHelper.isAvailable(this.siblingChases)) {
      this.navigateToChase(this.siblingChases[0].chaseId);
      this.scrollToTop();
    } else {
      this.getQueueOrNextChase();
    }
  }

  private scrollToTop() {
    const scrollElement = document.getElementById("scrollElement");
    if (scrollElement) {
      scrollElement.scrollTop = 0;
    }
  }

  private getQueueOrNextChase(): void {
    const request = new ChaseSearchRequest(
      null, null, null, this.functionalRoleId, null, this.user.userId, null, null, null, null, null, null, 0, 1, null, null, null, null, null, this.chaseDetailState.reportingStatusName
    );
    this.chaseSearchService.chaseSearch(request).subscribe(data => {
      if (ArrayHelper.isAvailable(data)) {
        this.navigateToChase(data[0].chaseId, true);
        this.scrollToTop();
      } else {
        this.router.navigate(["dashboard", "clinical"]);
      }
    });
  }

  private navigateToChase(chaseId: number, reload: boolean = false): void {
    const path = this.router.url.includes("expanded") ? "/members/chase" : this.router.url.substr(0, this.router.url.lastIndexOf("\/"));

    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([`${path}/${chaseId}`], { state: { reload } });
      this.handleNavigateAfterSubmit(path, chaseId);
    }
    );

  }

  private handleNavigateAfterSubmit(path: string, chaseId: number) {
    if (this.mainTab && this.localService.get(this.localStorageIsWindowOpen, null) === "1") {
      // api call only in collapse view
      const pdfThumbnailsListView: any = document.getElementsByClassName("thumbnail__singleExpand")[0];
      if (pdfThumbnailsListView) {
        pdfThumbnailsListView.style.setProperty("display", "block");
      }
      setTimeout(() => {
        this.localService.delete(this.localStorageKey);
        this.openOnce(`${window.location.origin}/${path}/${chaseId}`, this.pdfScreenTab);
        // select the pdf icon for collapse view
        const pdfThumbnails: any = document.getElementsByClassName("thumbnail__singleExpand")[0].children[0];
        pdfThumbnails.click();
        if (pdfThumbnailsListView) {
          pdfThumbnailsListView.style.setProperty("display", "none");
        }
      },         500);
      setTimeout(() => {
        this.localService.put(this.localStorageKey, "false");
        this.localService.put(this.localStorageIsWindowOpen, "1");
      },         1000);
    }
  }

  private getRiskError(entityError: EntityError): RiskError {
    const entity = this.riskService.data.value.encounters.reduce((acc, encounter) => {
      if (acc != null) {
        return acc;
      } else if (encounter.entityId === entityError.entityId) {
        return encounter;
      } else {
        return encounter.diagnoses.find(diagnosis => diagnosis.entityId === entityError.entityId);
      }
    },                                                           null);

    const riskError = new RiskError({
      entityId: entity.entityId,
      parentEntityId: entity.parentEntityId,
      message: entityError.validationMessage,
    });

    return riskError;
  }

  private markAllAsDirtyAndTouched = (control: AbstractControl): void => {
    control.markAsDirty();
    control.markAsTouched();

    const controls = (control as any).controls || {};
    const keys = Object.keys(controls);
    if (ArrayHelper.isAvailable(keys)) {
      keys.forEach(key => this.markAllAsDirtyAndTouched(controls[key]));
    }
  }


  private markAllAsDisabledIfMemberIsNotValid = (models: DynamicControl[], isMemberCorrect: boolean = false): void => {
    if (!ArrayHelper.isAvailable(models)) {
      setTimeout(() => this.markAllAsDisabledIfMemberIsNotValid(this.formModels, isMemberCorrect), 100);
    }
    (models || []).forEach(model => {
      const controls = (model as DynamicGroup).controls || (model as DynamicArray).models;
      const isDefaultDisabled = model.disabled;
      model.disabled = !this.isEnabled || model.isAdmin || model.isSupplemental;

      if (ArrayHelper.isAvailable(controls)) {
        this.markAllAsDisabledIfMemberIsNotValid(controls, isMemberCorrect);
      } else if (typeof (model as DynamicInput).getMasterKey === "function") {
        const masterKey = (model as DynamicInput).getMasterKey();
        const control = this.formGroup.get(masterKey);
        const saveInfoValue = (control as any)?.saveInfo;
        if (model.disabled) {
          control.disable({ onlySelf: true });
          if (this.isEnabled && this.isHedis && model.isNlp) {
            control?.enable();
          }
        } else {// TODO: Look into creating method to update control properties when there are more control types.
            if (isMemberCorrect) {
              model.controlType === "textbox-source" ? control.disable({onlySelf: true}) : control?.enable();
            } else {
              if (model.controlType === "autocomplete" && isDefaultDisabled) {
                control.disable({ onlySelf: true });
              }
            }
        }
      }
    });
  }

  handleClick(event: any) {
    if (this.localService.get(this.localStorageKey, null) === "false") {
      const pagenumber: any = document.getElementById(event.target.id.split("_")[0].concat("_28"));
      this.localService.delete(this.localStoragePageNumber);
      if (pagenumber.value !== undefined && pagenumber.value !== "0") {
        this.localService.put(this.localStoragePageNumber, pagenumber.value);
      }
    }
  }

  handleChange(event: DynamicFormEvent): void {
    switch (event.type) {
      case "compliance":
        this.chartService.onChangeEmit();
        break;
      case "save":
        this.save(event);
        break;
      case "clear":
      case "delete":
        this.confirmDelete(event);
        break;
      default:
        break;
    }
  }

  confirmDelete(event: DynamicFormEvent): void {
    const isNLPSource = this.chartService.isNLPSource(event.model.controls);
    const isDVASource = this.chartService.isDVASource(event.model.controls);
    if ((isNLPSource || isDVASource) && !this.duplicateTab) {
      this.showConfirmation = true;
      this.deleteEvent = event;
    } else {
      this.delete(event);
    }
  }

  deleteControls() {
    this.delete(this.deleteEvent);
  }

  delete(event: DynamicFormEvent): void {
    const controls = event.model.controls as DynamicInput[];
    const attributes = controls.map(control => control.saveInfo);
    const hasEntityId = attributes.some(attribute => attribute.entityId != null);

    if (hasEntityId) {
      this.chartService
        .delete(attributes)
        .subscribe(() => {
          event.successFn();
          this.chartService.onChangeEmit();
          if (event.type === "clear" || event.model.isNlp) {
            this.chartService.updateRefreshCodingForm(true);
          }
        });
    } else {
      event.successFn();
    }
  }

  save(event: DynamicFormEvent): void {
    const controls = event.model.controls;

    const isNLPSource = this.chartService.isNLPSource(controls);
    if (isNLPSource && !this.isHedis) {
      return;
    }

    const textboxSourceControl = controls
      .find(model => model.controlType === "textbox-source");

    const attributes = controls
      .filter(model => model.controlType !== "textbox-source")
      .map(model => {
        model.saveInfo.value = this.getValue(model.key, event.value);
        if (this.isHedis && model.saveInfo.value === undefined) {
        model.saveInfo.value = model.controlType === "autocomplete" ? model.value?.value : model.value;
        }
        return model.saveInfo;
      });

    const isAttributeValueModified = event?.control?.dirty && event?.control?.touched;

    if ((textboxSourceControl?.value === "DVA-" || textboxSourceControl?.value === "EVE") && isAttributeValueModified) {
      textboxSourceControl.value = "";
      attributes.push(this.createNlpReviewResultSaveInfo(textboxSourceControl.saveInfo));
    }

    const hasAnyValue = attributes.some(attribute => StringHelper.isAvailable(attribute.value));

    if (hasAnyValue) {
      if (isAttributeValueModified) {
        if (event.key === "Demographic" && this.isMemberCentricChase && ArrayHelper.isAvailable(attributes)) {
          this.saveDemographic(event, attributes);
        } else {
          this.chartService
            .save(attributes)
            .pipe(finalize(() => {
              this.chartService.onChangeEmit();
              this.formService.updateDom.next();
            }))
            .subscribe(
              newAttributes => {
                event.model.controls.forEach(control => {
                  const attribute = newAttributes.find(newAttribute => newAttribute.id === control.saveInfo?.id);
                  if (isAttributeValueModified) {
                    if (attribute) { attribute.isNlp = false; }
                    control.isNlp = false;
                  }
                  control.saveInfo = attribute;
                  control.isChanged = attribute?.isChanged;
                });
                // Update the source as MR when the attribute is manually modified.
                this.formGroup.get(textboxSourceControl.getMasterKey()).setValue("MR");

                event.successFn();
              },
              (e: HttpErrorResponse) => {
                event.control.setErrors({ saveError: e.error });
              }
            );
        }
      }
    } else {
      this.delete(event);
    }
  }

  private createNlpReviewResultSaveInfo(configuration: Attribute): Attribute {
    const nlpReviewResultSaveInfo = new Attribute({
      chaseId: configuration.chaseId,
      entityId: configuration.entityId,
      entityTypeId: configuration.entityTypeId,
      sourceTypeId: null,
      dataType: configuration.dataType,
      id: NLP_REVIEW_RESULT.attributeId,
      code: NLP_REVIEW_RESULT.attributeCode,
      value: "",
    });

    return nlpReviewResultSaveInfo;
  }

  private saveDemographic(event: DynamicFormEvent, attributes: DynamicEntityAttribute[]) {
    attributes[0].chaseIds = [...this.siblingChases.map(x => x.chaseId), this.chaseId];
    this.chartService
      .saveDemographic(attributes)
      .pipe(finalize(() => {
        this.chartService.onChangeEmit();
        this.formService.updateDom.next();
      }))
      .subscribe(() => {
        event.successFn();
      }
      ,          error => {
        this.messagingService.showToast("Failed.", SeverityType.ERROR);
      }
      );
  }
  getForm(): void {
    // TODO: We need to refactor this section. Will cause problems once we start using chase detail state.
    if (NumberHelper.isGreaterThan(this.chaseId, 0)) {
      if (this.projectType == null) {
        setTimeout(() => this.getForm(), 50);
        return;
      }

      if (this.isHedis) {
        this.chartService.getConfiguration(this.chaseId, true).subscribe(items => {
          if (!this.isDemographicRequried) {
            items.forEach((data, index) => {
              if (data.key === "RequiredMemberData") {
                items.splice(index, 1);
              }
            }
            );
          }
          this.formModels = items;
          this.formGroup = this.formService.createFormGroup(items);
          this.getMemberValidation();
          this.isFormReloading = false;
          this.isFormReloadingComplete = true;
          this.changeDetector.markForCheck();
        });
      } else if (this.useRisk || this.isRxcm) {
        const isRisk20Project = false;
        this.riskService.initData(this.chaseId, isRisk20Project, this.displayEncountersWithNoHcc, () => {
          this.getMemberValidation();
          this.changeDetector.markForCheck();
        });
      } else {
        this.getMemberValidation();
        this.changeDetector.markForCheck();
      }
    }
  }

  memberValidationSubmit(memberValidation: MemberValidation, showToast: boolean = false): void {
    const isValid = memberValidation.isValid;
    memberValidation.isValidInfo.chaseIds = [];
    if (isValid) {
      memberValidation.isValidInfo.chaseIds = [...this.siblingChases.map(x => x.chaseId)];
    }
    memberValidation.isValidInfo.chaseIds.push(this.chaseId);
    if (!ObjectHelper.isEmpty(memberValidation)) {
    this.memberValidationService.submit(memberValidation, this.status, this.isMemberCentricChase).subscribe(data => {
      this.memberValidationData = data;
      if (showToast) {
        this.messagingService.showToast("Member Validation Succeeded", SeverityType.SUCCESS);
      }
      this.memberIsValid = this.memberValidationData.isValid;
      this.isCatalyticResultsVisible = this.memberIsValid;
      this.markAllAsDisabledIfMemberIsNotValid(this.formModels, true);

      // TODO: How to handle HST/ENR/ATT?
      if (this.useRisk && isValid) {
        // FIXME: There's probably a better way than hard coding this.
        this.riskService.setData({
          memberValidation: new RiskEntity({
            entityTypeId: EntityType.MEMBER_VALIDATION,
            attributes: [new DynamicEntityAttribute({
              ...MEMBER_VALIDATION_RESULT,
              value: 1,
            })],
          }),
        });
        this.documentViewerSessionService.updateIsMemberValidated(this.memberIsValid);
      } else if (!this.isHedis && isValid) {
        this.chaseDetailStateService.setMemberValidationEntity(
          new DynamicEntity({
            entityTypeId: EntityType.MEMBER_VALIDATION,
            attributes: [new DynamicEntityAttribute({
              ...MEMBER_VALIDATION_RESULT,
              value: 1,
            })],
          }));
      } else if (!isValid) {
        this.router.navigate(["dashboard", "clinical"]);
      }
      this.complianceGridComponent?.setNrcInfo();
    });
  }
  }

  showMemberValidationInputs(isCorrectMember: boolean): void {
    this.showMemberInputs = isCorrectMember;
    if (!isCorrectMember) {
      this.memberIsValid = false;
      this.markAllAsDisabledIfMemberIsNotValid(this.formModels);
      this.isResearchDisable = true;
    } else {
      this.isResearchDisable = false;
    }
  }

  private getMemberValidation(): void {
    if (this.chaseId && StringHelper.isAvailable(this.status)) {
      this.memberValidationService.getData(this.chaseId, this.status).subscribe({
        next: data => {
          this.memberValidationData = data;

          if (this.isMemberCentricChase && ArrayHelper.isAvailable(this.memberValidationData.reasonInfo.options)) {
            const index = this.memberValidationData.reasonInfo.options.findIndex(x => x.value === "5");
            this.memberValidationData.reasonInfo.options.splice(index, 1);
          }
          this.memberIsValid = this.memberValidationData.isValid;

          this.markAllAsDisabledIfMemberIsNotValid(this.formModels, this.memberIsValid);
        },
        error: () => {
          this.memberIsValid = false;
          this.markAllAsDisabledIfMemberIsNotValid(this.formModels);
          this.messagingService.showToast("Correct Member is not available.", SeverityType.ERROR);
        },
      });

    }
  }

  private getValue(key: string, values: object): string {
    const value = values[key];

    if (typeof value === "object" && value != null) {
      return this.getValue("value", value);
    }

    return value;
  }

  showAndGetCatalyticResults(): void {
    this.isCatalyticResultsVisible = true;
  }

  refreshCodingForm(): void {
    this.isFormReloading = true;
    this.getForm();
    this.isFormReloadingComplete = false;
    this.changeDetector.markForCheck();
  }

  onincludeCodingPrintScreen(auditPackageItemId: number): void {
    setTimeout(() => this.initiateCodingPrintScreen(auditPackageItemId));
  }

  initiateCodingPrintScreen(auditPackageItemId: number): void {

    const chaseService = this.chartService;
    const auditPageEntryService = this.auditPageEntryService;
    const chaseId = this.chaseId;
    const codingScreenHtml = document.getElementById("printScreenSection");

    this.messagingService.showToast("Coding Screen capture in progress.", SeverityType.SUCCESS);
    domtoimage.toBlob(codingScreenHtml, { height: codingScreenHtml.scrollHeight, width: codingScreenHtml.offsetWidth, quality: 1 })
      .then(blob => {
        const formdata = new FormData();
        formdata.append("file", blob);
        chaseService.saveCodingScreenshot(formdata, chaseId, auditPackageItemId).subscribe(
          () => auditPageEntryService.codingScreenPrintScreenComplete(true),
          err => auditPageEntryService.codingScreenPrintScreenComplete(false)
        );

      });
  }

  hasNoDocumentPages(event: boolean): void {
    this.hasNoDocPages = event;
  }

  isOpenSplitScreenUrl(event: boolean): void {
    this.makeOriginalView();
    this.winRef?.close();
    this.openURL();
  }

  private createDataEntryLegend(): void {
    const parentClass = "coding-container__legend";
    const textClass = this.auditMode ? "coding-container__legend--textAudit" : "coding-container__legend--text";

    this.dataEntryLegend = [
      new Legend({
        parentClass,
        childClass: "coding-container__legend--admin",
        textClass,
        text: "Admin",
      }),
      new Legend({
        parentClass,
        childClass: "coding-container__legend--supplemental",
        textClass,
        text: "Supplemental",
      }),
      new Legend({
        parentClass,
        childClass: "coding-container__legend--nlpAdd",
        textClass,
        text: "EVE Add",
      }),
    ];
  }

  onExclusionContraError(event: string): void {
    this.compliantInfoError = event;
  }

  addExclusion(): void {
    this.chartService.addExclusion();
  }

  onLoadExclusions(isEnabled: boolean): void {
    this.isAddButtonEnabled = isEnabled;
  }
}
