<div class="control control--group" *ngIf="hasReasonOptions; else noReasonOptions" [ngClass]="classes">

  <div *ngIf="hasErrorMessage" class="control__header__error" [title]="errorMessage">{{ errorMessage }}</div>

  <div class="control control--input--double-width control--dropdown">
    <div class="control__header">
      <label class="control__header__label" for="{{ type }}">{{ type | titlecase }}</label>
    </div>

    <p-dropdown
      appendTo="body"
      [styleClass]="dropdownClass"
      [title]="reasonText"
      [readonly]="!enabled"
      [options]="reasonOptions"
      placeholder="Select..."
      [name]="type"
      [inputId]="type"
      [(ngModel)]="reason"
      [ngModelOptions]="{ updateOn: 'blur' }"
      dropdownIcon="pi pi-caret-down">
    </p-dropdown>
  </div>

  <div class="control control--input control--textbox">
    <div class="control__header">
      <label class="control__header__label" for="{{ type }} Date">{{ getDateLabel }}</label>
    </div>
    <input
      [ngClass]="{'control__input--overread': dateChange}"
      class="control__input control__input--textbox"
      type="text"
      id="{{ type }} Date"
      [ngModel]="date"
      (ngModelChange)="date = formatDate($event)"
      [ngModelOptions]="{ updateOn: 'blur' }"
      [disabled]="!enabled"/>
  </div>

  <div *ngIf="showDateOfDeath" class="control control--input control--textbox">
    <div class="control__header">
      <label class="control__header__label" for="{{ type }} Date of Death">Date of Death</label>
    </div>
    <input
      [ngClass]="{'control__input--overread': dateOfDeathChange}"
      class="control__input control__input--textbox"
      type="text"
      id="{{ type }} Date of Death"
      [ngModel]="dateOfDeath"
      (ngModelChange)="dateOfDeath = formatDate($event)"
      [ngModelOptions]="{ updateOn: 'blur' }"
      [disabled]="!enabled"/>
  </div>

  <div class="control control--input control--textbox">
    <div class="control__header">
      <label class="control__header__label" for="{{ type }} Exclusion Page Number">Exclusion Page #</label>
    </div>
    <input
      [ngClass]="{'control__input--overread': pageNumberChange}"
      class="control__input control__input--textbox"
      type="number"
      step="1"
      id="{{ type }} Exclusion Page Number"
      [(ngModel)]="pageNumber"
      [ngModelOptions]="{ updateOn: 'blur' }"
      [disabled]="!enabled" />
  </div>

  <button class="control__delete" (click)="remove()" tabindex="-1">-</button>

</div>
<ng-template #noReasonOptions>
  <div>No {{ type }}s</div>
</ng-template>