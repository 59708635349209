import { DateHelper } from "../../../utilities/contracts/date-helper";
import { StringHelper } from "../../../utilities/contracts/string-helper";
import { IAutoMapper } from "../interfaces";

export const mapChaseListItems = (automapper: IAutoMapper): void => {
  automapper
    .createMap("chase-search-item", "ChaseItem")
    .forMember("assignedTo", o => o.assignedTo)
    .forMember("assignedToUserId", o => o.assignedToUserId)
    .forMember("chaseCommentFormatted", o => StringHelper.clean(o.chaseCommentFormatted))
    .forMember("chartCommitmentDate", o => StringHelper.clean(o.chartCommitmentDate))
    .forMember("chartExpectedDate", o => StringHelper.clean(o.chartExpectedDate))
    .forMember("chartRequestDate", o => StringHelper.clean(o.chartRequestDate))
    .forMember("chaseComplianceCode", o => o.chaseComplianceCode)
    .forMember("chaseId", o => o.chaseID)
    .forMember("chasePendId", o => o.chasePendId)
    .forMember("createUserId", o => o.createUserId)
    .forMember("commentExists", o => o.commentExists)
    .forMember("coRetrievalOwner", o => o.coRetrievalOwner)
    .forMember("dateOfService", o => o.dateOfService)
    .forMember("documentRequestId", o => o.documentRequestId)
    .forMember("isClinical", o => o.isClinical)
    .forMember("isClinicalAndNonClinicalChases", o => o.isClinicalAndNonClinicalChases)
    .forMember("isThirdParty", o => o.isThirdParty)
    .forMember("masterDocumentSourceId", o => o.masterDocumentSourceId)
    .forMember("measureCode", o => o.measureCode)
    .forMember("measureID", o => o.measureID)
    .forMember("measureName", o => o.measureName)
    .forMember("memberDateOfBirth", o => DateHelper.format(o.memberDateOfBirth))
    .forMember("memberFirstName", o => o.memberFirstName)
    .forMember("memberGender", o => o.memberGender)
    .forMember("memberID", o => o.memberID)
    .forMember("memberLastName", o => o.memberLastName)
    .forMember("memberName", o => o.memberName)
    .forMember("memberValidationReason", o => o.memberValidationReason)
    .forMember("numeratorList", o => o.numeratorList)
    .forMember("openGaps", o => o.openGaps)
    .forMember("organizationId", o => o.organizationId)
    .forMember("parentChaseId", o => o.parentChaseId)
    .forMember("pendOwner", o => o.pendOwner)
    .forMember("pendCode", o => o.pendCode)
    .forMember("pendStatus", o => o.pendStatus)
    .forMember("pendStatusId", o => o.pendStatusId)
    .forMember("product", o => o.product)
    .forMember("projectID", o => o.projectID)
    .forMember("projectName", o => o.projectName)
    .forMember("projectReviewPeriod", o => o.projectReviewPeriod)
    .forMember("providerCount", o => o.providerCount)
    .forMember("providerList", o => o.serviceProviderData)
    .forMember("pursuitChases", o => o.pursuitChases)
    .forMember("recordCount", o => o.recordCount)
    .forMember("recordNumber", o => o.recordNumber)
    .forMember("reportingStatusName", o => o.reportingStatusName)
    .forMember("sampleComplianceCode", o => o.sampleComplianceCode)
    .forMember("serviceProviderData", o => o.serviceProviderData)
    .forMember("serviceProviders", o => o.serviceProviders)
    .forMember("serviceProviderName", o => o.serviceProviderName)
    .forMember("tags", o => o.tags)
    .forMember("tagData", o => o.tagData)
    .forMember("tagItems", o => o.tagItems)
    .forMember("tagsText", o => o.tagsText)
    .forMember("totalOpenGaps", o => o.totalOpenGaps)
    .forMember("workflowStatusId", o => o.workflowStatusId)
    .forMember("workflowStatusName", o => o.workflowStatusName)
    .forMember("previousMasterDocumentSourceID", o => o.previousMasterDocumentSourceID)
    .forMember("outreachTemplateName", o => o.outreachTemplateName)
    .forMember("tin", o => o.tin)
    .forMember("npi", o => o.npi)
    .forMember("contactName", o => o.contactName)
    .forMember("contactPhone", o => o.contactPhone)
    .forMember("isGapProject", o => o.isGapProject)
    .forMember("chaseSourceAliasID", o => o.chaseSourceAliasID)
    .forMember("imageName", o => o.imageName)
    .forMember("groupName", o => o.groupName);
};
