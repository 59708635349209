export enum ProductType {
  CHART_COLLECTION_ONLY = 2,
  FULL_WORKFLOW_V02 = 3,
  DATA_VERIFICATION_V1 = 4,
  CHART_LAKE = 5,
  FULL_WORKFLOW_V2023 = 6,
  FULL_WORKFLOW_V2024 = 7,
  FULL_WORKFLOW_V2025 = 8,
  MEMBER_MANAGEMENT = 9,
}
