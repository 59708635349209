import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LookerMemberManagementDashboardType } from "../../looker-member-management-dashboard-type.enum";
import { HideFilters } from "../../models/analytics-hide-filters.enum";

@Component({
  selector: "app-membership-history-report",
  templateUrl: "./membership-history-report.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembershipHistoryReportComponent {
filters: string[] = ["Projects"];
dashboardType = LookerMemberManagementDashboardType.Membership_History;
hideFilters: string[] = [HideFilters.PROJECT_ID, HideFilters.USER_ID, HideFilters.ORG_ID, HideFilters.CUSTOMER_NAME2];
}
