/* tslint:disable:max-file-line-count */
/* tslint:disable:cyclomatic-complexity */
import { DatePipe } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../../../auth/auth.service";
import { UserToken } from "../../../../auth/user-token.model";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { DownloaderService } from "../../../../core/downloader/downloader.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../core/user/user.service";
import { DynamicControl } from "../../../../dynamic-forms/dynamic-control.model";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Calendar } from "../../../../dynamic-forms/inputs/calendar/calendar.model";
import { CheckboxGroup } from "../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { Checkbox } from "../../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { Radiobutton } from "../../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { Switch } from "../../../../dynamic-forms/inputs/switch/switch.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { dateGreaterThanValidator } from "../../../../dynamic-forms/validators/date-greather-than.validator";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../shared/grid/models/grid-request.model";
import { LogoService } from "../../../../shared/logo/logo.service";
import { ProviderPacketLogoResponseModel } from "../../../../shared/logo/provider-packet-logo-response.model";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../utilities/reg-Ex-Helper";
import { ICanComponentDeactivate } from "../../../api/url-guards/can-deactivate.guard";
import { WorkflowStatusDb } from "../../../api/workflow/workflow-status-db.enum";
import { CHASE_SUBMISSION_ITEM, CODING_REVIEW_MODE } from "../../member/chase-detail/chase-detail-chart/attributes";
import { RetrievalPageService } from "../../retrieval/retrieval-page/retrieval-page.service";
import { ServiceOrgConfigurationService } from "../../service-org-admin/service-org-config/service-org-config.service";
import { LineOfBusiness } from "../new-project/line-of-business.enum";
import { NewProjectService } from "../new-project/new-project.service";
import { ProjectDocumentType } from "../project-files/project-document-type.enum";
import { ProjectType } from "../project-type.enum";
import { ProjectService } from "../project.service";
import { CodingReviewMode } from "./coding-review-mode.enum";
import { ProjectInvoiceSettingComponent } from "./invoice-config/invoice-config.component";
import { AuthorizationLetterAttribute } from "./models/authorization-letter-attribute.model";
import { ChaseSubmissionItem } from "./models/chase-submission-item.model";
import { ChaseSubmissionWorkflowItem } from "./models/chase-submission-workflow-item.model";
import { FileDetail } from "./models/file-detail.model";
import { MappingDocument } from "./models/mapping-document.model";
import { MeasureDocumentsSummary } from "./models/measure-documents-summary.model";
import { IMeasurePriority } from "./models/priority-measure.model";
import { ProjectAttribute } from "./models/project-attribute.model";
import { ProjectConfigSummary } from "./models/project-config-summary.model";
import { ProviderPacket } from "./models/provider-packet.model";
import { SelectWeeks } from "./models/select-weeks.model";
import { ProjectConfigurationService } from "./project-config.service";

enum PriorityOption {
  FIRST_CHART = "First Chart Available",
  MEASURE = "Measure",
}
enum CodingLevelDropdown { FullCoding = "1", RADiagnosisCoding = "2"}


// TODO: Include file in the tslint when the tabs are moved to separate components (and state service is done?).
@Component({
  selector: "project-project-config",
  templateUrl: "./project-config.component.html",
  styleUrls: [ // TODO: Need to remove the styles and add textbox and submenu component.
    "../../../../dynamic-forms/inputs/textbox/textbox.component.scss",
    "./project-config.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectConfigComponent implements ICanComponentDeactivate, OnInit {
  private CHASE_PRIORITY_ITEMS_PER_COLUMN = 25;
  navigateAwayProjectConfig: Subject<boolean> = new Subject<boolean>();
  projectName: string;
  private projectTypeId: number;
  hideEditor = true; // used in view
  @ViewChild("invoiceColumn", { static: true }) invoiceColumn: TemplateRef<ProjectInvoiceSettingComponent>;
  projectSettingsFormGroup: FormGroup;
  measureYearFormGroup: FormGroup;
  projectConfigForm: FormGroup;
  reportingFormGroup: FormGroup;
  riskFormGroup: FormGroup;
  providerPacketFromGroup: FormGroup;
  dataTransferFtpFormGroup: FormGroup;
  providerPacketSettingsFormGroup: FormGroup;
  hedisMeasureFormGroup: FormGroup;
  coverLetterRequired: Radiobutton;
  authLetterRequired: Radiobutton;
  dosPullListRequired: Radiobutton;
  memberListRequired: Radiobutton;
  chaseLetterRequired: Radiobutton;
  providerPackageCheckIncludeDocumentRequestForGap: Radiobutton;
  addressInsertRequired: Radiobutton;
  startDate: Textbox;
  endDate: Textbox;
  projectOwner: Dropdown;
  targetCoderQaScore: Textbox;
  clinicalGuidelinesSignOffDate: Calendar;
  documentRequestLinesApprovalDate: Calendar;
  providerLetterReceivedDate: Calendar;
  measureYear: Dropdown;
  reportingYear: Dropdown;
  expectedChaseCount: Textbox;
  expectedSampleFeedReceiptDate: Textbox;
  isReveleerProvidingServices: Switch;
  reveleerServiceProvided: Radiobutton;
  researchType: Radiobutton;
  dataEntryLocation: Radiobutton;
  serviceImplementationStartDate: Calendar;
  retrievalServiceStartDate: Calendar;
  retrievalServiceEndDate: Calendar;
  clinicalServiceStartDate: Calendar;
  clinicalServiceEndDate: Calendar;
  targetLoadDate: Textbox;
  initialSampleFeedReceiptDate: Textbox;
  finalSampleFeedReceiptDate: Textbox;
  documentRequestRequiresApproval: Radiobutton;
  pagesRequiresApproval: Radiobutton;
  allowDocumentForInProcessChase: Radiobutton;
  enableToAttachAdditionalDocInIntake: Radiobutton;
  dualRoleChaseAssignmentEnabled: Switch;
  gapProviderOutreachEnabled: Switch;
  overreadFeedbackEnabled: Switch;
  dataEntryProviderConfirmationEnabled: Switch;
  firstPassCodingBotEnabled: Switch;
  autoPendEmptyNlpResult: Switch;
  noNetNewOpportunityAutoPend: Switch;
  isEveOnlyCardsValidationRequired: Radiobutton;
  hiosId: Textbox;
  reviewPeriodFromDate: Textbox;
  reviewPeriodThruDate: Textbox;
  codingLevel: Dropdown;
  codingReviewModeSelection: Dropdown;
  codingOptimizationMode: Radiobutton;
  documentReviewLevel: Dropdown;
  lineOfBusiness: Dropdown;
  hcc: Dropdown;
  hcc2: Dropdown;
  rxHcc: Dropdown;
  serviceProviderRequired: Radiobutton;
  reviewAdminEncountersAndDiagnoses: Radiobutton;
  validationReasonCodeRequired: Radiobutton;
  encounterTypeRequired: Radiobutton;
  hccCardAdditionEnabled: Radiobutton;
  memberCentricChaseRetrieval: Radiobutton;
  mdPortalRetrievalEnabled: Radiobutton;
  dataExtractType: Dropdown;
  dataExtractDelimiter: Dropdown;
  dataExtractIncludeHeader: Radiobutton;
  projectConfigurationName: Textbox;
  projectConfigurationStatus: Switch;
  coverLetterTemplate: Dropdown;
  autocloseMemberCompliantChase: Radiobutton;
  autocloseMemberCompliantChaseForRecentEventMeasure: Radiobutton;
  nrcRequired: Radiobutton;
  demographicRequired: Radiobutton;
  backButtonText = "Go Back";
  selectedTab = "project";
  projectId: number;
  projectStatusFilter: string;
  projectAttributes = null;
  progressMilestones = null;
  documentRequirementsDetails = null;
  qaRates = null;
  coverLetterDetails = null;
  memberListDetails = null;
  chaseLetterDetails = null;
  milestoneRetrievalList = ["contacted", "scheduled", "retrieved"];
  milestoneClinicalList = ["abstracted"];
  listOfWeeks: SelectableInput[] = [];
  projectList: any[] = [];
  coverLetterProjectListInput: Dropdown;
  memberListProjectListInput: Dropdown;
  chaseLetterProjectListInput: Dropdown;
  measureListInput: Dropdown;
  shortDescriptionMeasureListInput: Dropdown;
  shortDescriptionMeasureTextBox: Textbox;
  shortDescriptionMeasureText: string;
  chaseLetterTemplateList: any[] = [];
  listRequiredDocsPerMeasure: any[] = [];
  isCoverLetterProjectSelected = false;
  isMemberListProjectSelected = false;
  isChaseLetterProjectSelected = false;
  isFirstCodingPassBotEnabled = false;
  isHcc2Selected = false;
  selectedCoverLetterProjectID: number;
  selectedMemberListProjectID: number;
  selectedChaseLetterProjectID: number;
  selectedMeasureCode = "";
  shortDescriptionSelectedMeasureCode = "";
  selectedFileList: any[] = [];
  authletterURL: string;
  percentageValidators: ValidatorFn[] = [
    Validators.required,
    Validators.min(0),
    Validators.max(100),
    Validators.pattern(RegExHelper.number0To100),
  ];
  Week: Dropdown;
  timeFrameFrom: Dropdown;
  timeFrameTo: Dropdown;
  formData: FormData = new FormData();
  private projectConfigName: string;
  private projectStatus: number;
  dataTransferSchedulerFormGroup: FormGroup;
  dataTransferEnableSchedule: Switch;
  number: CheckboxGroup;
  hour: Dropdown;
  timezone: Dropdown;
  period: Dropdown;
  schedulerEndDate: Textbox;
  schedulerSettingsDetails = null;
  isWeekDaysDisabled = true;
  isDeficientChasePopupVisible: boolean;
  isRiskBotPopupVisible: boolean;
  headerText: any;
  getNext: Switch;
  hedisGetNextPriority: Radiobutton;
  hedisGetNextPriorityPlaceholder: Radiobutton;
  hedisMeasureInputs: Textbox[] = [];
  clinicalGetNext: Switch;
  chasePrioritySettingInput: Dropdown;
  chasePrioritySetting = null;
  chaseSubmissionItem: ChaseSubmissionWorkflowItem[] = [];
  invoiceGridConfiguration = new GridConfiguration();
  vendorInvoiceCODValue: Textbox;
  invoiceGridRequest: GridRequest;
  vendorsUrl = `${this.baseApiUrl}projectconfiguration/vendor`;
  refreshGrid = new EventEmitter<boolean>(true);
  fileDetail: FileDetail;
  authorizationLetterAttributes: AuthorizationLetterAttribute[];
  fileId: string;
  readonly projectDocumentTypeId = ProjectDocumentType.Project;
  confirmationTextForChangingProjectStatus: string;
  showConfirmationForProjectStatusChange: boolean;
  isEveForAbstractionPopupVisible: boolean;

  codingSuppressDxForConfirmedHcc: Switch;
  gridData: any[] = [];
  formGroup: FormGroup;
  formModels: DynamicControl[];
  formModelsFirstPass: DynamicControl[];
  botSetting = null;
  gridConfiguration = new GridConfiguration();
  firstPassGridConfiguration = new GridConfiguration();
  rowType = false;
  rowIndex = 6;
  selectedRiskBotOption: string;
  riskBotPopUpText: string;
  helperTextClass = "risk-helper-text ";

  enrMappingDocumentURL: string;
  enrCrossWalkURL: string;
  rxcMappingDocumentURL: string;
  rxcCrossWalkURL: string;
  ivaMissingMedicalURL: string;
  ivaManifestUrl: string;
  ivaManifestUrl2: string;
  ivaManifestUrl3: string;
  ivaSubsampleUrl: string;

  enrMappingDocumentAttribute: MappingDocument;
  enrCrossWalkAttribute: MappingDocument;
  rxcMappingDocumentAttribute: MappingDocument;
  rxcCrossWalkAttribute: MappingDocument;
  ivaMissingMedicalAttribute: MappingDocument;
  ivaManifestAttribute: MappingDocument;
  ivaManifestAttribute2: MappingDocument;
  ivaManifestAttribute3: MappingDocument;
  ivaSubsampleAttribute: MappingDocument;
  ivaMappingArray: MappingDocument[];
  enrMappingText = "ENR - Mapping Document";
  enrCrosswalkText = "ENR - Crosswalk";
  rxcMappingText = "RXC - Mapping Document";
  rxcCrosswalkText = "RXC - Crosswalk";
  ivaMissingMedicalText = "Missing Medical Records";
  ivaManifestText = "File Manifest Package 1";
  ivaManifest2Text = "File Manifest Package 2";
  ivaManifest3Text = "File Manifest Package 3";
  ivaSubsampleText = "SVA Subsample report";
  IsDocumentQAEnabled = false;
  weekInputFormGroup: FormGroup;
  firstPassBotEnabled: Switch;
  totalChartsBotDataEnteredValue: Textbox;
  hasFirstPassBotEnabled = false;
  totalChartBotFirstPassComputedValue: number;
  botFirstPassAttributeData: any[] = [];
  firstPassBotSetting = null;
  isDocumentHasTimeFrame = true;
  formGroupAbstraction: FormGroup;
  maxNumOfChasesPerPage: Textbox;
  reveleerProjectContacts: Textbox;
  private user: UserToken;
  isSOFirstPassBotEnabled = false;
  isSOFirstPassBotEnabledForRisk = false;
  isSOFirstPassBotEnabledForHedis = false;
  saveType = "button";
  showConfirmChangeNavigation = false;
  chaseSubmissionItemModified = false;
  uploadedImageUrl: SafeResourceUrl;
  logo: SafeResourceUrl;
  selectedFile: any[] = [];
  uploadedFileName: string;
  @ViewChild("fileUpload") fileUpload: any;
  providerPacketLogoFormData: FormData = new FormData();
  enableProjectConfigSave = false;
  chartChangeActions = null;
  maxFileSizeForProviderPacketLogoUploadInBytes: number;
  isShowChasePrioritySetting: boolean;
  chartChangeActionsTooltipText: string;
  isMDPortalSettingEnabled: boolean;
  private codingReviewMode = null;
  onLoadDisableVRCANDServiceType = false;
  vendorMatchingRequired: Radiobutton;
  hccDynamicSuppression: Dropdown;
  abstractionFormGroup: FormGroup;
  allSelectionCheckbox: Checkbox;

  CODING_REVIEW_TOOLTIPS: { [key: string]: string } = {
    [CodingReviewMode.ENCOUNTER]: "2.0 Encounter Mode: Allows the user to review each date of service (DOS) and all associated ICD codes for that specific DOS, one DOS at a time. Additionally, custom VRC (Validation Reason Codes) codes can be created and loading of claims data for validation is allowed.",
    [CodingReviewMode.DIAGNOSIS]: "2.1 Dx Review Mode: Allows the user to see all dates of service (DOS) and associated ICD codes that map to a unique HCC. No ability to create custom VRC codes. VRC00 for Valid ICD and VRC39 for Invalid ICD is auto-appended. Claims data validation is allowed, but the HCC card can only have Valid or Invalid action. No ability to mark one ICD as valid and the other as invalid within one HCC card.",
    [CodingReviewMode.DIAGNOSIS_V3]: "3.0 EVE RISK: Allows the broadest flexibility for risk adjustment coding. Loading/validation of claims data, creation of custom VRC codes, HCC coding, encounter coding and numerous filters for faster, more efficient drill down for your staff members.",
  };


  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private automapper: AutomapperService,
    private fb: FormBuilder,
    private readonly formService: FormService,
    private service: ProjectConfigurationService,
    private projectService: ProjectService,
    private newProjectService: NewProjectService,
    private messagingService: MessagingService,
    private retrievalService: RetrievalPageService,
    private serviceOrgConfigurationService: ServiceOrgConfigurationService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private changeDetector: ChangeDetectorRef,
    private userService: UserService,
    private downloaderService: DownloaderService,
    private readonly domSanitizer: DomSanitizer,
    private readonly logoService: LogoService
  ) { }

  ngOnInit() {
    this.projectId = parseInt(this.route.snapshot.params.projectId, 10);
    this.user = this.userService.getUserToken();
    this.maxFileSizeForProviderPacketLogoUploadInBytes = this.user.providerPacketLogoUploadSizeInBytes;
    this.isSOFirstPassBotEnabledForRisk = this.user.enableRiskFirstPassBot;
    this.isSOFirstPassBotEnabledForHedis = this.user.enableHedisFirstPassBot;
    this.isMDPortalSettingEnabled = this.user.isMDPortalSettingEnabled;
    this.getCompanyLogoUrl();
    this.createFirstPassGrid();
    this.getAllMeasureResult();
    this.getComputedValueForChartsFirstPassBot();
    this.listOfWeeks = this.getListOfWeeks();
    this.loadSubmissionItemTabs();
    this.chartChangeActionsTooltipText = "The Chase move back is based on Sample Gap Compliance. Compliant: Compliant includes compliant and supplemental compliant chases and has not been through OR1 and/or OR2";

    this.allSelectionCheckbox = new Checkbox({
      key: "allSelectionCheckbox",
    });

    this.startDate = new Textbox({
      key: "startDate",
      label: "Start Date:",
      placeholder: "Start Date",
      validators: [Validators.pattern(RegExHelper.date)],
      errorMessages: {
        pattern: "Date should be in MM/DD/YYYY format.",
      },
    });

    this.endDate = new Textbox({
      key: "endDate",
      label: "End Date:",
      placeholder: "End Date",
      validators: [Validators.pattern(RegExHelper.date)],
      errorMessages: {
        pattern: "Date should be in MM/DD/YYYY format.",
      },
    });

    this.projectOwner = new Dropdown({
      key: "projectOwner",
      label: "Project Owner",
      placeholder: "Select an Owner",
      validators: [Validators.required],
      errorMessages: {
        required: "Project owner is required.",
      },
    });

    this.clinicalGuidelinesSignOffDate = new Calendar({
      key: "clinicalGuidelinesSignOffDate",
      label: "Sign-Off on Clinical Guidelines",
      placeholder: "Sign-Off on Clinical Guidelines",
      validators: [Validators.required],
      errorMessages: {
        required: "Enter Date",
      },
    });

    this.documentRequestLinesApprovalDate = new Calendar({
      key: "documentRequestLinesApprovalDate",
      label: "Document Request Lines Approval",
      placeholder: "Document Request Lines Approval",
      validators: [Validators.required],
      errorMessages: {
        required: "Enter Date",
      },
    });

    this.providerLetterReceivedDate = new Calendar({
      key: "providerLetterReceivedDate",
      label: "Provider Letter Received",
      placeholder: "Provider Letter Received",
      validators: [Validators.required],
      errorMessages: {
        required: "Enter Date",
      },
    });

    this.measureYear = new Dropdown({
      key: "measureYear",
      label: "Measure Year",
      placeholder: "Select a year",
      options: [
        new SelectableInput({ text: "2025", value: "2025" }),
        new SelectableInput({ text: "2024", value: "2024" }),
        new SelectableInput({ text: "2023", value: "2023" }),
        new SelectableInput({ text: "2022", value: "2022" }),
        new SelectableInput({ text: "2021", value: "2021" }),
        new SelectableInput({ text: "2020", value: "2020" }),
        new SelectableInput({ text: "2019", value: "2019" }),
        new SelectableInput({ text: "2018", value: "2018" }),
      ],
      disabled: true,
      validators: [Validators.required],
      errorMessages: {
        required: "Measure year is required.",
      },
    });
    this.reportingYear =
      new Dropdown({
        key: "reportingYear",
        placeholder: "Select Reporting Year",
        options: [
          new SelectableInput({ text: "2026", value: "2026" }),
          new SelectableInput({ text: "2025", value: "2025" }),
          new SelectableInput({ text: "2024", value: "2024" }),
          new SelectableInput({ text: "2023", value: "2023" }),
          new SelectableInput({ text: "2022", value: "2022" }),
          new SelectableInput({ text: "2021", value: "2021" }),
          new SelectableInput({ text: "2020", value: "2020" }),
          new SelectableInput({ text: "2019", value: "2019" }),
        ],
        disabled: true,
        label: "Reporting Year",
        errorMessages: {
          required: "Reporting year is required.",
        },
      });
    this.timeFrameFrom = new Dropdown({
      key: "timeFrameFrom",
    });
    this.timeFrameTo = new Dropdown({
      key: "timeFrameTo",
    });

    this.autocloseMemberCompliantChase = new Radiobutton({
      key: "autocloseMemberCompliantChase",
      label: "Auto-close All Open And Pended Chases When A Member Becomes Compliant",
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
      tooltip: "When a member/measure becomes compliant based on ADMIN Data - all member/measure chases will Auto-Close and Move to a Delivery Status. If compliance is based on Medical Record Review (MRR) and makes the member/measure compliant and the chase has been through OR1 or OR2 associated chases in a status prior to MRR will close. Chases in MRR/OR1/OR2 will need to be completed. These rules apply to all Measures excluding (A1c9, BPD, CBP, GSD, HBD)",
    });
    this.autocloseMemberCompliantChaseForRecentEventMeasure = new Radiobutton({
      key: "autocloseMemberCompliantChaseForRecentEventMeasure",
      label: "Auto-Close All Open and Pended Chases When a Member Becomes Compliant for MOST RECENT Measures",
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
      tooltip: "Applicable to (A1c9, BPD, CBP, GSD, HBD). When a member/measure becomes compliant based on ADMIN Data - all member/measure chases will Auto-Close and Move to a Delivery Status. If compliance is based on Medical Record Review (MRR) and makes the member/measure compliant and the chase has been through OR1 or OR2 associated chases in a status prior to MRR will close. Chases in MRR/OR1/OR2 will need to be completed",
    });


    this.nrcRequired = new Radiobutton({
      key: "nrcRequired",
      label: "NRC REQUIREMENTS FOR CHASE SUBMISSION",
      options: [
        new SelectableInput({ text: "Required", value: "1" }),
        new SelectableInput({ text: "Not Required", value: "0" }),
      ],
    });

    this.demographicRequired = new Radiobutton({
      key: "demographicRequired",
      label: "DEMOGRAPHIC (RACE & ETHNICITY) REQUIREMENTS FOR CHASE SUBMISSION",
      options: [
        new SelectableInput({ text: "Required", value: "1" }),
        new SelectableInput({ text: "Not Required", value: "0" }),
      ],
    });

    this.targetCoderQaScore = new Textbox({
      key: "targetCoderQaScore",
      label: "QA Rate:",
      validators: this.percentageValidators,
      errorMessages: {
        pattern: "Percentage must be between 0 - 100",
        min: "Percentage must be between 0 - 100",
        max: "Percentage must be between 0 - 100",
      },
      useTitleCase: false,
    });

    this.documentRequestRequiresApproval = new Radiobutton({
      key: "documentRequestRequiresApproval",
      label: "DO YOU WANT PURSUITS TO REQUIRE MANAGER/ADMIN APPROVAL?",
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
      validators: [Validators.required],
      tooltip: `This setting is applied when clinical employees create pursuits from data entry. If "Yes" is selected, chases will appear in the pursuit module of the approval center and pursuits will be created upon approval. If "No" is selected, pursuits will be created automatically.`,
    });

    this.reveleerServiceProvided = new Radiobutton({
      key: "reveleerServiceProvided",
      label: "Select which services are being provided by reveleer?",
      options: [
        new SelectableInput({ text: "Retrieval", value: "Retrieval" }),
        new SelectableInput({ text: "Clinical Data Entry", value: "Clinical" }),
        new SelectableInput({ text: "Retrieval + Clinical Data Entry", value: "Both" }),
      ],
    });

    this.dataEntryLocation = new Radiobutton({
      key: "dataEntryLocation",
      label: "Is data entry being performed onshore or offshore?",
      options: [
        new SelectableInput({ text: "Onshore", value: "Onshore" }),
        new SelectableInput({ text: "Offshore", value: "Offshore" }),
      ],
      tooltip: `This setting is to capture whether data entry will be done by onshore or offshore clinicians. This does not drive any workflow and is only used for analytics.`,
    });

    this.researchType = new Radiobutton({
      key: "researchType",
      label: "Is this project retrospective or prospective?",
      options: [
        new SelectableInput({ text: "Retrospective", value: "Retrospective" }),
        new SelectableInput({ text: "Prospective", value: "Prospective" }),
      ],
      tooltip: `This setting is to capture whether this project is for a retrospective or prospective review. This does not drive any workflow and is only used for analytics.`,
    });

    this.serviceImplementationStartDate = new Calendar({
      key: "serviceImplementationStartDate",
      label: "Implementation Start Date",
      errorMessages: {
        required: "Enter Date",
      },
    });

    this.retrievalServiceStartDate = new Calendar({
      key: "retrievalServiceStartDate",
      label: "Retrieval Start Date",
      errorMessages: {
        required: "Enter Date",
      },
    });

    this.retrievalServiceEndDate = new Calendar({
      key: "retrievalServiceEndDate",
      label: "Retrieval End Date",
      errorMessages: {
        required: "Enter Date",
        dateGreaterThan: "End Date should be greater than Start Date",
      },
    });

    this.clinicalServiceStartDate = new Calendar({
      key: "clinicalServiceStartDate",
      label: "Clinical Start Date",
      errorMessages: {
        required: "Enter Date",
      },
    });

    this.clinicalServiceEndDate = new Calendar({
      key: "clinicalServiceEndDate",
      label: "Clinical End Date",
      errorMessages: {
        required: "Enter Date",
        dateGreaterThan: "End Date should be greater than Start Date",
      },
    });

    this.pagesRequiresApproval = new Radiobutton({
      key: "pagesRequiresApproval",
      label: "DO YOU WANT NEW PAGES TO REQUIRE MANAGER/ADMIN APPROVAL?",
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
      validators: [Validators.required],
      tooltip: `This setting controls how the system handles additional documentation for chases beyond data entry. When set to "yes", new pages must be manually reviewed in the 'New Pages' grid for chases to move back to data entry. When set to "no", any additional documentation for chases beyond data entry will automatically send the chase back to data entry to be abstracted with the new documentation.`,
    });

    this.allowDocumentForInProcessChase = new Radiobutton({
      key: "allowDocumentForInProcessChase",
      label: "HOW DO YOU WANT TO HANDLE NEW CHASE CREATION FROM ADDITIONAL PAGES?",
      options: [
        new SelectableInput({ text: "Send to Document Approval Center", value: "1" }),
        new SelectableInput({ text: "Automatically Approve", value: "2" }),
        new SelectableInput({ text: "Automatically Deny", value: "0" }),
      ],
      validators: [Validators.required],
      tooltip: `This setting determines what happens when pages are added to a chase that has already gone through data entry. This applies whether the pages are added manually or by an automated process.`,
    });

    this.enableToAttachAdditionalDocInIntake = new Radiobutton({
      key: "enableToAttachAdditionalDocInIntake",
      label: "HOW DO YOU WANT TO HANDLE PAGES ADDED TO INACTIVE CHASES",
      options: [
        new SelectableInput({ text: "Send to Document Approval Center", value: "2" }),
        new SelectableInput({ text: "Automatically Approve", value: "1" }),
        new SelectableInput({ text: "Automatically Deny", value: "0" }),
      ],
      validators: [Validators.required],
      tooltip: `This setting determines what happens when pages are added to a chase that is in an inactive status. This applies whether the pages are added manually or by an automated process.`,
    });

    this.dualRoleChaseAssignmentEnabled = new Switch({
      key: "dualRoleChaseAssignmentEnabled",
      label: "Enable Dual Role Assignment",
      preceedingOffLabel: "Inactive",
      followingOnLabel: "Active",
      tooltip: "If your employee performs both Retrieval and Clinical Abstraction, you can automatically keep the chase assigned to them.",

    });

    this.gapProviderOutreachEnabled = new Switch({
      key: "gapProviderOutreachEnabled",
      label: "ENABLE GAP PURSUIT CREATION",
      preceedingOffLabel: "Inactive",
      followingOnLabel: "Active",
      tooltip: "This feature allows for gap specific provider outreach on chases that still have non-compliant numerators after overread. Gap Pursuits will automatically be generated on a weekly basis.",
    });

    this.overreadFeedbackEnabled = new Switch({
      key: "overreadFeedbackEnabled",
      label: "Enable Overread Feedback Tool",
      preceedingOffLabel: "Inactive",
      followingOnLabel: "Active",
      tooltip: "This tool allows Abstractors, Overreaders and Managers to review and resolve data entry discrepancies. Please note, chases will be held until all feedback is resolved.",
    });

    this.dataEntryProviderConfirmationEnabled = new Switch({
      key: "dataEntryProviderConfirmationEnabled",
      label: "Provider Confirmation",
      preceedingOffLabel: "Inactive",
      followingOnLabel: "Active",
      tooltip: "When enabled, Abstractors will have the opportunity to update the associated provider to the medical record.",
    });

    this.autoPendEmptyNlpResult = new Switch({
      key: "autoPendEmptyNlpResult",
      label: "EVE 0 Hits Auto PEND",
      preceedingOffLabel: "Inactive",
      followingOnLabel: "Active",
      tooltip: this.getAutoPendEmptyNlpResultTooltip,
    });

    this.noNetNewOpportunityAutoPend = new Switch({
      key: "noNetNewOpportunityAutoPend",
      label: "No Net New Opportunity Auto Pend",
      preceedingOffLabel: "Disable",
      followingOnLabel: "Enable",
      tooltip: "EVE did not retrieve net new HCC, chase pends with PC-1030 and moves delivery",
    });

    this.isEveOnlyCardsValidationRequired = new Radiobutton({
      key: "isEveOnlyCardsValidationRequired",
      label: "Is Coder Required to Review All EVE only HCC?",
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
    });

    this.expectedChaseCount = new Textbox({
      key: "expectedChaseCount",
      label: "Expected Chase Count:",
    });

    this.expectedSampleFeedReceiptDate = new Textbox({
      key: "expectedSampleFeedReceiptDate",
      label: "Expected Sample Receipt Date:",
      validators: [Validators.pattern(RegExHelper.date)],
      errorMessages: {
        pattern: "Date should be in MM/DD/YYYY format.",
      },
    });

    this.isReveleerProvidingServices = new Switch({
      key: "isReveleerProvidingServices",
      label: "Is Reveleer performing services for this project?",
      preceedingOffLabel: "No",
      followingOnLabel: "Yes",
      tooltip: "This setting should only be set to ‘Yes’ if Reveleer is performing services for this project. These settings are for reporting purposes only, they do not drive any changes in functionality",
    });

    this.targetLoadDate = new Textbox({
      key: "targetLoadDate",
      label: "Target Load Date:",
      validators: [Validators.pattern(RegExHelper.date)],
      errorMessages: {
        pattern: "Date should be in MM/DD/YYYY format.",
      },
    });

    this.initialSampleFeedReceiptDate = new Textbox({
      key: "initialSampleFeedReceiptDate",
      label: "Initial Sample Receipt Date:",
      validators: [Validators.pattern(RegExHelper.date)],
      errorMessages: {
        pattern: "Date should be in MM/DD/YYYY format.",
      },
    });

    this.finalSampleFeedReceiptDate = new Textbox({
      key: "finalSampleFeedReceiptDate",
      label: "Final Sample Receipt Date:",
      validators: [Validators.pattern(RegExHelper.date)],
      errorMessages: {
        pattern: "Date should be in MM/DD/YYYY format.",
      },
    });

    this.reviewPeriodFromDate = new Textbox({
      key: "reviewPeriodFromDate",
      label: "Review Period From Date:",
      validators: [Validators.pattern(RegExHelper.date)],
      errorMessages: {
        pattern: "Date should be in MM/DD/YYYY format.",
      },
      disabled: true,
    });

    this.reviewPeriodThruDate = new Textbox({
      key: "reviewPeriodThruDate",
      label: "Review Period Thru Date:",
      validators: [Validators.pattern(RegExHelper.date)],
      errorMessages: {
        pattern: "Date should be in MM/DD/YYYY format.",
      },
      disabled: true,
    });

    this.codingLevel = new Dropdown({
      key: "codingLevel",
      label: "Coding Level",
      placeholder: "Select coding",
    });
    this.codingReviewModeSelection = new Dropdown({
      key: "codingReviewModeSelection",
      label: "Coding Review Mode",
      placeholder: "Select coding review mode",
    });
    this.codingOptimizationMode = new Radiobutton({
      key: "codingOptimizationMode",
      label: "Display Admin encounters with 0 HCCs",
      options: [
        new SelectableInput({ text: "Yes", value: 2 }),
        new SelectableInput({ text: "No", value: 1 }),
      ],
    });

    this.documentReviewLevel = new Dropdown({
      key: "documentReviewLevel",
      label: "Document Review Level",
      placeholder: "Select Document Review Level",
    });

    this.hcc = new Dropdown({
      key: "hcc",
      label: "Coding Model",
      placeholder: "Select HCC Model",
      validators: [Validators.required],
      errorMessages: {
        required: "HCC Model is required.",
      },
      disabled: true,
    });

    this.hcc2 = new Dropdown({
      key: "hcc2",
      label: "Coding Model 2",
      placeholder: "Select HCC Model",
      disabled: true,
    });

    this.rxHcc = new Dropdown({
      key: "rxHcc",
      label: "Coding Model (Rx)",
      disabled: true,
    });

    this.reviewAdminEncountersAndDiagnoses = new Radiobutton({
      key: "reviewAdminEncountersAndDiagnoses",
      label: "Coder is Required to Review all Claims Encounters & Diagnosis",
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
      validators: [Validators.required],
      tooltip: "If “Yes”, all unreviewed admin claims must be reviewed. If “No”, claims review is optional and system allows chase submission.",
    });

    this.serviceProviderRequired = new Radiobutton({
      key: "serviceProviderRequired",
      label: "IS PROVIDER REQUIRED FOR DATA ENTRY VALIDATION?",
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
      validators: [Validators.required],
    });

    this.validationReasonCodeRequired = new Radiobutton({
      key: "validationReasonCodeRequired",
      label: "IS VRC REQUIRED FOR DATA ENTRY VALIDATION?",
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
      validators: [Validators.required],
    });

    this.encounterTypeRequired = new Radiobutton({
      key: "encounterTypeRequired",
      label: "IS SERVICE TYPE REQUIRED FOR DATA ENTRY VALIDATION?",
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
      validators: [Validators.required],
    });
    this.hccCardAdditionEnabled = new Radiobutton({
      key: "hccCardAdditionEnabled",
      label: "Allow coder to add net new HCC",
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
      validators: [Validators.required],
    });
    this.memberCentricChaseRetrieval = new Radiobutton({
      key: "memberCentricChaseRetrieval",
      label: "Project Creation",
      options: [
        new SelectableInput({ text: "Chase", value: "0" }),
        new SelectableInput({ text: "Member", value: "1" }),
        new SelectableInput({ text: "Gap", value: "2" }),
      ],
      validators: [Validators.required],
      disabled: true,
    });

    this.mdPortalRetrievalEnabled = new Radiobutton({
      key: "mdPortalRetrievalEnabled",
      label: "Do You Want to Retrieve CCDA Files When Possible?",
      tooltip: "If this setting is set to ‘Yes’ then reveleer will attempt to retrieve chases through interoperability. These chases will not appear for traditional retrieval unless no charts are received through interoperability.",
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
    });

    this.hedisGetNextPriority = new Radiobutton({
      key: "hedisGetNextPriority",
      label: "Select Priority",
      tooltip: this.getSelectPriorityToolTip,
      options: [
        new SelectableInput({ text: "First Chart Available", value: "0" }),
        new SelectableInput({ text: "Measures", value: "1" }),
      ],
    });

    this.hedisGetNextPriorityPlaceholder = new Radiobutton({
      key: "hedisGetNextPriorityPlaceholder",
      options: [
        new SelectableInput({ text: "Measures", value: "1", disabled: true }),
      ],
      disabled: true,
    });

    this.dataExtractType = new Dropdown({
      key: "dataExtractType",
      label: "Data Extract Type",
      placeholder: "Select Data Extract",
    });

    this.dataExtractDelimiter = new Dropdown({
      key: "dataExtractDelimiter",
      label: "Delimiter",
      placeholder: "Select Delimiter",
      options: [
        new SelectableInput({ text: "Tab", value: "1" }),
        new SelectableInput({ text: "Pipe", value: "2" }),
        new SelectableInput({ text: "Comma", value: "3" }),
      ],
    });

    this.dataExtractIncludeHeader = new Radiobutton({
      key: "dataExtractIncludeHeader",
      label: "Include Header",
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
      validators: [Validators.required],
    });

    this.projectConfigurationName = new Textbox({
      key: "projectConfigurationName",
      label: "Project Name:",
      placeholder: "Project Name",
      validators: [Validators.required],
      errorMessages: {
        required: "Project name is required.",
      },
    });

    this.projectConfigurationStatus = new Switch({
      key: "projectConfigurationStatus",
      label: "Set Project Status",
      preceedingOffLabel: "Inactive",
      followingOnLabel: "Active",
    });

    this.dataTransferEnableSchedule = new Switch({
      key: "dataTransferEnableSchedule",
      label: "Data Extract Schedule:",
      subLabel: "Enable Scheduler",
    });

    this.number = new CheckboxGroup({
      key: "number",
      label: "Set day:",
      options: [
        { text: "Monday", value: "01" }, { text: "Tuesday", value: "02" }, { text: "Wednesday", value: "03" },
        { text: "Thursday", value: "04" }, { text: "Friday", value: "05" }, { text: "Saturday", value: "06" }, { text: "Sunday", value: "00" },
      ],
      errorMessages: {
        required: "Select atleast one day.",
      },
    });

    this.vendorMatchingRequired = new Radiobutton({
      key: "vendorMatchingRequired",
      label: "Do You Want to Apply Vendor Matching During Data Load?",
      tooltip: "If this setting is set to ‘Yes’, reveleer will attempt to find a matching 3rd party copy vendor for each of the chases that are loaded to this project. The possible vendor matches are CIOX, MediCopy, MRO, Scanning Sherpas, ScanStat Technologies, ShareCare Bactes, and Verisma Systems.",
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
    });

    this.hour = new Dropdown({
      key: "hour",
      label: "Set Hour:",
      placeholder: "Select...",
      disabled: true,
      options: [
        new SelectableInput({ text: "01", value: "01" }),
        new SelectableInput({ text: "02", value: "02" }),
        new SelectableInput({ text: "03", value: "03" }),
        new SelectableInput({ text: "04", value: "04" }),
        new SelectableInput({ text: "05", value: "05" }),
        new SelectableInput({ text: "06", value: "06" }),
        new SelectableInput({ text: "07", value: "07" }),
        new SelectableInput({ text: "08", value: "08" }),
        new SelectableInput({ text: "09", value: "09" }),
        new SelectableInput({ text: "10", value: "10" }),
        new SelectableInput({ text: "11", value: "11" }),
        new SelectableInput({ text: "12", value: "12" }),
      ],
      validators: [Validators.required],
      errorMessages: {
        required: "Hour is required.",
      },
    });

    this.hccDynamicSuppression = new Dropdown({
      key: "hccDynamicSuppression",
      label: "",
      placeholder: "Select...",
      options: [
        new SelectableInput({ text: "1", value: 1 }),
        new SelectableInput({ text: "2", value: 2 }),
        new SelectableInput({ text: "3", value: 3 }),
        new SelectableInput({ text: "4", value: 4 }),
        new SelectableInput({ text: "5", value: 5 }),
      ],
    });

    this.period = new Dropdown({
      key: "period",
      label: "Period",
      disabled: true,
      placeholder: "Select...",
      options: [
        new SelectableInput({ text: "AM", value: "AM" }),
        new SelectableInput({ text: "PM", value: "PM" }),
      ],
      validators: [Validators.required],
      errorMessages: {
        required: "Period is required.",
      },
    });

    this.timezone = new Dropdown({
      key: "timezone",
      label: "Set time zone:",
      placeholder: "Select...",
      disabled: true,
      options: [
        new SelectableInput({ text: "Eastern Standard Time", value: "Eastern Standard Time" }),
        new SelectableInput({ text: "Pacific Standard Time", value: "Pacific Standard Time" }),
      ],
      validators: [Validators.required],
      errorMessages: {
        required: "Time Zone is required.",
      },
    });

    this.schedulerEndDate = new Textbox({
      key: "schedulerEndDate",
      label: "End Date:",
      placeholder: "End Date",
      validators: [Validators.required, Validators.pattern(RegExHelper.date)],
      errorMessages: {
        required: "End Date is required.",
        pattern: "Date should be in MM/DD/YYYY format.",
      },
    });

    this.coverLetterTemplate = new Dropdown({
      key: "coverLetterTemplate",
      label: "Cover Letter Template",
      placeholder: "Select Cover Letter Template",
    });

    this.coverLetterProjectListInput = new Dropdown({
      key: "coverLetterProjectListInput",
      label: "COPY SETTINGS FROM A PROJECT",
      placeholder: "Select From List",
    });

    this.memberListProjectListInput = new Dropdown({
      key: "memberListProjectListInput",
      label: "COPY SETTINGS FROM A PROJECT",
      placeholder: "Select From List",
    });

    this.chaseLetterProjectListInput = new Dropdown({
      key: "chaseLetterProjectListInput",
      label: "COPY SETTINGS FROM A PROJECT",
      placeholder: "Select From List",
    });

    this.measureListInput = new Dropdown({
      key: "measureListInput",
      label: "Measure",
      placeholder: "Select From List",
    });
    this.shortDescriptionMeasureListInput = new Dropdown({
      key: "shortDescriptionMeasureListInput",
      label: "Measure",
      placeholder: "Select From List",
    });

    this.getNext = new Switch({
      key: "getNext",
      label: "Enable Retrieval Get Next",
      preceedingOffLabel: "Inactive",
      followingOnLabel: "Active",
      tooltip: "Our Retrieval assignment automation looks for the following user criteria: Oldest chase loaded, project access, chase is unassigned with no active pends, commitment date has been met or If Field Tech, chart appt date has been met, and we have not contacted location within last 24 hours.",
    });

    this.clinicalGetNext = new Switch({
      key: "clinicalGetNext",
      label: "Enable Clinical Get Next",
      preceedingOffLabel: "Inactive",
      followingOnLabel: "Active",
    });

    this.chasePrioritySettingInput = new Dropdown({
      key: "chasePrioritySettingInput",
      label: "Select Priority",
      placeholder: "Select Priority",
      options: [
        new SelectableInput({ text: "First Chart Available", value: "First Chart Available" }),
        new SelectableInput({ text: "All HCCs (Admin + EVE Suggested)", value: "HCC Value Combined" }),
        new SelectableInput({ text: "Admin HCCs", value: "HCC Value Admin" }),
        new SelectableInput({ text: "EVE Suggested HCCs", value: "HCC Value Coder" }),
      ],
      hasTooltipLabel: true,
    });

    this.vendorInvoiceCODValue = new Textbox({
      key: "vendorInvoiceCODValue",
      label: "COD Threshold:",
      placeholder: "COD Threshold",
      validators: [Validators.pattern(RegExHelper.invoiceAmount)],
      errorMessages: {
        pattern: "Value must be in this pattern: 0.00",
      },
    });

    this.firstPassBotEnabled = new Switch({
      key: "firstPassBotEnabled",
      label: "EVE for Abstraction",
      disabled: !this.hedisFirstPassBotEnabled,
      preceedingOffLabel: "Disable",
      followingOnLabel: "Enable",
      tooltip: "EVE is your virtual team member. EVE (Evidence Validation Engine) is an AI-infused tool that assists in closing care gaps.",
    });

    this.firstPassCodingBotEnabled = new Switch({
      key: "firstPassCodingBotEnabled",
      label: "EVE for First Pass Coding",
      preceedingOffLabel: "Disable",
      followingOnLabel: "Enable",
      tooltip: "EVE is your virtual team member. EVE (Evidence Validation Engine) is an AI-infused tool that validates your clinical data and uncovers conditions, matching them to ICDs/HCCs.",
    });

    this.totalChartsBotDataEnteredValue = new Textbox({
      key: "totalChartsBotDataEnteredValue",
      label: "Total Charts EVE has Abstracted",
      placeholder: "Total Charts EVE has Abstracted",
      readonly: true,
      value: this.chartBotFirstPassCount,
    });

    this.coverLetterRequired = new Radiobutton({
      key: "coverLetterRequired",
      label: "WOULD YOU LIKE TO INCLUDE A COVER LETTER FOR THE PROVIDER PACKET?",
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
    });

    this.authLetterRequired = new Radiobutton({
      key: "authLetterRequired",
      label: "WOULD YOU LIKE TO INCLUDE AN AUTHORIZATION LETTER?",
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
    });

    this.dosPullListRequired = new Radiobutton({
      key: "dosPullListRequired",
      label: "WOULD YOU LIKE TO INCLUDE A DATE OF SERVICE PULL LIST?",
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
    });

    this.memberListRequired = new Radiobutton({
      key: "memberListRequired",
      label: "WOULD YOU LIKE TO INCLUDE MEMBER DETAILS?",
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
    });

    this.chaseLetterRequired = new Radiobutton({
      key: "chaseLetterRequired",
      label: "WOULD YOU LIKE TO INCLUDE A MEASURE INSTRUCTION GUIDE?",
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
    });

    this.providerPackageCheckIncludeDocumentRequestForGap = new Radiobutton({
      key: "providerPackageCheckIncludeDocumentRequestForGap",
      label: "WOULD YOU LIKE TO INCLUDE THE DOCUMENT REQUEST GUIDE FOR MEMBER GAPS?",
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
      tooltip: "This helpful one-page guide will be included in your Provider Packet. It lets Providers know exactly what to send for each Open Gap.",
    });

    this.addressInsertRequired = new Radiobutton({
      key: "addressInsertRequired",
      label: "WOULD YOU LIKE TO INCLUDE AN ADDRESS INSERT WHEN MAILING THE PROVIDER PACKET?",
      options: [
        new SelectableInput({ text: "Yes", value: "1" }),
        new SelectableInput({ text: "No", value: "0" }),
      ],
    });

    this.codingSuppressDxForConfirmedHcc = new Switch({
      key: "codingSuppressDxForConfirmedHcc",
      label: "",
      preceedingOffLabel: "Inactive",
      followingOnLabel: "Active",
      useTitleCase: false,
    });

    this.maxNumOfChasesPerPage = new Textbox({
      key: "maxNumOfChasesPerPage",
      label: "Enter maximum number of pages allowed for chases",
      placeholder: "Enter maximum number of pages",
      tooltip: "This setting can be used to limit the number of pages that can be attached to a chase. If the number of pages exceeds the limit set here, the chase will automatically move to document QA. If there is no limit entered, all chases will be able to move to data entry regardless of page count.",
      validators: [Validators.pattern(RegExHelper.number)],
      errorMessages: {
        pattern: "Value must be number only",
      },
    });

    this.reveleerProjectContacts = new Textbox({
      key: "reveleerProjectContacts",
      label: "Reveleer Project Contact(s)",
      placeholder: "Reveleer Project Contact(s)",
      tooltip: "Please enter the email of the Reveleer contacts for this project (comma separated if multiple contacts).",
      validators: [Validators.pattern(RegExHelper.emailWithCsvPattern)],
      errorMessages: {
        pattern: "Invalid Email Format",
      },
    });
    this.hiosId = new Textbox({
      key: "hiosId",
      label: "HIOS ID:",
      validators: [Validators.pattern(RegExHelper.number)],
      errorMessages: {
        pattern: "Value must be number only",
      },
    });
    this.shortDescriptionMeasureTextBox = new Textbox({
      key: "shortDescriptionMeasureTextBox",
      label: "Measure Short Description",
      placeholder: "Measure Short Description",
      readonly: false,
      value: this.shortDescriptionMeasureText,
      validators: [Validators.maxLength(100)],
      errorMessages: {
        maxlength: "Measure Short Description can not be more than 100 characters.",
      },
    });


    this.abstractionFormGroup = this.formService.createFormGroup([this.allSelectionCheckbox]);


    this.providerPacketSettingsFormGroup = this.formService.createFormGroup(
      [
        this.coverLetterRequired,
        this.authLetterRequired,
        this.dosPullListRequired,
        this.memberListRequired,
        this.chaseLetterRequired,
        this.addressInsertRequired,
        this.providerPackageCheckIncludeDocumentRequestForGap,
      ]
    );

    this.projectSettingsFormGroup = this.formService.createFormGroup(
      [
        this.startDate,
        this.endDate,
        this.clinicalGuidelinesSignOffDate,
        this.documentRequestLinesApprovalDate,
        this.providerLetterReceivedDate,
        this.targetCoderQaScore,
        this.documentRequestRequiresApproval,
        this.pagesRequiresApproval,
        this.allowDocumentForInProcessChase,
        this.enableToAttachAdditionalDocInIntake,
        this.dualRoleChaseAssignmentEnabled,
        this.gapProviderOutreachEnabled,
        this.overreadFeedbackEnabled,
        this.dataEntryProviderConfirmationEnabled,
        this.firstPassCodingBotEnabled,
        this.autoPendEmptyNlpResult,
        this.memberCentricChaseRetrieval,
        this.mdPortalRetrievalEnabled,
        this.projectConfigurationName,
        this.projectConfigurationStatus,
        this.projectOwner,
        this.getNext,
        this.clinicalGetNext,
        this.chasePrioritySettingInput,
        this.vendorInvoiceCODValue,
        this.firstPassBotEnabled,
        this.totalChartsBotDataEnteredValue,
        this.maxNumOfChasesPerPage,
        this.reveleerProjectContacts,
        this.hedisGetNextPriority,
        this.hedisGetNextPriorityPlaceholder,
        this.vendorMatchingRequired,
      ]
    );

    this.measureYearFormGroup = this.formService.createFormGroup(
      [this.measureYear, this.autocloseMemberCompliantChase, this.autocloseMemberCompliantChaseForRecentEventMeasure, this.nrcRequired, this.demographicRequired, this.reportingYear]
    );

    this.reportingFormGroup = this.formService.createFormGroup(
      [
        this.expectedChaseCount,
        this.expectedSampleFeedReceiptDate,
        this.targetLoadDate,
        this.initialSampleFeedReceiptDate,
        this.finalSampleFeedReceiptDate,
        this.isReveleerProvidingServices,
        this.reveleerServiceProvided,
        this.serviceImplementationStartDate,
        this.retrievalServiceStartDate,
        this.retrievalServiceEndDate,
        this.clinicalServiceStartDate,
        this.clinicalServiceEndDate,
        this.researchType,
        this.dataEntryLocation,
      ]
    );

    this.riskFormGroup = this.formService.createFormGroup(
      [
        this.reviewPeriodFromDate,
        this.reviewPeriodThruDate,
        this.codingLevel,
        this.codingReviewModeSelection,
        this.codingOptimizationMode,
        this.documentReviewLevel,
        this.hcc,
        this.hcc2,
        this.rxHcc,
        this.reviewAdminEncountersAndDiagnoses,
        this.serviceProviderRequired,
        this.validationReasonCodeRequired,
        this.encounterTypeRequired,
        this.hccCardAdditionEnabled,
        this.codingSuppressDxForConfirmedHcc,
        this.hccDynamicSuppression,
        this.hiosId,
        this.isEveOnlyCardsValidationRequired,
        this.noNetNewOpportunityAutoPend,
      ]
    );

    this.dataTransferSchedulerFormGroup = this.formService.createFormGroup(
      [
        this.dataTransferEnableSchedule,
        this.number,
        this.hour,
        this.period,
        this.timezone,
        this.schedulerEndDate,
      ]
    );

    this.dataTransferFtpFormGroup = this.formService.createFormGroup(
      [
        this.dataExtractType,
        this.dataExtractDelimiter,
        this.dataExtractIncludeHeader,
      ]
    );

    this.providerPacketFromGroup = this.formService.createFormGroup(
      [
        this.coverLetterTemplate,
        this.coverLetterProjectListInput,
        this.memberListProjectListInput,
        this.chaseLetterProjectListInput,
        this.measureListInput,
        this.shortDescriptionMeasureListInput,
        this.shortDescriptionMeasureTextBox,
      ]
    );
    this.Week = new Dropdown({
      key: "Week",
      label: "",
      placeholder: "Select Weeks",
      options: this.listOfWeeks,
    });
    this.weekInputFormGroup = this.formService.createFormGroup([this.Week]);

    this.projectConfigForm = this.fb.group({
      slaSettingsFormGroup: this.fb.group({
        contacted: this.fb.array([]),
        scheduled: this.fb.array([]),
        abstracted: this.fb.array([]),
        retrieved: this.fb.array([]),
      }),
      Overread: this.fb.array([]),
      Overread2: this.fb.array([]),
      MRQA: this.fb.array([]),
      CoverLetterHeaders: this.fb.array([]),
      CoverLetterBody: this.fb.array([]),
      MemberListHeaders: this.fb.array([]),
      ChaseLetterTemplate: this.fb.array([]),
      ChaseLetterBody: this.fb.array([]),
      RequiredDocsPerMeasure: this.fb.array([]),
    });
    this.projectConfigForm.addControl("projectSettingsFormGroup", this.projectSettingsFormGroup);
    this.projectConfigForm.addControl("measureYearFormGroup", this.measureYearFormGroup);
    this.projectConfigForm.addControl("reportingFormGroup", this.reportingFormGroup);
    this.projectConfigForm.addControl("riskFormGroup", this.riskFormGroup);
    this.projectConfigForm.addControl("providerPacketFromGroup", this.providerPacketFromGroup);
    this.projectConfigForm.addControl("dataTransferFtpFormGroup", this.dataTransferFtpFormGroup);
    this.projectConfigForm.addControl("dataTransferSchedulerFormGroup", this.dataTransferSchedulerFormGroup);
    this.projectConfigForm.addControl("providerPacketSettingsFormGroup", this.providerPacketSettingsFormGroup);
    this.projectConfigForm.addControl("weekInputFormGroup", this.weekInputFormGroup);
    this.projectService.getProjectStatusAndChaseCount(this.projectId)
      .subscribe(result => {
          this.projectStatusFilter = result.projectStatus;
          this.disableFirstPassCodingBot(result.chaseCount);
          this.disableHedisFirstPassCodingBot(result.chaseCount);
          this.retrievalService
          .getProjectList(this.projectId, null, this.projectStatusFilter)
          .subscribe(project => {
            this.projectName = project[0].name;
            this.projectStatus = project[0].projectStatus;
            this.projectTypeId = project[0].projectTypeId;
            this.projectConfigForm.patchValue({
              projectSettingsFormGroup: {
                projectConfigurationName: this.projectName,
                projectConfigurationStatus: Number(this.projectStatus),
              },
            });
            this.updateEnableClinicalGetNextToolTip();
            this.updateSelectPriorityToolTip();
            this.updateAutoPendEmptyNlpResultToolTip();
            if (this.isRiskProject || this.isIvaProject) {
              this.getRiskFirstPassAttribute();
            }
            this.changeDetector.markForCheck();
            this.getProjectList();
            if (this.isDataExtractVisible) {
              this.getDataExtractTypes();
            }

            this.service
              .getProjectAttributes(this.projectId)
              .pipe(
                map(items =>
                  items.reduce((config, item) => {
                    config[item.attributeCode] = item;
                    return config;
                  },           {}))
              )
              .subscribe(configuration => {

                this.assignAndNotify(configuration);
              });

          });
      });

    this.getTemplateList();
    this.getOwners();
    this.gethcc();
    this.getRxHcc();
    // Observables
    this.onProjectListChanges();
    this.onMeasureCodeChange();
    this.onProviderPacketChanges();
    this.loadInvoiceSettings();
    this.initializeInvoiceGrid();

    if (this.isHedisProject && this.hasFirstPassMeasureData && this.firstPassBotEnabled) {
      this.initializeMeasureFirstPassConfiguration();
    }
    this.getProjectListForDocumentReq(this.isDocumentHasTimeFrame);
  }

  get isPriorityMeasureSelected(): boolean {
    return this.projectSettingsFormGroup?.get("hedisGetNextPriority")?.value === "1";
  }

  get measureColumnStyle(): string {
    let numberOfColumns = 1;
    if (ArrayHelper.isAvailable(this.hedisMeasureInputs)) {
      numberOfColumns = Math.ceil(this.hedisMeasureInputs.length / this.CHASE_PRIORITY_ITEMS_PER_COLUMN);
    }
    return `repeat(auto-fit, minmax(min(100%/${numberOfColumns}, max(64px, 100%/${numberOfColumns})), 1fr))`;
  }

  get maxWidthStyle(): string {
    let maxWidth = 100;
    if (ArrayHelper.isAvailable(this.hedisMeasureInputs)) {
      const widthPerColumn = 200;
      maxWidth = Math.ceil(this.hedisMeasureInputs.length / this.CHASE_PRIORITY_ITEMS_PER_COLUMN) * widthPerColumn;
    }
    return `${maxWidth}px`;
  }

  get projectHasMeasures(): boolean {
    return ArrayHelper.isAvailable(this.hedisMeasureInputs);
  }

  canDeactivate(): boolean | Observable<boolean> {
    if (!this.projectConfigForm.pristine
      || !this.firstPassBotFormPristine
      || !this.isChaseSubmissionItemPristine()
      || !this.abstractionFormGroup.untouched) {
      this.showConfirmChangeNavigation = true;
      this.changeDetector.markForCheck();
      return this.navigateAwayProjectConfig;
    } else {
      return true;
    }

  }

  private disableFirstPassCodingBot(count: number): void {
    if (count > 0) {
      this.projectSettingsFormGroup.get(this.firstPassCodingBotEnabled.key)?.disable();
    }
  }

  private disableHedisFirstPassCodingBot(count: number): void {
    if (count > 0) {
      this.projectSettingsFormGroup.get(this.firstPassBotEnabled.key)?.disable();
    }
  }

  continueNavigation(): void {
    this.showConfirmChangeNavigation = false;
    this.navigateAwayProjectConfig.next(true);
  }

  cancelNavigation(): void {
    this.navigateAwayProjectConfig.next(false);
  }

  saveAndContinueNavigation(): void {
    this.showConfirmChangeNavigation = false;
    this.saveProjectSettings("button");
    // This is to delay the navigation while project config is finished updating.
    setTimeout(() => {
      this.navigateAwayProjectConfig.next(true);
    },         500);
  }

  get firstPassBotFormPristine(): boolean {
    if (this.hasFirstPassBotEnabled) {
      return (this.formGroupAbstraction.pristine);
    } else {
      return true;
    }
  }


  getDataExtractTypes() {
    this.service.getDataExtractTypes(this.projectId).subscribe(result => {
      this.dataExtractType = new Dropdown({ ...this.dataExtractType, options: result } as any);
      this.changeDetector.markForCheck();
    });
  }

  projectEndDateChange() {
    const dateGreaterThan = `Project end date can not be less than Project start date`;
    const dateValidators = [Validators.required,
                            dateGreaterThanValidator(this.projectSettingsFormGroup?.value?.startDate)];

    const projectEndDateControl = this.projectSettingsFormGroup.get(this.endDate.getMasterKey());
    projectEndDateControl.clearValidators();
    projectEndDateControl.setValidators(dateValidators);
    projectEndDateControl.setErrors({
      ...this.endDate.errorMessages,
      dateGreaterThan,
    });
    this.endDate.errorMessages = {
      ...this.endDate.errorMessages,
      dateGreaterThan,
    };
    projectEndDateControl.updateValueAndValidity();
  }

  get iconName(): string {
    return "download";
  }

  get projectText() {
    return `Project ${this.projectName} Configuration`;
  }

  get isHedisProject() {
    return this.projectTypeId === ProjectType.HEDIS;
  }

  get isRiskProject() {
    return this.projectTypeId === ProjectType.RISK;
  }

  get isIvaProject() {
    return this.projectTypeId === ProjectType.IVA;
  }

  get shouldDisplayChartChangeActionGrid(): boolean {
    return !this.isRiskProject && !this.isIvaProject;
  }

  get shouldDisplayDocumentForInProcessChase(): boolean {
    return this.isRiskProject || this.isIvaProject;
  }

  get isChartLakeProject() {
    return this.projectTypeId === ProjectType.CHART_LAKE;
  }

  get isMemberManagmentProject(): boolean {
    return this.projectTypeId === ProjectType.MEMBER_MANAGEMENT;
  }

  get isRetrievalTypeGap() {
    return this.projectAttributes && this.projectAttributes.MemberCentricChaseRetrieval.attributeValue === "2";
  }

  get riskIvaHeader() {
    return this.isRiskProject ? "RISK Settings" : "IVA Settings";
  }

  get isDataExtractVisible(): boolean {
    return this.isHedisProject || this.isRiskProject;
  }

  get coverLetterProjectSelected(): boolean {
    return this.isCoverLetterProjectSelected;
  }

  get memberListProjectSelected(): boolean {
    return this.isMemberListProjectSelected;
  }

  get chaseLetterProjectSelected(): boolean {
    return this.isChaseLetterProjectSelected;
  }

  get enableProviderPacket(): boolean {
    return StringHelper.isAvailable(this.selectedMeasureCode);
  }

  get coverLetterTemplateId() {
    return NumberHelper.isAvailable(this.projectConfigForm.get("providerPacketFromGroup.coverLetterTemplate").value) ?
      this.projectConfigForm.get("providerPacketFromGroup.coverLetterTemplate").value : 0;
  }

  get isProviderTabSelected() {
    return this.selectedTab === "packet";
  }

  get isLOBMedicaid(): boolean {
    return (this.projectAttributes && this.projectAttributes.HccModelID.attributeValue) === "23";
  }


  get getEnableClinicalGetNextToolTip(): string {
    return "This is our automated clinical assignment functionality. When enabled, users can request new work assignments without manager intervention. Managers can still perform manual assignments, if needed.";
  }

  get getSelectPriorityToolTip(): string {
    return (this.isRiskProject || this.isIvaProject) ?
      "Now that you've enabled Get Next, you can select the chase priority based on 4 different criteria: First Chart Available, All HCCs (Admin + EVE Suggested), Admin HCCs, or EVE suggested HCCs." :
      "Now that you've enabled Get Next, you can select the chase priority based on different measures.";
  }

  get getAutoPendEmptyNlpResultTooltip(): string {

    return (this.isRiskProject || this.isIvaProject) ?
      "All chases processed through EVE with 0 Hits will get automatically pended, skip Data entry, and move to Delivery Status with PC-1020. Default setting is disabled/NO. To view and code all chases regardless of EVE hits select Enable/YES." :
      "All chases processed through EVE with 0 Hits will get automatically pended, current status with PC-310. Default setting is disabled/NO. To view and code all chases regardless of EVE hits select Enable/YES.";
  }


  get hedisFirstPassBotEnabled(): boolean {
    return this.user.enableHedisFirstPassBot;
  }

  get riskFirstPassBotEnabled(): boolean {
    return this.user.enableRiskFirstPassBot;
  }

  get isGridEnabled(): boolean {
    return ((this.hedisFirstPassBotEnabled && this.isHedisProject && this.isFirstPassBotEnabled) || (this.riskFirstPassBotEnabled && this.isRiskProject && this.isFirstPassCodingBotEnabled));
  }

  get isServiceClinical(): boolean {
    return this.projectConfigForm.get("reportingFormGroup.reveleerServiceProvided")?.value === "Clinical";
  }

  get isServiceRetrieval(): boolean {
    return this.projectConfigForm.get("reportingFormGroup.reveleerServiceProvided")?.value === "Retrieval";
  }

  get isReveleerServiceProvided(): boolean {
    return this.projectConfigForm.get("reportingFormGroup.isReveleerProvidingServices")?.value;
  }


  get isReveleerServiceEnabled(): boolean {
      return (
        this.projectConfigForm.get("reportingFormGroup.reveleerServiceProvided")?.value &&
        this.projectConfigForm.get("reportingFormGroup.isReveleerProvidingServices")?.value
      );
  }


  assignAndNotify = (data): void => {
    this.projectAttributes = data;
    this.progressMilestones = JSON.parse(this.projectAttributes.ProgressMilestone.attributeValue);
    if (this.projectAttributes.QaRate) {
      this.qaRates = JSON.parse(this.projectAttributes.QaRate.attributeValue);
    }
    this.codingReviewMode = this.projectAttributes.CodingReviewMode?.attributeValue;
    this.hccCardAdditionEnabled.hidden = this.codingReviewMode !== CodingReviewMode.DIAGNOSIS && this.codingReviewMode !== CodingReviewMode.DIAGNOSIS_V2_2;
    this.isEveOnlyCardsValidationRequired.hidden = this.codingReviewMode !== CodingReviewMode.DIAGNOSIS_V2_2;
    this.coverLetterDetails = JSON.parse(this.projectAttributes.ProviderPacketCoverLetterDetails.attributeValue);
    this.memberListDetails = JSON.parse(this.projectAttributes.ProviderPacketMemberListDetails.attributeValue);
    this.chaseLetterDetails = JSON.parse(this.projectAttributes.ProviderPacketChaseLetterDetails.attributeValue);
    this.chartChangeActions = JSON.parse(this.projectAttributes.ChartChangeAction.attributeValue)?.chartChangeAttributes;
    this.chasePrioritySetting = JSON.parse(this.projectAttributes.ChasePrioritySetting.attributeValue);
    if (this.projectAttributes.DocumentRequirement) {
      this.documentRequirementsDetails = JSON.parse(this.projectAttributes.DocumentRequirement.attributeValue);
      this.hasDocumentRequestTimeFrame();
    }
    if (this.projectAttributes.DataTransferScheduleSettings) {
      this.schedulerSettingsDetails = JSON.parse(this.projectAttributes.DataTransferScheduleSettings.attributeValue);
    }
    if (this.projectAttributes.ChaseSubmissionItem) {
      this.chaseSubmissionItem = this.convertWorkflowStatusArray((JSON.parse(this.projectAttributes.ChaseSubmissionItem.attributeValue)).chaseSubmissionItem);
    }
    if (this.projectAttributes.BotSetting) {
      this.botSetting = JSON.parse(this.projectAttributes.BotSetting.attributeValue);
    }
    if (this.projectAttributes.FirstPassBotSetting) {
      this.firstPassBotSetting = JSON.parse(this.projectAttributes.FirstPassBotSetting.attributeValue);
    }
    this.loadForm();
  }

  loadForm() {
    this.loadGeneralSettings();
    this.loadChasePrioritySetting();
    this.loadQARatesSettings();
    this.loadMileStonesSettings();
    this.loadHedisSettings();
    this.loadRiskSettings();
    this.loadProviderPacketSettings();
    this.loadReportingSettings();
    this.loadDataTransferSetting();
    this.loadSchedulerListSettings();
    this.loadBotSettings();
    this.loadFirstPassBotSettings();

    if (this.isIvaProject) {
      this.loadIVAMappingDocumentSettings();
    }
    this.checkIfProjectMemberCentric();

    setTimeout(() => {
      this.enableProjectConfigSave = true;
      this.changeDetector.markForCheck();
    },         500);
  }

  protected schedulerControlEnableDisable() {
    if (this.projectAttributes.DataTransferEnableSchedule.attributeValue === "1") {
      this.checkIfSchedulerControlsIsEnabled();
    } else {
      this.checkIfSchedulerControlsIsDisabled();
    }
  }

  protected dataExtractControlExist() {
    if (this.projectAttributes.DataExtractType.attributeValue === "0") {
      this.dataTransferSchedulerFormGroup.get(this.dataTransferEnableSchedule.key).disable();
    } else {
      this.dataTransferSchedulerFormGroup.get(this.dataTransferEnableSchedule.key).enable();
    }
  }

  loadGeneralSettings() {
    this.showChasePrioritySetting();
    this.projectConfigForm.patchValue({
      projectSettingsFormGroup: {
        projectOwner: Number(this.projectAttributes.OwnerDirectoryUserID.attributeValue),
        clinicalGuidelinesSignOffDate: this.projectAttributes.ClinicalGuidelinesSignOffDate.attributeValue,
        documentRequestLinesApprovalDate: this.projectAttributes.DocumentRequestLinesApprovalDate.attributeValue,
        providerLetterReceivedDate: this.projectAttributes.ProviderLetterReceivedDate.attributeValue,
        allowDocumentForInProcessChase: this.projectAttributes.AllowDocumentForInProcessChase.attributeValue,
        enableToAttachAdditionalDocInIntake: this.projectAttributes.EnableToAttachAdditionalDocInIntake.attributeValue,
        dualRoleChaseAssignmentEnabled: Number(this.projectAttributes.DualRoleChaseAssignmentEnabled.attributeValue),
        gapProviderOutreachEnabled: Number(this.projectAttributes.GapProviderOutreachEnabled.attributeValue),
        overreadFeedbackEnabled: Number(this.projectAttributes.OverreadFeedbackEnabled.attributeValue),
        dataEntryProviderConfirmationEnabled: Number(this.projectAttributes.DataEntryProviderConfirmationEnabled.attributeValue),
        memberCentricChaseRetrieval: this.projectAttributes.MemberCentricChaseRetrieval.attributeValue,
        mdPortalRetrievalEnabled: this.projectAttributes.MDPortalRetrievalEnabled.attributeValue,
        documentRequestRequiresApproval: Number(this.projectAttributes.DocumentRequestRequiresApproval?.attributeValue),
        pagesRequiresApproval: Number(this.projectAttributes.PagesRequiresApproval?.attributeValue),
        getNext: Number(this.projectAttributes.GetNextEnabled.attributeValue),
        clinicalGetNext: Number(this.projectAttributes.ClinicalGetNextEnabled.attributeValue),
        vendorInvoiceCODValue: Number(this.projectAttributes.VendorInvoiceCODValue.attributeValue),
        firstPassCodingBotEnabled: Number(this.projectAttributes.EnableMrrBot.attributeValue),
        autoPendEmptyNlpResult: Number(this.projectAttributes.AutoPendEmptyNlpResultChase?.attributeValue),
        vendorMatchingRequired: Number(this.projectAttributes.VendorMatchingRequired?.attributeValue),
        maxNumOfChasesPerPage: NumberHelper.isGreaterThan(Number(this.projectAttributes.MRQAThresholdPageCount.attributeValue), 0) ?
          Number(this.projectAttributes.MRQAThresholdPageCount.attributeValue) : "",
        reveleerProjectContacts: this.projectAttributes.ReveleerProjectContacts.attributeValue === "NA" ? "" : this.projectAttributes.ReveleerProjectContacts.attributeValue,
      },
    });

    this.getProjectListForDocumentReq(this.isDocumentHasTimeFrame);
  }

  get isFirstPassCodingBotEnabled(): boolean {
    const val = Number(this.projectConfigForm.get("projectSettingsFormGroup.firstPassCodingBotEnabled").value);
    return val === 1;
  }

  get isFirstPassBotEnabled(): boolean {
    const val = Number(this.projectConfigForm.get("projectSettingsFormGroup.firstPassBotEnabled").value);
    return val === 1;
  }


  private loadChasePrioritySetting(): void {
    this.projectConfigForm.patchValue({
      projectSettingsFormGroup: {
        chasePrioritySettingInput: this.getChasePrioritySettingInput(),
      },
    });
    if (this.isHedisProject) {
      const priorityOption = this.chasePrioritySetting?.priorityoption === PriorityOption.MEASURE ? "1" : "0";
      this.projectConfigForm.patchValue({
        projectSettingsFormGroup: {
          hedisGetNextPriority: priorityOption,
        },
      });
      this.loadHedisMeasures();
      this.updateChasePrioritiesOptions();
    }
  }

  private updateChasePrioritiesOptions(): void {
    if (!this.projectHasMeasures) {
      this.hedisGetNextPriority.options.pop();
    } else {
      this.hedisMeasureFormGroup = this.formService.createFormGroup(this.hedisMeasureInputs);
      this.projectConfigForm.addControl("hedisMeasureFormGroup", this.hedisMeasureFormGroup);
    }
  }

  private loadHedisMeasures(): void {
    const measures: IMeasurePriority[] = this.chasePrioritySetting?.measuresettings?.measuresetting || [];
    measures.forEach(measure => {
      const priority = measure?.priority === "1000" ? null : measure?.priority;
      const textbox = new Textbox({
        key: measure?.measureCode,
        label: `${measure?.measureCode} (${measure?.chaseCount})`,
        value: priority,
        placeholder: "0",
        labelPosition: "right",
        validators: [Validators.min(0), Validators.max(measures.length), Validators.pattern(RegExHelper.wholeNumber)],
        errorMessages: {
          min: "Minimum priority is 0",
          max: `Maximum priority is ${measures.length}`,
          pattern: "Only numeric values",
        },
      });
      this.hedisMeasureInputs.push(textbox);
    });
  }

  private getHedisMeasureValues(): IMeasurePriority[] {
    const currentMeasures = this.hedisMeasureFormGroup.value;
    const newMeasures: IMeasurePriority[] = [];
    for (const [measureCode, priorityValue] of Object.entries(currentMeasures)) {
      const priority = priorityValue === (null || "0") ? "1000" : priorityValue as string;
      const measurePriority: IMeasurePriority = { measureCode, priority, chaseCount: 0 };
      newMeasures.push(measurePriority);
    }
    return newMeasures;
  }

  loadHedisSettings() {
    this.projectConfigForm.patchValue({
      measureYearFormGroup: {
        measureYear: this.projectAttributes.MeasureYear.attributeValue,
        autocloseMemberCompliantChase: Number(this.projectAttributes.AutocloseMemberCompliantChase?.attributeValue),
        autocloseMemberCompliantChaseForRecentEventMeasure: Number(this.projectAttributes.AutocloseMemberCompliantChaseForRecentEventMeasure?.attributeValue),
        nrcRequired: Number(this.projectAttributes.NegativeReasonCodeRequired.attributeValue),
        demographicRequired: Number(this.projectAttributes.DemographicRequired.attributeValue),
        reportingYear: this.projectAttributes.ReportingYear.attributeValue,
      },
    });

    this.populateTimeFrame(this.topYearFrame());
  }

  private topYearFrame(): string {
    return this.isHedisProject ? this.projectAttributes.MeasureYear.attributeValue
    : DateHelper.isAvailable(this.projectAttributes.ReviewPeriodThruDate.attributeValue)
    ? new Date(this.projectAttributes.ReviewPeriodThruDate.attributeValue).getFullYear().toString()
    : new Date().getFullYear().toString();
  }

  loadRiskSettings() {
    this.getCodingLevel();
    this.getCodingReviewModeOptions();
    this.getDocumentReviewLevel();
    const hccs = this.projectAttributes?.HccModelID?.attributeValue?.split(",");
    this.projectConfigForm.patchValue({
      riskFormGroup: {
        reviewPeriodFromDate: this.projectAttributes.ReviewPeriodFromDate.attributeValue,
        reviewPeriodThruDate: this.projectAttributes.ReviewPeriodThruDate.attributeValue,
        codingLevel: this.projectAttributes.CodingLevel.attributeValue,
        codingReviewModeSelection: this.projectAttributes.CodingReviewMode?.attributeValue,
        codingOptimizationMode: this.projectAttributes.CodingOptimizationMode?.attributeValue,
        documentReviewLevel: this.projectAttributes.DocumentReviewLevel.attributeValue,
        hcc: Number(hccs[0]),
        hcc2: hccs[1] != undefined ? Number(hccs[1]) : null,
        rxHcc: Number(this.projectAttributes?.RxHccModelID?.attributeValue),
        reviewAdminEncountersAndDiagnoses: Number(this.projectAttributes?.ReviewAllAdminClaimsRequired?.attributeValue),
        serviceProviderRequired: Number(this.projectAttributes.ServiceProviderRequired.attributeValue),
        validationReasonCodeRequired: Number(this.projectAttributes.ValidationReasonCodeRequired.attributeValue),
        encounterTypeRequired: Number(this.projectAttributes.EncounterTypeRequired.attributeValue),
        hccCardAdditionEnabled: Number(this.projectAttributes.HccCardAdditionEnabled?.attributeValue),
        isEveOnlyCardsValidationRequired: Number(this.projectAttributes.IsEveOnlyCardsValidationRequired?.attributeValue ?? 0),
        hiosId: this.HIOSValue,
      },
    });
    const dynamicSuppressionValue = Number(this.projectAttributes.CodingSuppressDxForConfirmedHcc?.attributeValue);
    if (this.isCodingReviewModeDxV3 && NumberHelper.isGreaterThan(dynamicSuppressionValue, 0)) {
      this.projectConfigForm.patchValue({
        riskFormGroup: {
          codingSuppressDxForConfirmedHcc: 1,
          hccDynamicSuppression: dynamicSuppressionValue,
        },
      });
    } else {
      const form = "riskFormGroup.hccDynamicSuppression";
      this.projectConfigForm.get(form).disable();
      this.projectConfigForm.patchValue({
        riskFormGroup: {
          codingSuppressDxForConfirmedHcc: 0,
        },
      });
    }

    if (this.codingReviewMode === CodingReviewMode.DIAGNOSIS && this.projectAttributes?.CodingLevel?.attributeValue === "2") {
      this.riskFormGroup.get(this.codingLevel.key).disable();
    }
    if ((this.isHedisProject) || (this.isIvaProject) || (this.isRiskProject && this.codingReviewMode === CodingReviewMode.ENCOUNTER)) {
      this.hideAutoPendEmptyNlpResult();
    }

    if (this.isCodingReviewModeDxV3) {
      this.riskFormGroup.patchValue({
        noNetNewOpportunityAutoPend: Number(this.projectAttributes.NoNetNewOpportunityAutoPend?.attributeValue ?? 0),
      });
    }

    this.setCodingLevelValue();
    this.riskFormGroup.get(this.codingReviewModeSelection.key)?.disable();
    this.isHcc2Selected = hccs[1] != undefined;
  }

  getCodingReviewModeOptions(): void {
    this.service
      .getCodingReviewModeOptionsList(CODING_REVIEW_MODE.attributeId)
      .subscribe(options => {
        this.codingReviewModeSelection = new Dropdown({ ...this.codingReviewModeSelection, options } as any);
        this.codingReviewModeSelection.options.forEach(opt => {
          if (this.CODING_REVIEW_TOOLTIPS[opt.value]) {
            opt.extra.tooltip = this.CODING_REVIEW_TOOLTIPS[opt.value];
          }
        });
      });
    this.changeDetector.markForCheck();
  }

  private setCodingLevelValue(): void {
    if (this.isDiagnosis_V2_2_CodingReviewMode) {
      this.riskFormGroup.get(this.codingLevel.key)?.setValue(CodingLevelDropdown.RADiagnosisCoding);
      this.riskFormGroup.get(this.codingLevel.key)?.disable();
    }
    this.changeDetector.markForCheck();
  }

  getTooltipText(): string {
    const value = this.riskFormGroup.get("codingReviewModeSelection")?.value;
    return value ? this.CODING_REVIEW_TOOLTIPS[value] : "";
  }

  get showRxHccInput(): boolean {
    return NumberHelper.isAvailable(Number(this.projectAttributes?.RxHccModelID?.attributeValue));
  }

  get isCodingReviewModeDxV3(): boolean {
    return this.codingReviewMode === CodingReviewMode.DIAGNOSIS_V3;
  }

  get showCodingOptimizationMode(): boolean {
    const codingLevelValue = this.projectConfigForm.get("riskFormGroup.codingLevel").value;

    if (this.isCodingReviewModeDxV3) {
      return false;
    } else {
      return this.codingReviewMode !== CodingReviewMode.DIAGNOSIS && codingLevelValue === CodingLevelDropdown.RADiagnosisCoding;
    }
  }
  get HIOSValue() {
    return Number(this.projectAttributes.HIOS.attributeValue) === 0 ? "" : this.projectAttributes.HIOS.attributeValue;
  }

  loadMileStonesSettings() {
    this.projectConfigForm.patchValue({
      projectSettingsFormGroup: {
        startDate: this.projectAttributes.StartDate.attributeValue,
        endDate: this.projectAttributes.EndDate.attributeValue,
        targetCoderQaScore: this.projectAttributes.TargetCoderQaScore.attributeValue,
      },
    });

    this.progressMilestones.milestones.forEach(value => {
      let mileStoneFormArray: FormArray;

      switch (value.MilestoneType.toLowerCase()) {
        case "contacted":
          mileStoneFormArray = this.projectConfigForm.get("slaSettingsFormGroup.contacted") as FormArray;
          break;
        case "scheduled":
          mileStoneFormArray = this.projectConfigForm.get("slaSettingsFormGroup.scheduled") as FormArray;
          break;
        case "abstracted":
          mileStoneFormArray = this.projectConfigForm.get("slaSettingsFormGroup.abstracted") as FormArray;
          break;
        case "retrieved":
          mileStoneFormArray = this.projectConfigForm.get("slaSettingsFormGroup.retrieved") as FormArray;
          break;
        default:
          break;
      }

      this.addMileStoneValuesToForm(mileStoneFormArray, value);

    });
    this.changeDetector.markForCheck();
  }

  get isDiagnosis_V2_2_CodingReviewMode(): boolean {
    return this.isRiskProject && this.projectAttributes.CodingReviewMode?.attributeValue === CodingReviewMode.DIAGNOSIS_V2_2;
  }

  addMileStoneValuesToForm(formArray: FormArray, value): void {
    formArray.push(this.getMileStonesControls(value));
  }

  loadQARatesSettings() {
    if (this.qaRates) {
      this.qaRates.qarates.forEach(value => {

        switch (value.QAType) {
          case "overread":
            this.buildListOfQARates(this.projectConfigForm.controls.Overread as FormArray, value);
            if (this.isIvaProject) {
              this.projectConfigForm.controls.Overread.disable();
            }
            break;
          case "overread2":
            this.buildListOfQARates(this.projectConfigForm.controls.Overread2 as FormArray, value);
            if (this.isIvaProject) {
              this.projectConfigForm.controls.Overread2.disable();
            }
            break;
          case "mrqa":
            this.buildListOfQARates(this.projectConfigForm.controls.MRQA as FormArray, value);
            break;
          default:
            break;
        }
      });
      this.changeDetector.markForCheck();
    }
  }

  loadProviderPacketSettings() {
    this.providerPacketSettingsFormGroup.patchValue({
      authLetterRequired: this.projectAttributes.ProviderPacketAuthLetterRequired.attributeValue,
      dosPullListRequired: this.projectAttributes.ProviderPacketDOSPullListRequired.attributeValue,
      chaseLetterRequired: this.projectAttributes.ProviderPacketChaseLetterRequired.attributeValue,
      addressInsertRequired: this.projectAttributes.ProviderPacketAddressInsertRequired.attributeValue,
      coverLetterRequired: this.projectAttributes.ProviderPacketCoverLetterRequired.attributeValue,
      memberListRequired: this.projectAttributes.ProviderPacketMemberListRequired.attributeValue,
      providerPackageCheckIncludeDocumentRequestForGap: Number(this.projectAttributes.ProviderPackageCheckIncludeDocumentRequestForGap.attributeValue),
    });

    this.projectConfigForm.patchValue({
      providerPacketFromGroup: {
        coverLetterTemplate: 0,
      },
    });

    this.loadAuthLetterSettings();
    this.loadMemberListSettings();

    // This is needed because of a bug in Quill editor
    setTimeout(() => {
      this.hideEditor = false;

      if (!this.hideEditor) {
        this.getCoverLetterTemplateList();
        this.loadCoverLetterSettings();
        this.loadChaseSectionSettings();
      }

      this.changeDetector.markForCheck();
    },         100);
  }

  get coverLetterToggle(): boolean {
    return this.providerPacketSettingsFormGroup.get("coverLetterRequired").value === "1";
  }

  get authLetterToggle(): boolean {
    return this.providerPacketSettingsFormGroup.get("authLetterRequired").value === "1";
  }

  get dosPullListToggle(): boolean {
    return this.providerPacketSettingsFormGroup.get("dosPullListRequired").value === "1";
  }

  get memberListToggle(): boolean {
    return this.providerPacketSettingsFormGroup.get("memberListRequired").value === "1";
  }

  get isShortDescriptionChecked(): boolean {
    if (!this.isHedisProject && !this.memberListToggle) {
      return false;
    }
    const memberListHeaders = this.projectConfigForm.get("MemberListHeaders")?.value || [];
    const shortDescription = memberListHeaders.some((header: any) => header.Description === "Short Description:" && header.isrequired);
    return shortDescription;
  }


  get chaseLetterToggle(): boolean {
    return this.providerPacketSettingsFormGroup.get("chaseLetterRequired").value === "1";
  }

  getCoverLetterTemplateList() {
    const options = this.coverLetterDetails.coverletters.map((item, index: number) =>
      new SelectableInput({ text: item.Name, value: index }));

    this.coverLetterTemplate = new Dropdown({ ...this.coverLetterTemplate, options } as any);
    this.changeDetector.markForCheck();
  }

  loadReportingSettings() {
    this.projectConfigForm.patchValue({
      reportingFormGroup: {
        expectedChaseCount: this.projectAttributes.ExpectedChaseCount.attributeValue,
        expectedSampleFeedReceiptDate: this.projectAttributes.ExpectedSampleFeedReceiptDate.attributeValue,
        isReveleerProvidingServices: Number(this.projectAttributes.IsReveleerProvidingServices.attributeValue),
        reveleerServiceProvided: this.projectAttributes.ReveleerServiceProvided.attributeValue,
        serviceImplementationStartDate: this.projectAttributes.ServiceImplementationStartDate.attributeValue,
        retrievalServiceStartDate: this.projectAttributes.RetrievalServiceStartDate.attributeValue,
        retrievalServiceEndDate: this.projectAttributes.RetrievalServiceEndDate.attributeValue,
        clinicalServiceStartDate: this.projectAttributes.ClinicalServiceStartDate.attributeValue,
        clinicalServiceEndDate: this.projectAttributes.ClinicalServiceEndDate.attributeValue,
        dataEntryLocation: this.projectAttributes.DataEntryLocation.attributeValue,
        researchType: this.projectAttributes.ResearchType.attributeValue,
        targetLoadDate: this.projectAttributes.TargetLoadDate.attributeValue,
        initialSampleFeedReceiptDate: this.projectAttributes.InitialSampleFeedReceiptDate.attributeValue,
        finalSampleFeedReceiptDate: this.projectAttributes.FinalSampleFeedReceiptDate.attributeValue,
      },
    });
  }

  loadDataTransferSetting() {
    this.dataExtractControlExist();
    this.projectConfigForm.patchValue({
      dataTransferFtpFormGroup: {
        dataExtractType: Number(this.projectAttributes.DataExtractType.attributeValue),
        dataExtractDelimiter: this.projectAttributes.DataExtractDelimiter.attributeValue,
        dataExtractIncludeHeader: this.projectAttributes.DataExtractIncludeHeader.attributeValue,

      },
    });

  }
  loadSchedulerListSettings() {
    this.schedulerControlEnableDisable();
    const defaultDate = new Date();
    defaultDate.setDate(defaultDate.getDate() + 90);
    const datePipe: DatePipe = new DatePipe("en-US");
    this.projectConfigForm.patchValue({
      dataTransferSchedulerFormGroup: {
        dataTransferEnableSchedule: Number(this.projectAttributes.DataTransferEnableSchedule.attributeValue),
        schedulerEndDate: datePipe.transform(defaultDate, "MM/dd/yyyy"),
      },
    });

    this.loadSchedulerSettings();
  }

  loadCoverLetterSettings() {
    // Clear DOM
    this.setControl("CoverLetterHeaders", new FormArray([]));
    this.setControl("CoverLetterBody", new FormArray([]));

    this.coverLetterDetails.coverletters[this.coverLetterTemplateId].coverletterdetails.forEach(value => {
      switch (value.DetailType.toLowerCase()) {
        case "header":
          this.buildListOfCoverLetter(this.projectConfigForm.controls.CoverLetterHeaders, value);
          break;
        case "body":
          this.buildListOfCoverLetter(this.projectConfigForm.controls.CoverLetterBody, value);
          break;
        default:
          break;
      }
    });
  }
  loadAuthLetterSettings() {
    this.authletterURL = this.projectAttributes.ProviderPacketAuthLetterDetails.attributeValue;
    this.authorizationLetterAttributes = this.projectAttributes.ProviderPacketAuthLetterDetails.authorizationLetterAttributes;
    if (ArrayHelper.isAvailable(this.authorizationLetterAttributes)) {
      this.authorizationLetterAttributes.push({ attributeName: "FilePath", attributeValue: this.authletterURL });
      this.uploadedFileDetail();
    }
  }

  loadIVAMappingDocumentSettings() {
    if (StringHelper.isAvailable(this.projectAttributes.IVAMappingDocument.attributeValue)
      && this.projectAttributes.IVAMappingDocument.attributeValue !== "NA"
      && this.projectAttributes.IVAMappingDocument.attributeValue.includes("MappingDocumentItem")) {
      this.ivaMappingArray = JSON.parse(this.projectAttributes.IVAMappingDocument.attributeValue).MappingDocumentItem;

      if (ArrayHelper.isAvailable(this.ivaMappingArray)) {
        if (this.ivaMappingArray.find(x => x.documentType === this.enrMappingText)) {
          this.enrMappingDocumentAttribute = this.ivaMappingArray.find(x => x.documentType === this.enrMappingText);
          this.enrMappingDocumentURL = this.enrMappingDocumentAttribute.filePath;
        }

        if (this.ivaMappingArray.find(x => x.documentType === this.enrCrosswalkText)) {
          this.enrCrossWalkAttribute = this.ivaMappingArray.find(x => x.documentType === this.enrCrosswalkText);
          this.enrCrossWalkURL = this.enrCrossWalkAttribute.filePath;
        }

        if (this.ivaMappingArray.find(x => x.documentType === this.rxcMappingText)) {
          this.rxcMappingDocumentAttribute = this.ivaMappingArray.find(x => x.documentType === this.rxcMappingText);
          this.rxcMappingDocumentURL = this.rxcMappingDocumentAttribute.filePath;
        }

        if (this.ivaMappingArray.find(x => x.documentType === this.rxcCrosswalkText)) {
          this.rxcCrossWalkAttribute = this.ivaMappingArray.find(x => x.documentType === this.rxcCrosswalkText);
          this.rxcCrossWalkURL = this.rxcCrossWalkAttribute.filePath;
        }

        if (this.ivaMappingArray.find(x => x.documentType === this.ivaMissingMedicalText)) {
          this.ivaMissingMedicalAttribute = this.ivaMappingArray.find(x => x.documentType === this.ivaMissingMedicalText);
          this.ivaMissingMedicalURL = this.ivaMissingMedicalAttribute.filePath;
        }
      }
    }
    const ivaManifestFile = this.projectAttributes?.IVAManifestFile?.attributeValue;
    if (StringHelper.isAvailable(ivaManifestFile) && ivaManifestFile !== "NA" && ivaManifestFile.includes("MappingDocumentItem")) {
      const mappingArray = JSON.parse(ivaManifestFile).MappingDocumentItem as any[];
      const ivaManifestAttribute = mappingArray.find(m => m.documentType === this.ivaManifestText);
      if (ivaManifestAttribute) {
        this.ivaManifestAttribute = ivaManifestAttribute;
        this.ivaManifestUrl = ivaManifestAttribute.filePath;
      }

      const ivaManifestAttribute2 = mappingArray.find(m => m.documentType === this.ivaManifest2Text);
      if (ivaManifestAttribute2) {
        this.ivaManifestAttribute2 = ivaManifestAttribute2;
        this.ivaManifestUrl2 = ivaManifestAttribute2.filePath;
      }

      const ivaManifestAttribute3 = mappingArray.find(m => m.documentType === this.ivaManifest3Text);
      if (ivaManifestAttribute3) {
        this.ivaManifestAttribute3 = ivaManifestAttribute3;
        this.ivaManifestUrl3 = ivaManifestAttribute3.filePath;
      }

    }
    const ivaSubsampleFile = this.projectAttributes?.IVASubsampleFile?.attributeValue;
    if (StringHelper.isAvailable(ivaSubsampleFile) && ivaSubsampleFile !== "NA" && ivaSubsampleFile.includes("MappingDocumentItem")) {
      const mappingArray = JSON.parse(ivaSubsampleFile).MappingDocumentItem as any[];
      const ivaSubsampleAttribute = mappingArray.find(m => m.documentType === this.ivaSubsampleText);
      if (ivaSubsampleAttribute) {
        this.ivaSubsampleAttribute = ivaSubsampleAttribute;
        this.ivaSubsampleUrl = ivaSubsampleAttribute.filePath;
      }
    }
  }

  uploadDeleteIVAFile($event) {
    const documentType = $event.documentType;
    const filePath = $event.filePath;
    const attributes = $event.ivaMappingAttributes;
    if (documentType === this.enrMappingText) {
      this.enrMappingDocumentURL = filePath;
      this.enrMappingDocumentAttribute = attributes;
    }
    if (documentType === this.enrCrosswalkText) {
      this.enrCrossWalkURL = filePath;
      this.enrCrossWalkAttribute = attributes;
    }
    if (documentType === this.rxcMappingText) {
      this.rxcMappingDocumentURL = filePath;
      this.rxcMappingDocumentAttribute = attributes;
    }
    if (documentType === this.rxcCrosswalkText) {
      this.rxcCrossWalkURL = filePath;
      this.rxcCrossWalkAttribute = attributes;
    }
    if (documentType === this.ivaMissingMedicalText) {
      this.ivaMissingMedicalURL = filePath;
      this.ivaMissingMedicalAttribute = attributes;
    }
    if (documentType === this.ivaManifestText) {
      this.ivaManifestUrl = filePath;
      this.ivaManifestAttribute = attributes;
    }
    if (documentType === this.ivaManifest2Text) {
      this.ivaManifestUrl2 = filePath;
      this.ivaManifestAttribute2 = attributes;
    }
    if (documentType === this.ivaManifest3Text) {
      this.ivaManifestUrl3 = filePath;
      this.ivaManifestAttribute3 = attributes;
    }
    if (documentType === this.ivaSubsampleText) {
      this.ivaSubsampleUrl = filePath;
      this.ivaSubsampleAttribute = attributes;
    }

    this.saveProjectSettings();
    this.service.refreshFunction();
  }

  loadMemberListSettings() {
    this.memberListDetails.MemberListAttributes.forEach(value => {
      this.buildListOfMemberList(this.projectConfigForm.controls.MemberListHeaders, value);
    });
  }

  loadSchedulerSettings() {
    if (this.schedulerSettingsDetails.Days !== null) {
      const days = [];
      this.schedulerSettingsDetails.Days.Days.forEach(item => {
        days.push({ value: item.Number });
      });
      this.dataTransferSchedulerFormGroup.patchValue({
        number: days,
        hour: this.schedulerSettingsDetails.RunAt.Hour,
        period: this.schedulerSettingsDetails.RunAt.Period,
        timezone: this.schedulerSettingsDetails.RunAt.TimeZone,
        schedulerEndDate: this.schedulerSettingsDetails.RunAt.SchedulerEndDate,
      });
    }
  }

  loadChaseSectionSettings() {
    this.loadRequiredDocSettings();
    this.loadChaseLetterSettings();
  }

  loadRequiredDocSettings() {
    const options = [];

    if (this.documentRequirementsDetails != null && ArrayHelper.isAvailable(this.documentRequirementsDetails.documentrequirements)) {
      this.listRequiredDocsPerMeasure = this.documentRequirementsDetails.documentrequirements.reduce((type, item) => {
        type[item.MeasureCode] = type[item.MeasureCode] || [];
        type[item.MeasureCode].push(item);
        return type;
      },                                                                                             {});
    }
    if (this.isDocumentHasTimeFrame) {
      for (const key of Object.keys(this.listRequiredDocsPerMeasure)) {
        options.push(new SelectableInput({ text: key, value: key }));
        let listOfDocName = "";
        let timeFrameFrom = "";
        let timeFrameTo = "";
        this.listRequiredDocsPerMeasure[key].forEach(value => {
          listOfDocName = `${value.DocumentName}`;
          if (this.projectTypeId === ProjectType.RISK || this.projectTypeId === ProjectType.IVA) {
            timeFrameFrom = StringHelper.isAvailable(listOfDocName) ? `${value.TimeFrameFromYear}` :
              new Date(this.riskFormGroup.controls.reviewPeriodFromDate.value).getFullYear().toString();
            timeFrameTo = StringHelper.isAvailable(listOfDocName) ? `${value.TimeFrameToYear}` :
              new Date(this.riskFormGroup.controls.reviewPeriodThruDate.value).getFullYear().toString();

            this.buildListOfMeasureCodes(this.projectConfigForm.controls.RequiredDocsPerMeasure as FormArray, key, listOfDocName, timeFrameFrom, timeFrameTo, value.MeasureDescription);
          } else {
            timeFrameFrom = `${value.TimeFrameFromYear}`;
            timeFrameTo = `${value.TimeFrameToYear}`;
            this.buildListOfMeasureCodes(this.projectConfigForm.controls.RequiredDocsPerMeasure as FormArray, key, listOfDocName, timeFrameFrom, timeFrameTo, value.MeasureDescription);
          }

        });

      }
    } else {
      for (const key of Object.keys(this.listRequiredDocsPerMeasure)) {
        options.push(new SelectableInput({ text: key, value: key }));
        let listOfDocName = "";
        const timeFrameFrom = "";
        const timeFrameTo = "";
        let measureDescription = "";
        this.listRequiredDocsPerMeasure[key].forEach(value => {
          listOfDocName += `${value.DocumentName}\n`;
          measureDescription = value.MeasureDescription;
        });
        this.buildListOfMeasureCodes(this.projectConfigForm.controls.RequiredDocsPerMeasure as FormArray, key, listOfDocName, timeFrameFrom, timeFrameTo, measureDescription);
      }
    }
    this.measureListInput = new Dropdown({ ...this.measureListInput, options } as any);
    this.providerPacketFromGroup.get(this.measureListInput.key).setValue(options[0]?.value);
    this.shortDescriptionMeasureListInput = new Dropdown({ ...this.shortDescriptionMeasureListInput, options } as any);
    this.providerPacketFromGroup.get(this.shortDescriptionMeasureListInput.key).setValue(options[0]?.value);
    this.selectedMeasureCode = options[0]?.value;
    this.shortDescriptionSelectedMeasureCode = options[0]?.value;
    if (this.listRequiredDocsPerMeasure?.length > 0) {
      const shortDescriptionDefault = this.listRequiredDocsPerMeasure[options[0]?.value][0]?.MeasureDescription;
      this.providerPacketFromGroup.get(this.shortDescriptionMeasureTextBox.key).setValue(shortDescriptionDefault);
    }
    this.changeDetector.markForCheck();
  }

  loadChaseLetterSettings() {
    this.chaseLetterDetails.ChaseCoverLetterAttributes.forEach(value => {
      switch (value.DetailType.toLowerCase()) {
        case "template":
          this.buildListOfChaseLetter(this.projectConfigForm.controls.ChaseLetterTemplate, value);
          break;
        case "body":
          this.buildListOfChaseLetter(this.projectConfigForm.controls.ChaseLetterBody, value);
          break;
        default:
          break;
      }
    });
  }

  buildEmptyListOfMileStones(milestoneType: string) {
    const formGroup = new FormGroup({
      MilestoneType: new FormControl(milestoneType),
      Week: new FormControl(0),
      Percentage: new FormControl(0, this.percentageValidators),
    });
    return formGroup;
  }

  getMileStonesControls(value) {
    return this.fb.group({
      MilestoneType: new FormControl(value ? value.MilestoneType : ""),
      Week: new FormControl(value ? value.Week : undefined),
      Percentage: new FormControl(value ? value.Percentage : undefined, this.percentageValidators),
    });
  }

  buildListOfQARates(formArray, data) {
    const deficientChases = this.isIvaProject ? "100" : data.Deficientchases;
    const submittablechases = this.isIvaProject ? "100" : data.Submittablechases;

    formArray.push(
      new FormGroup({
        QAType: new FormControl(data.QAType),
        MeasureCode: new FormControl(data.MeasureCode),
        Compliant: new FormControl(data.Compliant, this.percentageValidators),
        PartiallyCompliant: new FormControl(data.PartiallyCompliant || 0, this.percentageValidators),
        NonCompliant: new FormControl(data.NonCompliant, this.percentageValidators),
        NonCompliantExclusion: new FormControl(data.NonCompliantExclusion, this.percentageValidators),
        Fax: new FormControl(data.Fax, this.percentageValidators),
        Mail: new FormControl(data.Mail, this.percentageValidators),
        Emr: new FormControl(data.Emr, this.percentageValidators),
        Fieldtech: new FormControl(data.Fieldtech, this.percentageValidators),
        Provider: new FormControl(data.Provider, this.percentageValidators),
        Approvalcenter: new FormControl(data.Approvalcenter, this.percentageValidators),
        Ftp: new FormControl(data.Ftp, this.percentageValidators),
        Intake: new FormControl(data.Intake, this.percentageValidators),
        Mrrupload: new FormControl(data.Mrrupload, this.percentageValidators),
        Pendupload: new FormControl(data.Pendupload, this.percentageValidators),
        Psrupload: new FormControl(data.Psrupload, this.percentageValidators),
        Smrc: new FormControl(data.Smrc, this.percentageValidators),
        Providerdeficiency: new FormControl(data.Providerdeficiency, this.percentageValidators),
        Nonfacetoface: new FormControl(data.Nonfacetoface, this.percentageValidators),
        Deficientchases: new FormControl(deficientChases || 0, this.percentageValidators),
        Submittablechases: new FormControl(submittablechases || 0, this.percentageValidators),
        OverreadType: new FormControl("1", this.percentageValidators),
        PortalUpload: new FormControl(data.PortalUpload, this.percentageValidators),
        ThirdPartyUpload: new FormControl(data.ThirdPartyUpload, this.percentageValidators),
      })
    );
  }

  buildListOfCoverLetter(formArray, data) {
    formArray.push(
      new FormGroup({
        DetailType: new FormControl(data.DetailType),
        Description: new FormControl(data.Description),
        Value: new FormControl(data.Value),
        isrequired: new FormControl(data.isrequired),
      })
    );
  }

  buildListOfMemberList(formArray, data) {
    formArray.push(
      new FormGroup({
        Description: new FormControl(data.Description),
        Value: new FormControl(data.Value),
        isrequired: new FormControl(data.isrequired),
      })
    );
  }

  buildListOfChaseLetter(formArray, data) {
    formArray.push(
      new FormGroup({
        DetailType: new FormControl(data.DetailType),
        Value: new FormControl(data.Value),
      })
    );
  }

  buildListOfMeasureCodes(formArray, key, requiredDocs, timeFrameFrom, timeFrameTo, measureDescription) {
    const measureYear = this.projectAttributes.MeasureYear.attributeValue;
    formArray.push(
      new FormGroup({
        MeasureCode: new FormControl(key),
        Documents: new FormControl(requiredDocs),
        timeframeMiddleText: new FormControl(timeFrameTo === "Prior" ? "and" : "to"),
        timeFrameFrom: new FormControl(!StringHelper.isAvailable(timeFrameFrom) ? measureYear : timeFrameFrom),
        timeFrameTo: new FormControl(!StringHelper.isAvailable(timeFrameTo) ? measureYear : timeFrameTo),
        measureDescription : new FormControl(measureDescription),
      })
    );
  }
  buildDynamicListOfMeasureCodes(key, requiredDocs, fromTimeFrame, toTimeFrame) {
    let measureYear: string;
    let selectedFromTimeFrame = fromTimeFrame.value;
    let selectedToTimeFrame = toTimeFrame.value;
    if (this.projectTypeId === ProjectType.RISK || this.projectTypeId === ProjectType.IVA) {
      selectedFromTimeFrame = StringHelper.isAvailable(selectedFromTimeFrame) ? selectedFromTimeFrame :
        new Date(this.riskFormGroup.controls.reviewPeriodFromDate.value).getFullYear().toString();
      selectedToTimeFrame = StringHelper.isAvailable(selectedToTimeFrame) ? selectedToTimeFrame :
        new Date(this.riskFormGroup.controls.reviewPeriodThruDate.value).getFullYear().toString();
    } else {
      measureYear = this.projectAttributes.MeasureYear.attributeValue;
    }
    const formGroup = new FormGroup({
      MeasureCode: new FormControl(key),
      Documents: new FormControl(requiredDocs),
      timeframeMiddleText: new FormControl(selectedToTimeFrame === "Prior" ? "and" : "to"),
      timeFrameFrom: new FormControl(selectedFromTimeFrame == null ? measureYear : selectedFromTimeFrame),
      timeFrameTo: new FormControl(selectedToTimeFrame == null ? measureYear : selectedToTimeFrame),
    });
    return formGroup;
  }
  generatePreviewPacket() {
    this.service
      .getProjectAttribute(this.projectId, this.projectAttributes.DocumentRequirement.attributeId)
      .subscribe(configuration => {
        this.projectAttributes.DocumentRequirement = configuration;
        this.getFormAttributes();
        const ProviderPacketModel = this.buildProviderPacketModel();
        this.service
          .generatePreviewPacket(ProviderPacketModel)
          .subscribe(response => {
            if (StringHelper.isAvailable(response)) {
              this.downloaderService.fromRawUrl(response);
            } else {
              this.messagingService.showToast("No provider packet available for preview.", SeverityType.ERROR);
            }
            this.changeDetector.markForCheck();
          },
                     (err: HttpErrorResponse) => {
              if (err.error instanceof Error) {
                this.messagingService.showToast("A client side error occurred while saving.", SeverityType.ERROR);
              } else {
                this.messagingService.showToast("A back end error occurred while saving project configurations.", SeverityType.ERROR);
              }
              this.changeDetector.markForCheck();
            }
          );
      });
  }

  buildProjectConfigurationSummaryModel() {
    const model = new ProjectConfigSummary({
      projectId: this.projectId,
      projectConfigurationAttributes: Object.keys(this.projectAttributes)
        .filter(key => key !== "DocumentRequirement")
        .map(
          item => this.projectAttributes[item]
        ),
    });

    return model;
  }

  buildProviderPacketModel() {
    const model = new ProviderPacket({
      projectId: this.projectId,
      projectTypeId: this.projectTypeId,
      measureCode: this.selectedMeasureCode,
      template: this.coverLetterDetails.coverletters[this.coverLetterTemplateId].Name,
      projectConfigurationAttributes: Object.keys(this.projectAttributes)
        .map(
          item => this.projectAttributes[item]
        ),
      measureDocumentsList: Object.keys(this.projectConfigForm.get("RequiredDocsPerMeasure").value)
        .map(
          item => this.projectConfigForm.controls.RequiredDocsPerMeasure.value[item]
        ),
      measureYear: this.projectAttributes.MeasureYear.attributeValue,
    });

    return model;
  }

  selectTab(tabName: string): boolean {
    // Hide Tab
    return this.selectedTab !== tabName;
  }

  toggleTab(tab: string) {
    this.selectedTab = this.selectedTab === tab ? "" : tab;
    this.saveProjectSettings("tab");
  }

  trackByIndex(index, item) {
    return index;
  }
  getListOfWeeks() {
    const listOfWeeks = [];
    for (let i = 1; i < 53; i++) {
      listOfWeeks.push(new SelectWeeks(i, `${i} weeks`));
    }
    this.listOfWeeks = listOfWeeks.map(x => ({ text: x.name, value: x.id, label: x.name, extra: [], disabled: false }));
    return this.listOfWeeks;
  }

  getProjectList() {
    this.retrievalService
      .getProjectList()
      .pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
      .subscribe(options => {
        this.coverLetterProjectListInput = new Dropdown({ ...this.coverLetterProjectListInput, options } as any);
        this.memberListProjectListInput = new Dropdown({ ...this.memberListProjectListInput, options } as any);
        this.changeDetector.markForCheck();
      });
  }
  getProjectListForDocumentReq(isDocumentHasTimeFrame: boolean) {
    this.retrievalService
      .getProjectListForDocumentReq(isDocumentHasTimeFrame)
      .pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
      .subscribe(result => {
        const options = result.filter(x => x.value !== this.projectId);
        this.chaseLetterProjectListInput = new Dropdown({ ...this.chaseLetterProjectListInput, options } as any);
        this.changeDetector.markForCheck();
      });
  }
  getTemplateList() {
    this.chaseLetterTemplateList = [
      { value: "english", name: "English" },
      { value: "spanish", name: "Spanish" },
    ];
  }

  getOwners() {
    const UserSearchCriteriaModel = {
      status: "A",
      groupName: "",
      endRecord: 32000,
    };

    this.newProjectService.getOwners(UserSearchCriteriaModel)
      .pipe(
        map(this.automapper.curryMany("UserModel", "SelectableInput"))
      )
      .subscribe((options: any[]): void => {
        this.projectOwner = new Dropdown({ ...this.projectOwner, options } as any);
        this.changeDetector.markForCheck();
      });
  }

  get isProjectTypeMembercentric(): boolean {
    return this.projectAttributes?.MemberCentricChaseRetrieval.attributeValue === "1";
  }

  getClassForPageApproval(): string {
    return this.isProjectTypeMembercentric ? "subSectionHeader" : "subSectionHeader__page-approval";
  }

  gethcc(lineofbusiness?: string) {
    this.newProjectService
      .getHCC(lineofbusiness)
      .subscribe(options => {
        this.hcc = new Dropdown({ ...this.hcc, options } as any);
        this.hcc2 = new Dropdown({ ...this.hcc2, options } as any);
      });

    this.changeDetector.markForCheck();
  }

  private getRxHcc(): void {
    this.newProjectService
      .getHCC(LineOfBusiness.MEDICARE_RX)
      .subscribe(options => {
        this.rxHcc = new Dropdown({ ...this.rxHcc, options } as any);
      });

    this.changeDetector.markForCheck();
  }

  getCodingLevel(): void {
    const options = this.isLOBMedicaid ? [
      new SelectableInput({ text: "Full coding", value: "1" }),
    ]
      : [
        new SelectableInput({ text: "Full coding", value: "1" }),
        new SelectableInput({ text: "RA Diagnosis Coding", value: "2" }),
      ];

    this.codingLevel = new Dropdown({
      ...this.codingLevel,
      options,
    } as any);

    this.changeDetector.markForCheck();
  }

  getDocumentReviewLevel(): void {
    const options = [
      new SelectableInput({ text: "Member Validation", value: "1" }),
      new SelectableInput({ text: "Member and DOS Validation", value: "2" }),
    ];

    this.documentReviewLevel = new Dropdown({
      ...this.documentReviewLevel,
      options,
    } as any);

    this.changeDetector.markForCheck();
  }

  onProjectListChanges(): void {
    this.providerPacketFromGroup.get("coverLetterProjectListInput").valueChanges
      .subscribe(selectedProject => {
        if (selectedProject != null) {
          this.isCoverLetterProjectSelected = true;
        }

        this.selectedCoverLetterProjectID = selectedProject;
      });

    this.providerPacketFromGroup.get("memberListProjectListInput").valueChanges
      .subscribe(selectedProject => {
        if (selectedProject != null) {
          this.isMemberListProjectSelected = true;
        }

        this.selectedMemberListProjectID = selectedProject;
      });

    this.providerPacketFromGroup.get("chaseLetterProjectListInput").valueChanges
      .subscribe(selectedProject => {
        if (selectedProject != null) {
          this.isChaseLetterProjectSelected = true;
        }

        this.selectedChaseLetterProjectID = selectedProject;
      });

  }

  onMeasureCodeChange(): void {
    this.providerPacketFromGroup.get("measureListInput").valueChanges
      .subscribe(selectedMeasureCode => {
        this.selectedMeasureCode = selectedMeasureCode;
      });
    this.providerPacketFromGroup.get("shortDescriptionMeasureListInput").valueChanges
      .subscribe(selectedMeasureCode => {
        this.shortDescriptionSelectedMeasureCode = selectedMeasureCode;
        this.shortDescriptionMeasureText = this.listRequiredDocsPerMeasure?.[selectedMeasureCode]?.[0]?.MeasureDescription ?? "";
        this.providerPacketFromGroup.get("shortDescriptionMeasureTextBox").setValue(this.shortDescriptionMeasureText);
        this.changeDetector.markForCheck();
      });
    this.providerPacketFromGroup.get("shortDescriptionMeasureTextBox").valueChanges
      .subscribe(description => {
         this.listRequiredDocsPerMeasure?.[this.shortDescriptionSelectedMeasureCode]?.forEach(item => {
           item.MeasureDescription = description;
          });
         const formArray = this.projectConfigForm.controls.RequiredDocsPerMeasure as FormArray;
         formArray.controls.forEach(control => {
            if (control.get("MeasureCode")?.value === this.shortDescriptionSelectedMeasureCode) {
               control.get("measureDescription")?.setValue(description);
          }
        });
      });
  }

  onProviderPacketChanges(): void {
    this.projectConfigForm.get("providerPacketFromGroup.coverLetterTemplate").valueChanges
      .subscribe(selectedCoverLetterTemplateIndex => {
        if (NumberHelper.isAvailable(selectedCoverLetterTemplateIndex)) {
          this.loadCoverLetterSettings();
        }
      });
  }

  copyCoverLetterSettings() {
    this.service
      .getProjectAttribute(this.selectedCoverLetterProjectID, this.projectAttributes.ProviderPacketCoverLetterDetails.attributeId)
      .subscribe(result => {
        if (result != null) {
          this.coverLetterDetails = "";
          this.coverLetterDetails = JSON.parse(result.attributeValue);

          // Clear DOM
          this.setControl("CoverLetterHeaders", new FormArray([]));
          this.setControl("CoverLetterBody", new FormArray([]));

          this.loadCoverLetterSettings();
          this.changeDetector.markForCheck();
        }
      });
  }

  copyMemberListSettings() {
    this.service
      .getProjectAttribute(this.selectedMemberListProjectID, this.projectAttributes.ProviderPacketMemberListDetails.attributeId)
      .subscribe(result => {
        if (result != null) {
          this.memberListDetails = "";
          this.memberListDetails = JSON.parse(result.attributeValue);

          // Clear DOM
          this.setControl("MemberListHeaders", new FormArray([]));

          this.loadMemberListSettings();
          this.changeDetector.markForCheck();
        }
      });
  }

  copyChaseLetterSettings() {
    this.service
      .getProjectAttribute(this.selectedChaseLetterProjectID, this.projectAttributes.ProviderPacketChaseLetterDetails.attributeId)
      .subscribe(result => {
        if (result != null) {
          this.chaseLetterDetails = "";
          this.chaseLetterDetails = JSON.parse(result.attributeValue);

          // Clear DOM
          this.setControl("ChaseLetterTemplate", new FormArray([]));
          this.setControl("ChaseLetterBody", new FormArray([]));

          this.loadChaseLetterSettings();
          this.changeDetector.markForCheck();
        }
      });
    this.service
      .getProjectAttribute(this.selectedChaseLetterProjectID, this.projectAttributes.DocumentRequirement.attributeId)
      .subscribe(result => {
        if (result != null) {
          this.documentRequirementsDetails = "";
          this.documentRequirementsDetails = JSON.parse(result.attributeValue);

          // Clear DOM
          this.setControl("RequiredDocsPerMeasure", new FormArray([]));

          this.loadRequiredDocSettings();
          this.changeDetector.markForCheck();
        }
      });
  }

  onFileSelect(event) {
    for (const file of event.files) {
      this.selectedFileList.push(file);
      this.formData.append("Document", file);
    }
  }

  onFileRemove(event) {
    this.formData = new FormData();

    let i = this.selectedFileList.length;
    while (i--) {
      if (this.selectedFileList[i].name === event.file.name) {
        this.selectedFileList.splice(i, 1);
      } else {
        this.formData.append("Document", this.selectedFileList[i]);
      }
    }
  }

  onCodingLevelChange(event: any) {
    const isDiagnosisCodingMode = this.isRiskProject && this.codingReviewMode === CodingReviewMode.DIAGNOSIS;

    if (!isDiagnosisCodingMode) {
      return;
    }

    this.onLoadDisableVRCANDServiceType = event.value === CodingLevelDropdown.RADiagnosisCoding;
    const validationReasonControl = this.riskFormGroup.get(this.validationReasonCodeRequired.key);
    const encounterTypeControl = this.riskFormGroup.get(this.encounterTypeRequired.key);

    if (this.onLoadDisableVRCANDServiceType) {
      validationReasonControl.disable();
      encounterTypeControl.disable();
      this.projectConfigForm.patchValue({
        riskFormGroup: {
          validationReasonCodeRequired: 0,
          encounterTypeRequired: 0,
        },
      });
    } else {
      validationReasonControl.enable();
      encounterTypeControl.enable();
    }
  }

  uploadDocument(event, fileUpload): void {
    if (this.selectedFileList != undefined && this.selectedFileList.length > 0) {

      this.service.uploadAuthLetter(this.formData)
        .subscribe(data => {
          if (ArrayHelper.isAvailable(data)) {
            this.authorizationLetterAttributes = data;
            this.uploadedFileDetail();
            this.authletterURL = this.fileDetail.filePath;
            this.saveProjectSettings();
            this.service.refreshFunction();
            this.messagingService.showToast("Authentication letter uploaded successfully.", SeverityType.SUCCESS);
            this.formData = new FormData();
            this.selectedFileList = [];
            fileUpload.clear();
            this.changeDetector.markForCheck();
          } else {
            this.messagingService.showToast("Error while Document uploading, please try again.", SeverityType.ERROR);
          }
        });
    } else {
      this.messagingService.showToast("Please select a required document.", SeverityType.ERROR);
    }
  }

  saveProjectSettings(saveType: string = "button") {
    if (!this.enableProjectConfigSave) {
      this.messagingService.showMessage("All Project Configurations not loaded yet. Please try later", SeverityType.ERROR);
    }

    if (this.projectConfigForm.invalid) {
      return;
    }

    this.saveType = saveType;
    const validation = this.validateList();
    const isDataTransferScheduleEnable = Number(this.projectConfigForm.get("dataTransferSchedulerFormGroup.dataTransferEnableSchedule").value);
    if (isDataTransferScheduleEnable === 1) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      const maxValidDate = new Date(today);
      maxValidDate.setDate(today.getDate() + 180);
      const schedulerEndDate = this.projectConfigForm.get("dataTransferSchedulerFormGroup.schedulerEndDate").value;
      const schedulerDate = new Date(schedulerEndDate);
      if (schedulerDate > maxValidDate) {
        this.messagingService.showToast("Extract scheduler end date should not be more than 180 days from todays date.", SeverityType.ERROR);
        return;
      }
      if (schedulerDate < today) {
        this.messagingService.showToast("Extract scheduler end date should not be less than todays date.", SeverityType.ERROR);
      }
    }
    if (validation) {
      try {
        this.getFormAttributes();
        const projectConfigurationSummary = this.buildProjectConfigurationSummaryModel();
        this.formData = new FormData();
        if (this.selectedFile.length > 0) {
          this.formData.append("providerPacketLogo", this.selectedFile[0]);
        }
        const measureDocumentsSummary = new MeasureDocumentsSummary({
          projectId: this.projectId,
          isDocumentHasTimeFrame: this.isDocumentHasTimeFrame,
          measureDocumentsList: Object.keys(this.projectConfigForm.get("RequiredDocsPerMeasure").value)
            .map(
              item => this.projectConfigForm.controls.RequiredDocsPerMeasure.value[item]
            ),
          projectTypeId: this.projectTypeId,
        });

        this.service.saveProjectAttributes(projectConfigurationSummary, this.projectConfigName, this.projectStatus, this.formData).subscribe({
          next: () => {
            if (this.isHedisTabSelected && this.hasBotSettings) {
              this.getUpdatedBotSettingsValue();
            }

            if (this.hasFirstPassBotEnabled) {
              this.formGroupAbstraction?.markAsUntouched();
              this.formGroupAbstraction?.markAsPristine();
            }
            this.saveMeasureDocuments(measureDocumentsSummary);
            this.projectConfigForm.markAsUntouched();
            this.projectConfigForm.markAsPristine();

            this.chaseSubmissionItemModified = false;
          },
          error: err => {
            if (err.error instanceof Error) {
              this.messagingService.showToast("A client side error occurred while saving.", SeverityType.ERROR);
            } else {
              this.messagingService.showToast("A back end error occurred while saving project configurations.", SeverityType.ERROR);
            }
          },
        });
        this.changeDetector.markForCheck();

      } catch (e) {
        this.messagingService.showToast("An error occurred while trying to save.", SeverityType.ERROR);
      }
    } else {
      this.messagingService.showMessage("Please complete the data entry checklist.", SeverityType.ERROR);
    }
  }

  private saveMeasureDocuments(measureDocumentsSummary: MeasureDocumentsSummary) {
    this.service.saveMeasureDocuments(measureDocumentsSummary)
      .subscribe({
        next: () => {
          if (this.saveType === "button") {

            const isDataExtractOn = this.projectConfigForm.get("dataTransferSchedulerFormGroup.dataTransferEnableSchedule").value;
            if (isDataExtractOn) {
              const isDaySelected = this.dataTransferSchedulerFormGroup.get(this.number.key).value;
              if (!ArrayHelper.isAvailable(isDaySelected)) {
                this.messagingService.showMessage("Select a day to enable data extract scheduler", SeverityType.ERROR);
                return;
              }
            }

            this.messagingService.showToast("Project config saved.", SeverityType.SUCCESS);
          }
        },
        error: err => {
          if (err.error instanceof Error) {
            this.messagingService.showToast("A client side error occurred while saving.", SeverityType.ERROR);
          } else {
            this.messagingService.showToast("A back end error occurred while saving project configurations.", SeverityType.ERROR);
          }
        },
      });
  }

  getFormAttributes() {
    this.changeDetector.markForCheck();
    const project = this.projectConfigForm.get("projectSettingsFormGroup.projectConfigurationName").value;
    this.projectConfigName = project === this.projectName ? "" : project;

    const projectStatusUpdated = Number(this.projectConfigForm.get("projectSettingsFormGroup.projectConfigurationStatus").value);
    this.projectStatus = projectStatusUpdated === this.projectStatus ? null : projectStatusUpdated;

    this.projectAttributes.StartDate.attributeValue = this.projectConfigForm.get("projectSettingsFormGroup.startDate").value;
    this.projectAttributes.EndDate.attributeValue = this.projectConfigForm.get("projectSettingsFormGroup.endDate").value;
    this.projectAttributes.OwnerDirectoryUserID.attributeValue = this.projectConfigForm.get("projectSettingsFormGroup.projectOwner").value;
    this.projectAttributes.ClinicalGuidelinesSignOffDate.attributeValue = DateHelper.format(this.projectConfigForm.get("projectSettingsFormGroup.clinicalGuidelinesSignOffDate").value);
    this.projectAttributes.DocumentRequestLinesApprovalDate.attributeValue = DateHelper.format(this.projectConfigForm.get("projectSettingsFormGroup.documentRequestLinesApprovalDate").value);
    this.projectAttributes.ProviderLetterReceivedDate.attributeValue = DateHelper.format(this.projectConfigForm.get("projectSettingsFormGroup.providerLetterReceivedDate").value);
    this.projectAttributes.TargetCoderQaScore.attributeValue = this.projectConfigForm.get("projectSettingsFormGroup.targetCoderQaScore").value;
    this.projectAttributes.AllowDocumentForInProcessChase.attributeValue = this.projectConfigForm.get("projectSettingsFormGroup.allowDocumentForInProcessChase").value;
    this.projectAttributes.EnableToAttachAdditionalDocInIntake.attributeValue = this.projectConfigForm.get("projectSettingsFormGroup.enableToAttachAdditionalDocInIntake").value;
    this.projectAttributes.OverreadFeedbackEnabled.attributeValue = Number(this.projectConfigForm.get("projectSettingsFormGroup.overreadFeedbackEnabled").value);
    this.projectAttributes.DataEntryProviderConfirmationEnabled.attributeValue = Number(this.projectConfigForm.get("projectSettingsFormGroup.dataEntryProviderConfirmationEnabled").value);
    this.projectAttributes.DualRoleChaseAssignmentEnabled.attributeValue = Number(this.projectConfigForm.get("projectSettingsFormGroup.dualRoleChaseAssignmentEnabled").value);
    this.projectAttributes.GapProviderOutreachEnabled.attributeValue = Number(this.projectConfigForm.get("projectSettingsFormGroup.gapProviderOutreachEnabled").value);
    this.projectAttributes.MemberCentricChaseRetrieval.attributeValue = this.projectConfigForm.get("projectSettingsFormGroup.memberCentricChaseRetrieval").value;
    this.projectAttributes.MDPortalRetrievalEnabled.attributeValue = this.projectConfigForm.get("projectSettingsFormGroup.mdPortalRetrievalEnabled").value;
    this.projectAttributes.DocumentRequestRequiresApproval.attributeValue = Number(this.projectConfigForm.get("projectSettingsFormGroup.documentRequestRequiresApproval").value);
    this.projectAttributes.PagesRequiresApproval.attributeValue = Number(this.projectConfigForm.get("projectSettingsFormGroup.pagesRequiresApproval").value);
    this.projectAttributes.GetNextEnabled.attributeValue = Number(this.projectConfigForm.get("projectSettingsFormGroup.getNext").value);
    this.projectAttributes.ClinicalGetNextEnabled.attributeValue = Number(this.projectConfigForm.get("projectSettingsFormGroup.clinicalGetNext").value);
    this.projectAttributes.VendorInvoiceCODValue.attributeValue = Number(this.projectConfigForm.get("projectSettingsFormGroup.vendorInvoiceCODValue").value);
    this.projectAttributes.EnableMrrBot.attributeValue = Number(this.projectConfigForm.get("projectSettingsFormGroup.firstPassCodingBotEnabled").value);
    this.projectAttributes.AutoPendEmptyNlpResultChase.attributeValue = Number(this.projectConfigForm.get("projectSettingsFormGroup.autoPendEmptyNlpResult").value);
    this.projectAttributes.MRQAThresholdPageCount.attributeValue = Number(this.projectConfigForm.get("projectSettingsFormGroup.maxNumOfChasesPerPage").value);
    this.projectAttributes.VendorMatchingRequired.attributeValue = this.projectConfigForm.get("projectSettingsFormGroup.vendorMatchingRequired").value;
    this.projectAttributes.ReveleerProjectContacts.attributeValue = StringHelper.isAvailable(this.projectConfigForm.get("projectSettingsFormGroup.reveleerProjectContacts").value) ?
      this.projectConfigForm.get("projectSettingsFormGroup.reveleerProjectContacts").value : "NA";
    if (this.projectAttributes.QaRate) {
      this.projectAttributes.QaRate.attributeValue = JSON.stringify({
        qarates: Array.prototype.concat(
          this.projectConfigForm.controls.Overread.value,
          this.projectConfigForm.controls.Overread2.value,
          this.projectConfigForm.controls.MRQA.value
        ),
      });
      this.qaRates = JSON.parse(this.projectAttributes.QaRate.attributeValue);
    }

    this.projectAttributes.ProgressMilestone.attributeValue = JSON.stringify({
      milestones: Array.prototype.concat(
        this.projectConfigForm.get("slaSettingsFormGroup.contacted").value,
        this.projectConfigForm.get("slaSettingsFormGroup.abstracted").value,
        this.projectConfigForm.get("slaSettingsFormGroup.scheduled").value,
        this.projectConfigForm.get("slaSettingsFormGroup.retrieved").value
      ),
    });

    this.projectAttributes.ChartChangeAction.attributeValue = JSON.stringify({
      chartChangeAttributes: this.chartChangeActions,
    });

    this.projectAttributes.MeasureYear.attributeValue = this.projectConfigForm.get("measureYearFormGroup.measureYear").value;
    this.projectAttributes.ReportingYear.attributeValue = this.projectConfigForm.get("measureYearFormGroup.reportingYear").value;
    this.projectAttributes.NegativeReasonCodeRequired.attributeValue = Number(this.projectConfigForm.get("measureYearFormGroup.nrcRequired").value);
    this.projectAttributes.DemographicRequired.attributeValue = Number(this.projectConfigForm.get("measureYearFormGroup.demographicRequired").value);
    this.projectAttributes.ReviewPeriodFromDate.attributeValue = this.projectConfigForm.get("riskFormGroup.reviewPeriodFromDate").value;
    this.projectAttributes.ReviewPeriodThruDate.attributeValue = this.projectConfigForm.get("riskFormGroup.reviewPeriodThruDate").value;
    this.projectAttributes.CodingLevel.attributeValue = this.isIvaProject ? "2" : this.projectConfigForm.get("riskFormGroup.codingLevel").value;
    this.projectAttributes.DocumentReviewLevel.attributeValue = this.projectConfigForm.get("riskFormGroup.documentReviewLevel").value;

    this.projectAttributes.ServiceProviderRequired.attributeValue = Number(this.projectConfigForm.get("riskFormGroup.serviceProviderRequired").value);
    this.projectAttributes.ValidationReasonCodeRequired.attributeValue = Number(this.projectConfigForm.get("riskFormGroup.validationReasonCodeRequired").value);
    this.projectAttributes.EncounterTypeRequired.attributeValue = Number(this.projectConfigForm.get("riskFormGroup.encounterTypeRequired").value);
    this.projectAttributes.HIOS.attributeValue = Number(this.projectConfigForm.get("riskFormGroup.hiosId").value);

    this.projectAttributes.DataExtractType.attributeValue = this.projectConfigForm.get("dataTransferFtpFormGroup.dataExtractType").value;
    this.projectAttributes.DataExtractDelimiter.attributeValue = this.projectConfigForm.get("dataTransferFtpFormGroup.dataExtractDelimiter").value;
    this.projectAttributes.DataExtractIncludeHeader.attributeValue = this.projectConfigForm.get("dataTransferFtpFormGroup.dataExtractIncludeHeader").value;

    if (this.projectAttributes.AutocloseMemberCompliantChase) {
      this.projectAttributes.AutocloseMemberCompliantChase.attributeValue = Number(this.projectConfigForm.get("measureYearFormGroup.autocloseMemberCompliantChase").value);
    }

    if (this.projectAttributes.AutocloseMemberCompliantChaseForRecentEventMeasure) {
      this.projectAttributes.AutocloseMemberCompliantChaseForRecentEventMeasure.attributeValue = Number(this.projectConfigForm.get("measureYearFormGroup.autocloseMemberCompliantChaseForRecentEventMeasure").value);
    }

    if (this.projectAttributes.ReviewAllAdminClaimsRequired) {
      this.projectAttributes.ReviewAllAdminClaimsRequired.attributeValue = Number(this.projectConfigForm.get("riskFormGroup.reviewAdminEncountersAndDiagnoses").value);
    }

    this.projectAttributes.DataTransferEnableSchedule.attributeValue = Number(this.projectConfigForm.get("dataTransferSchedulerFormGroup.dataTransferEnableSchedule").value);
    if (this.isRiskProject && this.projectAttributes.CodingLevel.attributeValue === CodingLevelDropdown.RADiagnosisCoding && this.codingReviewMode === CodingReviewMode.DIAGNOSIS) {
      this.onLoadDisableVRCANDServiceType = true;
      this.riskFormGroup.get(this.validationReasonCodeRequired.key).disable();
      this.riskFormGroup.get(this.encounterTypeRequired.key).disable();
    }

    if (this.isRiskProject && this.isCodingReviewModeDxV3) {
      this.riskFormGroup.get(this.serviceProviderRequired.key).disable();
      this.riskFormGroup.get(this.encounterTypeRequired.key).disable();

      let value: number;
      const codingSuppressDxForConfirmedHccValue = Number(this.projectConfigForm.get("riskFormGroup.codingSuppressDxForConfirmedHcc").value);
      value = NumberHelper.isGreaterThan(codingSuppressDxForConfirmedHccValue, 0) ? Number(this.projectConfigForm.get("riskFormGroup.hccDynamicSuppression")?.value) : 0;
      this.projectAttributes.CodingSuppressDxForConfirmedHcc.attributeValue = value;
      this.projectAttributes.NoNetNewOpportunityAutoPend.attributeValue = Number(this.projectConfigForm.get("riskFormGroup.noNetNewOpportunityAutoPend")?.value ?? 0);
    }

    if (this.projectAttributes.DataTransferEnableSchedule.attributeValue === 1) {
      const dayValues = [];
      const weekDays = this.dataTransferSchedulerFormGroup.get(this.number.key).value;
      weekDays.forEach(item => {
        dayValues.push({ number: item.value });
      });
      this.projectAttributes.DataTransferScheduleSettings.attributeValue = JSON.stringify({
        Days: { Days: dayValues },
        RunAt: {
          Hour: this.dataTransferSchedulerFormGroup.get(this.hour.key).value,
          Period: this.dataTransferSchedulerFormGroup.get(this.period.key).value,
          Timezone: this.dataTransferSchedulerFormGroup.get(this.timezone.key).value,
          SchedulerEndDate: this.dataTransferSchedulerFormGroup.get(this.schedulerEndDate.key).value,
        },
      });
    }

    this.projectAttributes.ProviderPacketCoverLetterRequired.attributeValue =
    Number(this.projectConfigForm.get("providerPacketSettingsFormGroup.coverLetterRequired").value);

    this.projectAttributes.ProviderPackageCheckIncludeDocumentRequestForGap.attributeValue =
    Number(this.projectConfigForm.get("providerPacketSettingsFormGroup.providerPackageCheckIncludeDocumentRequestForGap").value);

    this.projectAttributes.ProviderPacketCoverLetterDetails.attributeValue = this.getCoverLetterDetails();

    if (this.isRiskProject || this.isIvaProject) {
      this.projectAttributes.ChasePrioritySetting.attributeValue = this.getChasePrioritySettingRisk();
      this.projectAttributes.CodingOptimizationMode.attributeValue = this.projectConfigForm.get("riskFormGroup.codingOptimizationMode").value;
    }

    if (this.isRiskProject) {
      this.projectAttributes.HccCardAdditionEnabled.attributeValue = Number(this.projectConfigForm.get("riskFormGroup.hccCardAdditionEnabled").value);

      if (this.codingReviewMode === CodingReviewMode.DIAGNOSIS_V2_2) {
        this.projectAttributes.IsEveOnlyCardsValidationRequired.attributeValue = Number(this.projectConfigForm.get("riskFormGroup.isEveOnlyCardsValidationRequired").value);
      }
    }

    if (this.isHedisProject) {
      this.projectAttributes.ChasePrioritySetting.attributeValue = this.getChasePrioritySettingHedis();
    }

    this.projectAttributes.ProviderPacketAuthLetterRequired.attributeValue =
      Number(this.projectConfigForm.get("providerPacketSettingsFormGroup.authLetterRequired").value);

    this.projectAttributes.ProviderPacketDOSPullListRequired.attributeValue =
        Number(this.projectConfigForm.get("providerPacketSettingsFormGroup.dosPullListRequired").value);

    this.projectAttributes.ProviderPacketAddressInsertRequired.attributeValue =
        Number(this.projectConfigForm.get("providerPacketSettingsFormGroup.addressInsertRequired").value);

    this.projectAttributes.ProviderPacketAuthLetterDetails.attributeValue = this.authletterURL;

    this.projectAttributes.ProviderPacketMemberListRequired.attributeValue =
      Number(this.projectConfigForm.get("providerPacketSettingsFormGroup.memberListRequired").value);

    this.projectAttributes.ProviderPacketMemberListDetails.attributeValue = JSON.stringify({
      MemberListAttributes: this.projectConfigForm.controls.MemberListHeaders.value,
    });

    this.projectAttributes.ProviderPacketChaseLetterRequired.attributeValue =
      Number(this.projectConfigForm.get("providerPacketSettingsFormGroup.chaseLetterRequired").value);

    if (this.isIvaProject) {
      const ivaDocumentArray: MappingDocument[] = [];

      if (StringHelper.isAvailable(this.enrMappingDocumentURL) && this.enrMappingDocumentURL !== "NA") {
        if (this.enrMappingDocumentAttribute.fileSize.includes("KB")) {
          this.enrMappingDocumentAttribute.fileSize = this.fileSizeConversion(this.enrMappingDocumentAttribute.fileSize);
        }
        this.enrMappingDocumentAttribute.fileType = "Mapping Document";
        ivaDocumentArray.push(this.enrMappingDocumentAttribute);
      }

      if (StringHelper.isAvailable(this.enrCrossWalkURL) && this.enrCrossWalkURL !== "NA") {
        if (this.enrCrossWalkAttribute.fileSize.includes("KB")) {
          this.enrCrossWalkAttribute.fileSize = this.fileSizeConversion(this.enrCrossWalkAttribute.fileSize);
        }
        this.enrCrossWalkAttribute.fileType = "Other Map";
        ivaDocumentArray.push(this.enrCrossWalkAttribute);
      }

      if (StringHelper.isAvailable(this.rxcMappingDocumentURL) && this.rxcMappingDocumentURL !== "NA") {
        if (this.rxcMappingDocumentAttribute.fileSize.includes("KB")) {
          this.rxcMappingDocumentAttribute.fileSize = this.fileSizeConversion(this.rxcMappingDocumentAttribute.fileSize);
        }
        this.rxcMappingDocumentAttribute.fileType = "Other Map";
        ivaDocumentArray.push(this.rxcMappingDocumentAttribute);
      }

      if (StringHelper.isAvailable(this.rxcCrossWalkURL) && this.rxcCrossWalkURL !== "NA") {
        if (this.rxcCrossWalkAttribute.fileSize.includes("KB")) {
          this.rxcCrossWalkAttribute.fileSize = this.fileSizeConversion(this.rxcCrossWalkAttribute.fileSize);
        }
        this.rxcCrossWalkAttribute.fileType = "Other Map";
        ivaDocumentArray.push(this.rxcCrossWalkAttribute);
      }

      if (StringHelper.isAvailable(this.ivaMissingMedicalURL) && this.ivaMissingMedicalURL !== "NA") {
        if (this.ivaMissingMedicalAttribute.fileSize.includes("KB")) {
          this.ivaMissingMedicalAttribute.fileSize = this.fileSizeConversion(this.ivaMissingMedicalAttribute.fileSize);
        }
        this.ivaMissingMedicalAttribute.fileType = "Other Map";
        ivaDocumentArray.push(this.ivaMissingMedicalAttribute);
      }

      this.projectAttributes.IVAMappingDocument.attributeValue = NumberHelper.isGreaterThan(ivaDocumentArray.length, 0) ?
        JSON.stringify(ivaDocumentArray) :
        "<mappingDocumentItems />";

      const manifestMapping = [];
      if (StringHelper.isAvailable(this.ivaManifestUrl) && this.ivaManifestUrl !== "NA") {
        if (this.ivaManifestAttribute.fileSize.includes("KB")) {
          this.ivaManifestAttribute.fileSize = this.fileSizeConversion(this.ivaManifestAttribute.fileSize);
        }
        this.ivaManifestAttribute.fileType = "Manifest File";
        manifestMapping.push(this.ivaManifestAttribute);
      }
      if (StringHelper.isAvailable(this.ivaManifestUrl2) && this.ivaManifestUrl2 !== "NA") {
        if (this.ivaManifestAttribute2.fileSize.includes("KB")) {
          this.ivaManifestAttribute2.fileSize = this.fileSizeConversion(this.ivaManifestAttribute2.fileSize);
        }
        this.ivaManifestAttribute2.fileType = "Manifest File";
        manifestMapping.push(this.ivaManifestAttribute2);
      }
      if (StringHelper.isAvailable(this.ivaManifestUrl3) && this.ivaManifestUrl3 !== "NA") {
        if (this.ivaManifestAttribute3.fileSize.includes("KB")) {
          this.ivaManifestAttribute3.fileSize = this.fileSizeConversion(this.ivaManifestAttribute3.fileSize);
        }
        this.ivaManifestAttribute3.fileType = "Manifest File";
        manifestMapping.push(this.ivaManifestAttribute3);
      }
      this.projectAttributes.IVAManifestFile.attributeValue = NumberHelper.isGreaterThan(manifestMapping.length, 0) ?
        JSON.stringify(manifestMapping) :
        "<manifestfileitems />";

      const subsampleMapping = [];
      if (StringHelper.isAvailable(this.ivaSubsampleUrl) && this.ivaSubsampleUrl !== "NA") {
        if (this.ivaSubsampleAttribute.fileSize.includes("KB")) {
          this.ivaSubsampleAttribute.fileSize = this.fileSizeConversion(this.ivaSubsampleAttribute.fileSize);
        }
        this.ivaSubsampleAttribute.fileType = "Subsample File";
        subsampleMapping.push(this.ivaSubsampleAttribute);
      }
      this.projectAttributes.IVASubsampleFile.attributeValue = NumberHelper.isGreaterThan(subsampleMapping.length, 0) ?
      JSON.stringify(subsampleMapping) : "<subsamplefileitems />";
    }

    this.projectAttributes.ProviderPacketChaseLetterDetails.attributeValue = JSON.stringify({
      ChaseCoverLetterAttributes: Array.prototype.concat(
        this.projectConfigForm.controls.ChaseLetterTemplate.value,
        this.projectConfigForm.controls.ChaseLetterBody.value
      ),
    });

    this.projectAttributes.ExpectedChaseCount.attributeValue = this.projectConfigForm.get("reportingFormGroup.expectedChaseCount").value;
    this.projectAttributes.ExpectedSampleFeedReceiptDate.attributeValue = this.projectConfigForm.get("reportingFormGroup.expectedSampleFeedReceiptDate").value;
    this.projectAttributes.IsReveleerProvidingServices.attributeValue = Number(this.projectConfigForm.get("reportingFormGroup.isReveleerProvidingServices").value);
    this.projectAttributes.ReveleerServiceProvided.attributeValue = this.projectConfigForm.get("reportingFormGroup.reveleerServiceProvided").value;
    this.projectAttributes.DataEntryLocation.attributeValue = this.projectConfigForm.get("reportingFormGroup.dataEntryLocation").value;
    this.projectAttributes.ResearchType.attributeValue = this.projectConfigForm.get("reportingFormGroup.researchType").value;
    this.projectAttributes.ServiceImplementationStartDate.attributeValue = DateHelper.format(this.projectConfigForm.get("reportingFormGroup.serviceImplementationStartDate").value);
    this.projectAttributes.ClinicalServiceStartDate.attributeValue = DateHelper.format(this.projectConfigForm.get("reportingFormGroup.clinicalServiceStartDate").value);
    this.projectAttributes.ClinicalServiceEndDate.attributeValue = DateHelper.format(this.projectConfigForm.get("reportingFormGroup.clinicalServiceEndDate").value);
    this.projectAttributes.RetrievalServiceStartDate.attributeValue = DateHelper.format(this.projectConfigForm.get("reportingFormGroup.retrievalServiceStartDate").value);
    this.projectAttributes.RetrievalServiceEndDate.attributeValue = DateHelper.format(this.projectConfigForm.get("reportingFormGroup.retrievalServiceEndDate").value);
    this.projectAttributes.TargetLoadDate.attributeValue = this.projectConfigForm.get("reportingFormGroup.targetLoadDate").value;
    this.projectAttributes.InitialSampleFeedReceiptDate.attributeValue = this.projectConfigForm.get("reportingFormGroup.initialSampleFeedReceiptDate").value;
    this.projectAttributes.FinalSampleFeedReceiptDate.attributeValue = this.projectConfigForm.get("reportingFormGroup.finalSampleFeedReceiptDate").value;

    if (this.projectAttributes.ChaseSubmissionItem) {
      this.projectAttributes.ChaseSubmissionItem.attributeValue =
        JSON.stringify({ chaseSubmissionItem: this.revertWorkflowStatusArray(this.chaseSubmissionItem) });
    } else {
      this.projectAttributes.ChaseSubmissionItem = new ProjectAttribute({
        ...CHASE_SUBMISSION_ITEM,
        attributeValue: JSON.stringify({ chaseSubmissionItem: this.revertWorkflowStatusArray(this.chaseSubmissionItem) }),
      });
    }


    if (this.projectAttributes.FirstPassBotEnabled) {
      this.projectAttributes.FirstPassBotEnabled.attributeValue = Number(this.projectConfigForm.get("projectSettingsFormGroup.firstPassBotEnabled").value);
      if (this.projectAttributes.FirstPassBotEnabled.attributeValue === 1 && this.formGroupAbstraction) {
        Object.keys(this.formGroupAbstraction.controls).forEach(key => {
          const keySplitValues = key.toString().split("_");
          const measureCode = keySplitValues[0];
          const compliance = keySplitValues[1];
          const complianceKey = StringHelper.isAvailable(compliance) ? `${measureCode}_${compliance}` : "";
          const checkIfSameMeasureExist = this.botFirstPassAttributeData.filter(x => x.measureCode === measureCode);
          if (!ArrayHelper.isAvailable(checkIfSameMeasureExist)) {
            this.botFirstPassAttributeData.push({
              measureCode,
              aiassist: StringHelper.isAvailable(complianceKey)
                ? NumberHelper.isAvailable(this.formGroupAbstraction.controls[complianceKey].value)
                  ? this.formGroupAbstraction.controls[complianceKey].value ? 1 : 0
                  : 0
                : 0,
            });
          } else {

            if (compliance === "aiassist") {
              checkIfSameMeasureExist[0].aiassist = (this.formGroupAbstraction.controls[complianceKey].value !== null)
                ? this.formGroupAbstraction.controls[complianceKey].value : 0;
            }
          }
        });
        if (this.projectAttributes.FirstPassBotSetting) {
          this.projectAttributes.FirstPassBotSetting.attributeValue = JSON.stringify({
            firstpassbotsettings: Array.prototype.concat(
              this.botFirstPassAttributeData
            ),
          });
        }
        this.totalChartsBotDataEnteredValue = new Textbox({ ...this.totalChartsBotDataEnteredValue, value: this.chartBotFirstPassCount } as any);
        this.projectSettingsFormGroup.get(this.totalChartsBotDataEnteredValue.key).setValue(this.chartBotFirstPassCount);
      }

    }

    const hccModelID = [this.projectConfigForm.get("riskFormGroup.hcc").value, this.projectConfigForm.get("riskFormGroup.hcc2").value].filter(Boolean).join(",");
    this.projectAttributes.HccModelID.attributeValue = StringHelper.isAvailable(hccModelID) ? hccModelID : "NA";
  }

  fileSizeConversion(ivaDocumentFileSize: string): string {
    const fileSizeConversion = ivaDocumentFileSize.replace(" KB", "");
    return `${((Number(fileSizeConversion) / 1024).toFixed(2))} MB`;
  }

  findInvalidControls() {
    const invalid = [];
    const controls = this.projectSettingsFormGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  onChangeSchedulerSettings(event): void {
    if (event.checked) {
      this.checkIfSchedulerControlsIsEnabled();
    } else {
      this.checkIfSchedulerControlsIsDisabled();
    }
    this.changeDetector.markForCheck();
  }

  onChangeDataExtractType(event): void {
    if (event.value) {
      this.dataTransferSchedulerFormGroup.get(this.dataTransferEnableSchedule.key).enable();
    }
  }

  onChangeClinicalGetNext(event): void {
    this.isShowChasePrioritySetting = event.checked;
    this.changeDetector.markForCheck();
  }

  private getCoverLetterDetails(): string {
    const newTemplate = {
      Name: this.coverLetterDetails.coverletters[this.coverLetterTemplateId].Name,
      coverletterdetails: Array.prototype.concat(
        this.projectConfigForm.controls.CoverLetterHeaders.value,
        this.projectConfigForm.controls.CoverLetterBody.value
      ),
    };

    this.coverLetterDetails.coverletters = this.coverLetterDetails.coverletters.map((item: any, index: number) =>
      index === this.coverLetterTemplateId ? newTemplate : item
    );

    return JSON.stringify(this.coverLetterDetails);
  }

  private getChasePrioritySettingHedis(): string {
    const priorityOption = this.getHedisChasePriorityValue(this.projectSettingsFormGroup.get("hedisGetNextPriority").value);
    const newChasePrioritySettingAttributeValue = this.chasePrioritySetting;
    newChasePrioritySettingAttributeValue.priorityoption = priorityOption;

    if (this.isPriorityMeasureSelected) {
      newChasePrioritySettingAttributeValue.measuresettings.measuresetting = this.getHedisMeasureValues();
    }

    return JSON.stringify(
      newChasePrioritySettingAttributeValue
    );
  }

  private getChasePrioritySettingRisk(): string {
    const newChasePrioritySettingAttributeValue = {
      ...this.chasePrioritySetting,
      measuresettings: {
        measuresetting: [{
          measureCode: "HCC",
          priority: this.projectConfigForm.get("projectSettingsFormGroup.chasePrioritySettingInput").value,
        }],
      },
    };

    return JSON.stringify(
      newChasePrioritySettingAttributeValue
    );
  }

  private setControl(controlName: string, controlType: any) {
    this.projectConfigForm.setControl(controlName, controlType);
  }

  private revertWorkflowStatusArray(chaseSubmissionItem: ChaseSubmissionWorkflowItem[]): ChaseSubmissionItem[] {
    const formattedSubmissionItem = chaseSubmissionItem;

    // TODO: Format ChaseSubmissionWorkflowItem[] to ChaseSubmissionItem[] in the back end.
    formattedSubmissionItem.forEach(x => {
      x.items.forEach((y, i) => {
        if (!y.workflowStatus && StringHelper.isAvailable(y.itemText)) {
          y.itemId = i.toString();
          y.workflowStatus = x.workflowStatus;
        }
      });
    });
    return [].concat(...formattedSubmissionItem.map(x => x.items));
  }

  private convertWorkflowStatusArray(chaseSubmissionItem: ChaseSubmissionItem[]): ChaseSubmissionWorkflowItem[] {
    const submissionItemClone = JSON.parse(JSON.stringify(this.chaseSubmissionItem));

    submissionItemClone.forEach(
      a => a.items = chaseSubmissionItem.filter(
        b => b.workflowStatus === a.workflowStatus
      )
    );
    return submissionItemClone;
  }

  private loadSubmissionItemTabs(): void {
    this.chaseSubmissionItem = [
      {
        workflowStatus: WorkflowStatusDb[WorkflowStatusDb.Abstraction],
        items: [],
      },
      {
        workflowStatus: WorkflowStatusDb[WorkflowStatusDb.Overread],
        items: [],
      },
      {
        workflowStatus: WorkflowStatusDb[WorkflowStatusDb.Overread2],
        items: [],
      },
    ];
  }

  checkIfSchedulerControlsIsEnabled() {
    this.isWeekDaysDisabled = false;
    this.dataTransferSchedulerFormGroup.get(this.number.key).enable();
    this.dataTransferSchedulerFormGroup.get(this.hour.key).enable();
    this.dataTransferSchedulerFormGroup.get(this.period.key).enable();
    this.dataTransferSchedulerFormGroup.get(this.timezone.key).enable();
    this.dataTransferSchedulerFormGroup.get(this.schedulerEndDate.key).enable();
  }

  checkIfSchedulerControlsIsDisabled() {
    this.isWeekDaysDisabled = true;
    this.dataTransferSchedulerFormGroup.get(this.number.key).disable();
    this.dataTransferSchedulerFormGroup.get(this.hour.key).disable();
    this.dataTransferSchedulerFormGroup.get(this.period.key).disable();
    this.dataTransferSchedulerFormGroup.get(this.timezone.key).disable();
    this.dataTransferSchedulerFormGroup.get(this.schedulerEndDate.key).disable();
  }

  checkDeficientChases(overreadType: string, index: number) {
    try {
      const deficientChaseValue = this.projectConfigForm.controls[overreadType].value[index].Deficientchases;
      this.isDeficientChasePopupVisible = NumberHelper.isLessThan(deficientChaseValue, 100);
      this.headerText = (overreadType === "Overread") ? "OR" : "OR2";
    } catch { }
  }

  onClosePopup() {
    this.isDeficientChasePopupVisible = false;
    this.changeDetector.markForCheck();
  }

  onRiskBotPopupButtonClick(action: string): void {
    this.isRiskBotPopupVisible = false;
    if (action === "cancel") {
      this.projectConfigForm.patchValue({
        projectSettingsFormGroup: {
          firstPassCodingBotEnabled: Number(!this.projectConfigForm.get("projectSettingsFormGroup.firstPassCodingBotEnabled").value),
        },
      });
    }
    this.changeDetector.markForCheck();
  }

  validateList() {
    return !this.chaseSubmissionItem.some(x => ArrayHelper.isAvailable(x.items) && x.items.some(y => !StringHelper.isAvailable(y.itemText)));
  }

  isChaseSubmissionItemPristine(): boolean {
    return !(this.validateList() && this.chaseSubmissionItemModified);
  }

  initializeInvoiceGrid(): void {
    this.invoiceGridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ header: "INVOICE CONFIGURATION", template: this.invoiceColumn, isSortableColumn: false }),
      ],
      hasPagination: false,
      showActionColumn: false,
      pageSize: 5,
    });
  }

  loadInvoiceSettings(): void {
    this.invoiceGridRequest = new GridRequest({
      url: this.vendorsUrl,
      filters: [
        new GridFilter({
          input: new Textbox(),
          key: "projectId",
          value: this.projectId.toString(),
          show: false,
        }),
      ],
    });
    this.refreshGrid.emit();
  }

  get isFileUploaded(): boolean {
    return this.fileDetail != null && StringHelper.isAvailable(this.fileDetail.fileName);
  }

  uploadedFileDetail(): FileDetail {
    if (ArrayHelper.isAvailable(this.authorizationLetterAttributes)) {
      this.fileDetail = {
        fileName: this.authorizationLetterAttributes.find(x => x.attributeName === "FileName").attributeValue,
        filePath: this.authorizationLetterAttributes.find(x => x.attributeName === "FilePath").attributeValue,
        fileSize: this.authorizationLetterAttributes.find(x => x.attributeName === "FileSize").attributeValue,
        uploadDateTime: this.authorizationLetterAttributes.find(x => x.attributeName === "UploadDateTime").attributeValue,
      };
    }
    return this.fileDetail;
  }

  downloadFile(): void {
    this.fileId = this.authletterURL.substring(this.authletterURL.lastIndexOf("/") + 1);
    this.service.downloadFile(this.fileId, this.authService.userId, "authorizationLetter", this.projectDocumentTypeId);
  }

  alertProjectActivation($event): void {
    this.confirmationTextForChangingProjectStatus = $event.checked ?
      "Once you save your Project Settings, you will be activating and enabling all the chases, processes, activities and reporting related to this project."
      : "Once you save your Project Settings, you will be deactivating and disabling all the chases, processes, activities and reporting related to this project.";

    this.showConfirmationForProjectStatusChange = true;
  }

  loadBotSettings() {
    if (this.hasMeasureData) {

      this.createMeasureItemControls();
    }

  }
  loadFirstPassBotSettings() {
    this.projectConfigForm.patchValue({
      projectSettingsFormGroup: {
        firstPassBotEnabled: Number(this.projectAttributes.FirstPassBotEnabled?.attributeValue),
      },
    });
    if (this.hasFirstPassMeasureData) {
      this.botFirstPassControlExist();
      this.projectConfigForm.patchValue({
        projectSettingsFormGroup: {
          totalChartsBotDataEnteredValue: this.chartBotFirstPassCount,
        },
      });
      this.getHedisFirstPassAttribute();
      this.createMeasureFirstPassItemControls();
    }
  }

  protected botFirstPassControlExist() {
    this.hasFirstPassBotEnabled = this.projectAttributes.FirstPassBotEnabled.attributeValue === "1";
  }

  private getComputedValueForChartsFirstPassBot(): void {
    this.service.getComputedValueForChartsFirstPassBot(this.projectId).subscribe(response => {
      this.totalChartBotFirstPassComputedValue = response;
      this.projectSettingsFormGroup.get(this.totalChartsBotDataEnteredValue.key).setValue(this.chartBotFirstPassCount);
    });
  }

  get chartBotFirstPassCount(): number {
    return this.totalChartBotFirstPassComputedValue;
  }


  onChangeFirstPassBot(): void {
    this.isEveForAbstractionPopupVisible = true;
    this.hasFirstPassBotEnabled = !this.hasFirstPassBotEnabled;
    if (this.hasFirstPassBotEnabled) {
      this.initializeMeasureFirstPassConfiguration();
    }
  }

  get eveForAbstractionStateLabel() {
    return this.hasFirstPassBotEnabled ? "enabling" : "deactivating";
  }

  get eveForAbstractionText() {
    return this.hasFirstPassBotEnabled ? "All legible/readable medical record charts will automatically be coded by EVE."
      : "New medical record charts will not be coded by EVE. Charts that are currently with EVE will continue through the coding process.";
  }

  onEveForAbstractionPopupCancel(): void {
    this.isEveForAbstractionPopupVisible = false;
    this.hasFirstPassBotEnabled = !this.hasFirstPassBotEnabled;
    this.projectConfigForm.patchValue({
      projectSettingsFormGroup: {
        firstPassBotEnabled: Number(!this.projectConfigForm.get("projectSettingsFormGroup.firstPassBotEnabled").value),
      },
    });
    this.changeDetector.markForCheck();
  }

  onChangeRiskBot(event): void {
    this.isRiskBotPopupVisible = true;
    this.selectedRiskBotOption = event.checked ? "enabling" : "deactivating";
    this.riskBotPopUpText = event.checked ? "All legible/readable medical record charts will automatically be coded by EVE." :
      "New medical record charts will not be coded by EVE. Charts that are currently with EVE will continue through the coding process.";
  }

  private getAllMeasureResult(): void {
    this.service
      .getAllMeasureResult(this.projectId)
      .subscribe(items => {
        this.gridData = items as any;

        if (this.projectAttributes != null && ArrayHelper.isAvailable(this.gridData)) {
          this.loadBotSettings();
          this.loadFirstPassBotSettings();
        }
        this.changeDetector.markForCheck();
      });
  }

  get hasMeasureData(): boolean {
    return ArrayHelper.isAvailable(this.gridData);
  }
  get hasFirstPassMeasureData(): boolean {
    return ArrayHelper.isAvailable(this.gridData);
  }

  initializeMeasureFirstPassConfiguration(): void {
    if (this.isHedisProject && this.hasFirstPassMeasureData && this.hasFirstPassBotEnabled) {
      this.projectSettingsFormGroup.get(this.totalChartsBotDataEnteredValue.key).setValue(this.chartBotFirstPassCount);
      this.createMeasureFirstPassItemControls();
    }
  }
  private createMeasureItemControls(): void {
    this.formModels = this.gridData
      .reduce(this.toCreateComplianceControls.bind(this), []);
    this.formGroup = this.formService.createFormGroup(this.formModels);
  }
  private createMeasureFirstPassItemControls(): void {
    this.formModelsFirstPass = this.gridData
      .reduce(this.toCreateComplianceFirstPassControls.bind(this), []);
    this.formGroupAbstraction = this.formService.createFormGroup(this.formModelsFirstPass);

    const controlsArray = Object.values(this.formGroupAbstraction?.controls);
    if (controlsArray && controlsArray.every(value => value.value === 1)) {
      this.abstractionFormGroup.get("allSelectionCheckbox").setValue(1);
    }

  }

  onAllSelection(): void {
    if (this.abstractionFormGroup.get("allSelectionCheckbox").value) {
      this.abstractionFormGroup.get("allSelectionCheckbox").setValue(1);
      Object.keys(this.formGroupAbstraction.controls).forEach(key => {
        this.formGroupAbstraction.controls[key].setValue(1);
      });

    } else {
      this.abstractionFormGroup.get("allSelectionCheckbox").setValue(null);
      Object.keys(this.formGroupAbstraction.controls).forEach(key => {
        this.formGroupAbstraction.controls[key].setValue(null);
      });
    }
  }
  private toCreateComplianceControls(controls: DynamicControl[], measures: any): DynamicControl[] {
    let checkIfMeasureMatch = false;
    let checkIfAllComplianceChecked = false;
    let botComplianceValue = null;
    let hasComplianceValue = false;
    if (this.botSetting != null && (this.botSetting.botsettings.length > 0)) {
      this.botSetting.botsettings.forEach(values => {
        checkIfMeasureMatch = (values.MeasureCode === measures.measureCode);
        if (checkIfMeasureMatch) {
          botComplianceValue = values;
          hasComplianceValue = true;
          checkIfAllComplianceChecked = ((values.Compliant && values.PartiallyCompliant
            && values.NonCompliant && values.NonCompliantExclusion && values.AIAssist) === 1);
          return false;
        }
      });
    }

    controls.push(
      new Checkbox({
        key: measures.measureCode,
        label: measures.measureCode,
        value: hasComplianceValue && checkIfAllComplianceChecked ? 1 : 0,
        parent: null,
      }));
    controls.push(
      new Checkbox({
        key: this.getComplainceKey(measures.measureCode, "aiassist"),
        value: hasComplianceValue ? botComplianceValue.AIAssist : 0,
        parent: measures.measureCode,
      }));
    controls.push(
      new Checkbox({
        key: this.getComplainceKey(measures.measureCode, "compliant"),
        value: hasComplianceValue ? botComplianceValue.Compliant : 0,
        parent: measures.measureCode,
      }));
    controls.push(
      new Checkbox({
        key: this.getComplainceKey(measures.measureCode, "partiallycompliant"),
        value: hasComplianceValue ? botComplianceValue.PartiallyCompliant : 0,
        parent: measures.measureCode,
      }));
    controls.push(
      new Checkbox({
        key: this.getComplainceKey(measures.measureCode, "noncompliant"),
        value: hasComplianceValue ? botComplianceValue.NonCompliant : 0,
        parent: measures.measureCode,
      }));
    controls.push(
      new Checkbox({
        key: this.getComplainceKey(measures.measureCode, "noncompliantexclusion"),
        value: hasComplianceValue ? botComplianceValue.NonCompliantExclusion : 0,
        parent: measures.measureCode,
      }));

    return controls;
  }
  private toCreateComplianceFirstPassControls(controls: DynamicControl[], measures: any): DynamicControl[] {
    let checkIfMeasureMatch = false;
    let checkIfAllComplianceChecked = false;
    let botComplianceValue = null;
    let hasComplianceValue = false;

    if (this.firstPassBotSetting != null && (this.firstPassBotSetting.firstpassbotsettings.length > 0)) {
      this.firstPassBotSetting.firstpassbotsettings.forEach(values => {
        checkIfMeasureMatch = (values.MeasureCode === measures.measureCode);
        if (checkIfMeasureMatch) {
          botComplianceValue = values;
          hasComplianceValue = true;
          checkIfAllComplianceChecked = ((values.AIAssist) === 1);
          return false;
        }
      });
    }
    controls.push(
      new Checkbox({
        key: measures.measureCode,
        label: measures.measureCode,
        value: hasComplianceValue && checkIfAllComplianceChecked ? 1 : 0,
        parent: null,
      }));
    controls.push(
      new Checkbox({
        key: this.getComplainceKey(measures.measureCode, "aiassist"),
        value: hasComplianceValue ? botComplianceValue.AIAssist : 0,
        parent: measures.measureCode,
      }));
    return controls;
  }
  private getComplainceKey(measureCode: number, type: string): string {
    return `${measureCode}_${type}`;
  }

  checkOverreadMeasuresSelected(): void {
    if ((this.formGroup != undefined || this.formGroupAbstraction != undefined) && this.hasFirstPassBotEnabled) {
      this.updateBotFormControl("CDC_aiassist", "overread");
      this.updateBotFormControl("CBP_aiassist", "overread");
      this.updateBotFormControl("HBD_aiassist", "overread");
      this.updateBotFormControl("EED_aiassist", "overread");
      this.updateBotFormControl("BPD_aiassist", "overread");
    }

  }


  private getMeasureFormControl(measureCode: string, botConfig: string): AbstractControl {
    for (const key in this.formGroup.controls) {
      if (this.formGroup.controls.hasOwnProperty(key)) {
        if (key === measureCode) {
          return botConfig === "overread" ? this.formGroup.controls[key] : this.formGroupAbstraction.controls[key];
        }
      }
    }
  }

  private updateBotFormControl(control: string, botConfig: string) {
    const overreadBotFormControl = this.formGroup.get(control);
    const firstPassFormControl = this.formGroupAbstraction.get(control);
    const measureCode = control.split("_")[0];
    const measureFormControl = this.getMeasureFormControl(measureCode, botConfig);

    botConfig === "overread" ?
      this.setOverreadBotFormControl(overreadBotFormControl, firstPassFormControl, measureFormControl) :
      this.setFirstPassBotFormControl(overreadBotFormControl, firstPassFormControl, measureFormControl);
  }

  private setOverreadBotFormControl(overreadBotFormControl: AbstractControl, firstPassFormControl: AbstractControl, measureFormControl: AbstractControl) {
    if (overreadBotFormControl?.valid && overreadBotFormControl?.value === 1) {
      if (firstPassFormControl?.valid && firstPassFormControl?.value === 1) {
        overreadBotFormControl.setValue(0);
        measureFormControl.setValue(0);
      }
    }
  }

  private setFirstPassBotFormControl(overreadBotFormControl: AbstractControl, firstPassFormControl: AbstractControl, measureFormControl: AbstractControl) {
    if (firstPassFormControl?.valid && firstPassFormControl?.value === 1) {
      if (overreadBotFormControl?.valid && overreadBotFormControl?.value === 1) {
        firstPassFormControl.setValue(0);
        measureFormControl.setValue(0);
      }
    }
  }

  onSelectFirstPassChange(event: any, model: any): void {
    try {
      const isCheckedValue = event.checked ? 1 : 0;

      Object.keys(this.formGroupAbstraction.controls).forEach(key => {
        const keySplitValues = key.toString().split("_");
        const measureCode = keySplitValues[0];
        const compliance = keySplitValues[1];
        const complianceKey = StringHelper.isAvailable(compliance) ? `${model.key}_${compliance}` : "";
        const selectedParent = model.parent;
        const breakLoop = false;
        if (model.key === key || complianceKey === key) {
          if (isCheckedValue === 1) {
            this.formGroupAbstraction.controls[key].setValue(isCheckedValue);
            if (StringHelper.isAvailable(compliance) && measureCode === model.parent
              && this.formGroupAbstraction.controls[`${selectedParent}_aiassist`].value === 1
            ) {
              this.formGroupAbstraction.controls[measureCode].setValue(isCheckedValue);
            }
          } else if (isCheckedValue === 0 || model.key.split("_")[0] === measureCode) {
            this.formGroupAbstraction.controls[measureCode].setValue(isCheckedValue);
            this.formGroupAbstraction.controls[key].setValue(isCheckedValue);
          }
        }

        const controlsArray = Object.values(this.formGroupAbstraction?.controls);
        if (controlsArray && controlsArray.every(value => value.value === 1)) {
            this.abstractionFormGroup.get("allSelectionCheckbox").setValue(1);
          } else {
            this.abstractionFormGroup.get("allSelectionCheckbox").setValue(null);
          }

        const exitLoop = {};
        if (breakLoop) { throw exitLoop; }
      });
    } catch (error) {

    }

  }

  getOverreadFirstPassCheckedValue(key: string, flag: number) {
    const messageText = "You cannot select the same measure for both abstraction and overread.";
    if (flag === 1 && this.formGroup != undefined) {
      const simpleKey = key.split("_")[0];
      const aiAssistKey = `${simpleKey}_aiassist`;
      const formControl = this.formGroup.get(aiAssistKey);

      if (formControl && this.formGroup.controls[aiAssistKey].valid && this.formGroup.controls[aiAssistKey].value === 1) {
        this.formGroupAbstraction.controls[key].setValue(0);
        this.messagingService.showToast(messageText, SeverityType.WARN);
      } else {
        return true;
      }
    } else if (flag === 2 && this.formGroupAbstraction != undefined && (this.formGroupAbstraction.controls[key].valid && this.formGroupAbstraction.controls[key].value === 1)) {
      this.formGroup.controls[key].setValue(0);
      this.messagingService.showToast(messageText, SeverityType.WARN);
      return false;
    } else {
      return true;
    }
  }

  createFirstPassGrid(): void {
    this.firstPassGridConfiguration.columns = [
      new GridColumnDefinition({ header: "AI Assist", width: "30.3%" }),
      new GridColumnDefinition({ header: "Measure", isSortableColumn: false, width: "28.3%" }),
      // new GridColumnDefinition({ header: "AI Assist", field: "aiassist", isSortableColumn: false, width: "33.3%" }),
    ];
    this.firstPassGridConfiguration.hasPagination = false;
    this.firstPassGridConfiguration.showExportAction = false;
    this.firstPassGridConfiguration.showActionColumn = false;
    this.firstPassGridConfiguration.showMenu = false;
  }

  getfirstClass(index: number, formModelCount: number) {

    let gridClass = "form-factory";
    if (index === 0) {
      this.rowIndex = 1;
    }

    if (index === this.rowIndex + 1) {
      this.rowIndex += 2;
      this.rowType = !this.rowType;
    }

    if (!this.rowType) {
      gridClass += " odd-row";
    } else if (this.rowType) {
      gridClass += " even-row";
    }
    if (index === formModelCount - 1) {
      this.rowType = false;
    }
    return gridClass;
  }

  getAssitClass(model: any) {
    let assistClass = "";
    if (model?.key?.includes("_aiassist")) {
      assistClass += "aiassist ";
    }
    return assistClass.trim();
  }

  get isHedisTabSelected() {
    return this.selectedTab === "hedis";
  }

  get hasBotSettings(): boolean {
    return this.projectAttributes.BotSetting;
  }
  get hasFirstPassBotSettings(): boolean {
    return this.projectAttributes.FirstPassBotSetting;
  }


  private populateTimeFrame(topYearFrame: string) {
    const year = parseInt(topYearFrame, 10);
    let options = [new SelectableInput({ text: "Prior", value: "Prior" }),
                   new SelectableInput({ text: "Birth", value: "Birth" })];
    if (this.isHedisProject) {
      options.push(new SelectableInput({ text: "MY", value: "MY" }));
    }
    for (let i = 11; i > -1; i--) {
      options.push(new SelectableInput({ text: (year - i).toString(), value: (year - i).toString() }));
    }
    this.timeFrameFrom = new Dropdown({ ...this.timeFrameFrom, options } as any);
    this.timeFrameFrom.value = year.toString();
    options = options.filter(e => e.value !== "Birth");
    this.timeFrameTo = new Dropdown({ ...this.timeFrameTo, options } as any);
    this.timeFrameTo.value = year.toString();
  }
  private getUpdatedBotSettingsValue(): void {
    this.service
      .getProjectAttribute(this.projectId, 374)
      .subscribe(result => {
        if (result != null && result.attributeCode === "BotSetting"
          && this.botSetting !== JSON.parse(result.attributeValue)) {
          // Clear DOM
          this.setControl("Overread", new FormArray([]));
          this.setControl("Overread2", new FormArray([]));
          this.setControl("MRQA", new FormArray([]));

          this.botSetting = "";
          this.botSetting = JSON.parse(result.attributeValue);
          this.loadQARatesSettings();
          this.changeDetector.markForCheck();
        }
      });
  }

  getBotHelperTextColor(): void {
    const textClass = "--bot";
    this.helperTextClass += textClass;
  }

  get riskBotHelperText(): string {
    return "Contact your Customer Success Manager to enable EVE for First Pass Coding.";
  }
  private checkIfProjectMemberCentric() {
    this.IsDocumentQAEnabled = this.projectAttributes.MemberCentricChaseRetrieval.attributeValue !== "1";
    this.changeDetector.markForCheck();
  }

  private getRiskFirstPassAttribute(): void {
    if (!this.riskFirstPassBotEnabled) {
        this.getBotHelperTextColor();
        this.projectSettingsFormGroup.get(this.firstPassCodingBotEnabled.key).disable();
        this.firstPassCodingBotEnabled = new Switch({
          ...this.firstPassCodingBotEnabled,
          disable: true,
          tooltip: "Your Service Organization has not enabled this feature. Please contact your administrator.",
        } as any);
      }
  }

  private getHedisFirstPassAttribute(): void {
    if (!this.hedisFirstPassBotEnabled) {
      this.getBotHelperTextColor();
      this.hasFirstPassBotEnabled = false;
      this.projectConfigForm.get("projectSettingsFormGroup.firstPassBotEnabled").setValue(0);
      this.projectSettingsFormGroup.get(this.firstPassBotEnabled.key).disable();
    }
  }

  getTagIcon(): string {
    return "far fa-plus-circle";
  }
  getClassForPlusIcon(): string {
    return "add-document-icon";
  }
  getRemoveIcon() {
    return "fal fa-times-circle";
  }
  private hasDocumentRequestTimeFrame(): void {
    this.isDocumentHasTimeFrame = this.documentRequirementsDetails.documentrequirements.some(x => (StringHelper.isAvailable(x.TimeFrameFromYear) && x.TimeFrameFromYear !== null) &&
      StringHelper.isAvailable(x.TimeFrameToYear) && x.TimeFrameToYear !== null);
    const isDocumentRequestEnabled = !this.documentRequirementsDetails.documentrequirements.some(x => (StringHelper.isAvailable(x.DocumentName)));
    this.isDocumentHasTimeFrame = isDocumentRequestEnabled ? true : this.isDocumentHasTimeFrame;
  }

  coverLetterSelectionChange(event): void {
    if (NumberHelper.isGreaterThan(event.oldRange?.index, 0)) {
      this.saveCoverLetterDetails();
    }
  }

  private saveCoverLetterDetails(): void {
    this.projectAttributes.ProviderPacketCoverLetterDetails.attributeValue = this.getCoverLetterDetails();
    const projectConfigurationSummary = this.buildSingleProjectConfigurationSummaryModel("ProviderPacketCoverLetterDetails");

    this.service.saveProjectAttributes(projectConfigurationSummary, this.projectConfigName, this.projectStatus).subscribe({
      error: err => {
        if (err.error instanceof Error) {
          this.messagingService.showToast("A client side error occurred while saving.", SeverityType.ERROR);
        } else {
          this.messagingService.showToast("A back end error occurred while saving project configurations.", SeverityType.ERROR);
        }
      },
    });
  }

  private buildSingleProjectConfigurationSummaryModel(attributeKey: string) {
    const model = new ProjectConfigSummary({
      projectId: this.projectId,
      projectConfigurationAttributes: Object.keys(this.projectAttributes)
        .filter(key => key === attributeKey)
        .map(
          item => this.projectAttributes[item]
        ),
    });

    return model;
  }

  onChaseSubmissionValueChange(event: ChaseSubmissionItem[]): void {
    this.chaseSubmissionItemModified = true;
  }

  onTimeFrameToChange(value: string, formGroup: FormGroup): void {
    formGroup.controls.timeframeMiddleText.setValue(value === "Prior" ? "and" : "to");
  }

  onLogoSelect(event: any): void {
    this.selectedFile = [];
    for (const file of event.files) {
      const fileExtension = file.name.substr(file.name.lastIndexOf(".") + 1).trim().toLowerCase();
      switch (fileExtension) {
        case "png":
        case "jpg":
        case "jpeg":
          const image = new Image();
          image.src = file.objectURL.changingThisBreaksApplicationSecurity;
          this.uploadedImageUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(image.src);
          this.selectedFile.push(file);
          this.providerPacketLogoFormData.append("companyLogo", file);
          this.changeDetector.detectChanges();
          break;
        default:
          break;
      }
    }
  }

  onLogoDelete(fileName: string): void {
    this.logoService.deleteServiceOrgLogo(fileName).subscribe(result => {
      if (result) {
        this.messagingService.showToast("Logo removed successfully", SeverityType.SUCCESS);
        this.logoService.getProviderPacketLogoUrl(this.projectId).subscribe(response => {
          this.logo = response.providerPacketLogo;
          this.uploadedFileName = response.fileName;
          this.changeDetector.detectChanges();
        });
      }
    });
  }

  onLogoRemove(): void {
    this.selectedFile = [];
    this.uploadedImageUrl = null;
  }

  get fileUploaded(): boolean {
    return ArrayHelper.isAvailable(this.selectedFile);
  }

  get fileName(): string {
    return (StringHelper.isAvailable(this.uploadedFileName) || ArrayHelper.isAvailable(this.selectedFile)) ?
      (ArrayHelper.isAvailable(this.selectedFile) ? this.selectedFile[0].name : this.uploadedFileName) : "Click to Browse";
  }

  get hasLogo(): boolean {
    if (this.uploadedImageUrl || this.logo) {
      return true;
    }
    return false;
  }

  get fileUploadLabel(): string {
    const labelClass = "file-upload-container__label";
    return ArrayHelper.isAvailable(this.selectedFile) || StringHelper.isAvailable(this.uploadedFileName) ? `${labelClass} file` : `${labelClass} choose`;
  }

  get logoUrl(): SafeResourceUrl {
    return ArrayHelper.isAvailable(this.selectedFile) ? this.uploadedImageUrl : this.logo;
  }

  get isServiceOrgLogoAvailable(): boolean {
    return this.logo && !this.uploadedImageUrl;
  }

  resetUpload(): void {
    this.selectedFile = [];
    this.uploadedImageUrl = null;
    this.fileUpload?.clear();
  }

  getCompanyLogoUrl(): void {
    this.logoService
      .getProviderPacketLogoUrl(this.projectId)
      .subscribe((response: ProviderPacketLogoResponseModel) => {
        if (response.providerPacketLogo && StringHelper.isAvailable(response.fileName)) {
          this.uploadedFileName = response.fileName;
          this.logo = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${response.providerPacketLogo}`);
          response.providerPacketLogo = this.logo;
        }
        this.resetUpload();
        this.changeDetector.detectChanges();
      });
  }

  private updateEnableClinicalGetNextToolTip(): void {
    this.clinicalGetNext = new Switch({
      ...this.clinicalGetNext,
      tooltip: this.getEnableClinicalGetNextToolTip,
    });
  }

  private updateSelectPriorityToolTip(): void {
    this.chasePrioritySettingInput = new Dropdown({
      ...this.chasePrioritySettingInput,
      tooltip: this.getSelectPriorityToolTip,
      appendTo: "body",
    } as any);
  }

  private hideAutoPendEmptyNlpResult(): void {
    this.autoPendEmptyNlpResult = new Switch({
      ...this.autoPendEmptyNlpResult,
      hidden: true,
      hide: true,
      disabled: true,
    });
  }

  private updateAutoPendEmptyNlpResultToolTip(): void {
    this.autoPendEmptyNlpResult = new Switch({
      ...this.autoPendEmptyNlpResult,
      tooltip: this.getAutoPendEmptyNlpResultTooltip,
    });
  }

  private showChasePrioritySetting(): void {
    this.isShowChasePrioritySetting = Number(this.projectAttributes.ClinicalGetNextEnabled.attributeValue) === 1;
    this.changeDetector.markForCheck();
  }

  validateDate() {
    const schedulerEndDate = this.dataTransferSchedulerFormGroup.get(this.schedulerEndDate.key);
    const isValid = DateHelper.isAvailable(schedulerEndDate.value);
    !isValid ? schedulerEndDate.setErrors({ "server-error": "Invalid Date" }) : schedulerEndDate.setErrors(null);
  }

  private getChasePrioritySettingInput(): string {
    if (this.isRiskProject || this.isIvaProject) {
      return StringHelper.isAvailable(this.chasePrioritySetting.measuresettings?.measuresetting[0]?.priority) ?
        this.chasePrioritySetting.measuresettings?.measuresetting[0]?.priority : "";
    }
    return "";
  }

  private getHedisChasePriorityValue(value: string): string {
    switch (value) {
      case "0":
        return PriorityOption.FIRST_CHART;
      case "1":
        return PriorityOption.MEASURE;
      default:
        return PriorityOption.FIRST_CHART;
    }
  }

  onDynamicHccChange(event: {checked: boolean}): void {
    const formControl = this.projectConfigForm.get("riskFormGroup.hccDynamicSuppression");
    if (event.checked) {
      formControl.enable();
      formControl.setValue(1); // Set the value to 1 when checked
    } else {
      formControl.disable();
      formControl.setValue(null);
    }
  }
  updateServicesMinDate(date: Calendar): void {
    this.clinicalServiceStartDate = new Calendar({ ...this.clinicalServiceStartDate, minDate: new Date(this.reportingFormGroup.get(date.key).value)});
    this.retrievalServiceStartDate = new Calendar({ ...this.retrievalServiceStartDate, minDate: new Date(this.reportingFormGroup.get(date.key).value)});
    this.changeDetector.markForCheck();
    this.formService.updateDom.next();
  }

  updateRetrievalServiceMinDate(fromDate: Calendar, toDate: Calendar): void {
    this.retrievalServiceEndDate = new Calendar({ ...toDate, minDate: new Date(this.reportingFormGroup.get(fromDate.key).value) });

    const dateValidators = [Validators.required, dateGreaterThanValidator(new Date(this.reportingFormGroup.get(fromDate.key).value))];

    const retreivalEndDateControl = this.reportingFormGroup.get(toDate.key);
    retreivalEndDateControl.clearValidators();
    retreivalEndDateControl.setValidators(dateValidators);
    retreivalEndDateControl.updateValueAndValidity();
    this.changeDetector.markForCheck();
    this.formService.updateDom.next();
  }

  updateClinicalServiceMinDate(fromDate: Calendar, toDate: Calendar): void {
    this.clinicalServiceEndDate = new Calendar({ ...toDate, minDate: new Date(this.reportingFormGroup.get(fromDate.key).value) });
    const dateValidators = [Validators.required, dateGreaterThanValidator(new Date(this.reportingFormGroup.get(fromDate.key).value))];

    const clinicalEndDateControl = this.reportingFormGroup.get(toDate.key);
    clinicalEndDateControl.clearValidators();
    clinicalEndDateControl.setValidators(dateValidators);
    clinicalEndDateControl.updateValueAndValidity();
    this.changeDetector.markForCheck();
    this.formService.updateDom.next();
  }

}
