<member-risk-read-only></member-risk-read-only>
<member-risk-submit-summary></member-risk-submit-summary>
<div class="coding-container-section" *ngIf="!isReadOnly">
  <app-button class="coding-container__submit-button" title="Submit" (onClick)="submit()"
    text="Submit Chase">
  </app-button>
</div>

<member-risk-validation-modal></member-risk-validation-modal>

<member-resolve-pend-warning-modal [(visible)]="isResolvePendWarningVisible" (onUpdate)="updateChaseData()">
</member-resolve-pend-warning-modal>