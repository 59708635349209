<app-modal class="new-project" [(visible)]="isNewProjectVisible" header="CREATE NEW PROJECT"
  (visibleChange)="visibleChange($event)" (onCancel)="cancel($event)" >
  <form [formGroup]="formGroup">
    <div class="ui-g ui-fluid basic-filter">
      <div class="ui-g-12">
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup"  [model]="projectName"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="projectOwners"></form-factory>
        </div>
      </div>
      <div class="ui-g-12">
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="projectTypes" (onChange)="projectTypeSelectionChange($event)">
          </form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="projectRetrievalType"></form-factory>
        </div>
      </div>
      <div class="ui-g-12" *ngIf="!isChartLakeProject && isShowProjectReviewPeriod">
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="projectReviewPeriodFromDate"
                        (onBlur)="onDateChange(projectReviewPeriodFromDate,projectReviewPeriodThruDate)"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="projectReviewPeriodThruDate"
                        (onBlur)="onDateChange(projectReviewPeriodFromDate,projectReviewPeriodThruDate)"></form-factory>
        </div>
      </div>
      <div *ngIf="!isChartLakeProject && isShowProjectPeriod && !isMemberManagementProject" class="ui-g-12 ui-md-12 ui-lg-12">      
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="projectStartDate" (onBlur)="projectEndDateChange()"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="projectEndDate" (onBlur)="projectEndDateChange()"></form-factory>
        </div>
      </div>
      <div class="ui-g-12" *ngIf="isRiskProject && isReviewAndProjectDatesFilled">
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [customTemplate]="customItem" [formGroup]="riskFormGroup" [model]="codingReviewMode"></form-factory>
          <ng-template let-item #customItem>
            <div class="dropdown-item">
              {{item?.text}} <app-tool-tip [text]="item?.extra?.tooltip" tooltipPosition="right" ></app-tool-tip>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="ui-g-12" *ngIf="useRiskSettings && isReviewAndProjectDatesFilled">
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="riskFormGroup" [model]="lineOfBusiness"
                        (onChange)="lineOfBusinessSelectionChange($event)"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="riskFormGroup" [model]="hcc" (onChange)="onhccSelection($event)"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-6" *ngIf="isLOBRxMedicare && (isCodingModel_MAHCCV242023 || isCodingModel_MAHCCV24) && isReviewPeriodStartingYear2023()">
          <form-factory [formGroup]="riskFormGroup" [model]="hcc2" (onChange)="onhcc2Selection($event)"></form-factory>
        </div>
      </div>
      <div class="ui-g-12" *ngIf="isShowRxInputs && isReviewAndProjectDatesFilled">
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="riskFormGroup" [clearable]="true" [model]="lineOfBusinessRx" (onChange)="lineOfBusinessRxSelectionChange($event)"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="riskFormGroup" [model]="hccRx"></form-factory>
        </div>
      </div>
      <div *ngIf="!isChartLakeProject && isShowMeasurementAndReportingYear" class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="measurementInput"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="reportingYearInput"></form-factory>
        </div>
      </div>
      <div class="ui-g-12" *ngIf="isReviewAndProjectDatesFilled">
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="productTypes" (onChange)="productTypeSelectionChange($event)"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-6">
          <form-factory [formGroup]="formGroup" [model]="clientOrgs"></form-factory>
        </div>
      </div>
    </div>
  </form>
  <footer>
    <app-button class="header-button" text="SAVE AND CONFIGURE" (onClick)="createProject()" [disabled]="!isFormValid">
    </app-button>
  </footer>
</app-modal>
