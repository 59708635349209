<div #containerBody class="chase-detail-chart-container"
  [ngClass]="(this.menuService.showHeader | async) ? 'compress' : 'expand'">
  <div class="chart-container" [ngClass]="{ 'max': isChartMaxed }" [ngStyle]="getPopOutPDFViewerStyle()">
    <app-document-page-viewer [chaseId]="chaseId" [isReadOnly]="!assignedToCurrentUser || auditMode"
      [isOcrDataAvailable]="isOcrDataAvailable" [status]="status"
      [isExpandedViewFromUrl]="isExpandedViewFromUrl" [(isMaximized)]="isChartMaxed" [showChartThumbnails]="!auditMode"
      (hasNoDocumentPages)="hasNoDocumentPages($event)" [duplicateTab]="duplicateTab" (isOpenSplitScreenUrl)="isOpenSplitScreenUrl($event)" [jumpPageNumber]="selectedPageNumber"
      [isSplitScreenEnabled]="isSplitScreenEnabled" [isPopOutBtnEnabled]="enablePopUpBtn">
    </app-document-page-viewer>
  </div>
  <div [ngClass]="codingStyles" [ngStyle]="getPopOutStyle()" id="scrollElement" >

    <div class="coding-container__member-chase-toggle" *ngIf="isMemberCentricChase">
      <app-button *ngFor="let chase of chaseDetailState.memberCentricChases; index as i; trackBy: trackByIndex"
                  classes="small" [ngClass]="getMemberCentricButtonStyle(chase)" [text]="chase.measureCode"
                  (onClick)="toggleMemberCentricChase(chase.chaseId)" [icon]="getMeasureButtonIcon(chase)">
      </app-button>
    </div>
    <div [ngClass]="memberCodingStyles">

      <div class="coding-container__member-catalytic-result" *ngIf="showNLPResults">
        <app-button class="coding-container__member-catalytic-result--button" text="Review" [disabled]="!isEnabled" (onClick)="showAndGetCatalyticResults()"></app-button>
        <member-nlp-hedis *ngIf="isCatalyticResultsVisible"
                          [(visible)]="isCatalyticResultsVisible"
                          [chaseId]="chaseId"
                          [measureYear]="measureYear"
                          [isFormReloadingComplete]="isFormReloadingComplete"></member-nlp-hedis>
      </div>

      <div class="audit-container-section" *ngIf="chaseDetailsForAudit">
        <app-audit-page-entry [chaseDetailsForAudit]="chaseDetailsForAudit"
                              (includeCodingPrintScreen)="onincludeCodingPrintScreen($event)" [hasNoDocumentPages]="hasNoDocPages">
        </app-audit-page-entry>
      </div>

      <div id="printScreenSection">
        <div *ngIf="showMemberValidation" class="coding-container-section" [ngClass]="showIncorrectMemberValidationInputs">
          <div class="coding-container__member-validation-section coding-container__member-validation">
            <app-member-validation [configuration]="memberValidationData" (onSubmit)="memberValidationSubmit($event, showSuccessToast)" (onCorrectMemberSelection)="showMemberValidationInputs($event)"
                                   [enabled]="assignedToCurrentUser && isStatusAvailable">
            </app-member-validation>
          </div>
          <div *ngIf="useRisk" class="coding-container__member-validation-section">
            <member-member-dob-gen class="coding-container__member-dob-gen" [configuration]="memberValidationData" [chaseId]="chaseId" [enabled]="isEnabled" (onChange)="handleChange($event)">
            </member-member-dob-gen>
          </div>
          <div class="coding-container__member-validation-section">
            <member-medical-record-dob class="coding-container__medical-record-dob" *ngIf="isHedis"
                                       [configuration]="memberValidationData" [chaseId]="chaseId" [enabled]="isEnabled"
                                       (onChange)="memberValidationSubmit($event)">
            </member-medical-record-dob>
          </div>
          <div *ngIf="isHedis" class="coding-container__member-validation-section">
            <member-additional-member-detail class="coding-container__additional-member-detail"
                                             [chaseId]="chaseId" [enabled]="isEnabled" (onChange)="handleChange($event)">
            </member-additional-member-detail>
          </div>
        </div>
        <div *ngIf="isServiceProviderVisible" class="coding-container-section">
          <member-service-provider [chaseId]="chaseId"  [enabled]="isEnabled" (onChange)="handleChange($event)"
                                   class="coding-container__member-service-provider-section"></member-service-provider>
        </div>

        <!-- HACK: Separate sections by Project and/or Measure type -->
        <div *ngIf="isHedis" class="coding-container-section">
          <app-legend [legendData]="dataEntryLegend"></app-legend>
        </div>
        <div *ngIf="isHedis" class="coding-container-section">
          <form class="form" [formGroup]="formGroup">
            <form-factory #codingContainer *ngFor="let model of formModels; index as i; trackBy: trackByIndex" [formGroup]="formGroup" [model]="model"
                          (onChange)="handleChange($event)" (click)="handleClick($event)" [ngClass]="codingClasses(codingContainer)"></form-factory>
          </form>
        </div>

        <div *ngIf="isHedis" class="coding-container-section coding-container-section__member-exclusion">
          <ng-container *ngIf="isAddButtonEnabled else noExclusions">
            Exclusions <button [disabled]="!isEnabled" class="control__add exclusion" (click)="addExclusion()"> + </button>
          </ng-container>
          <ng-template #noExclusions>
            No Exclusions
          </ng-template>
          <member-exclusion-and-contra [measureCode]="measureCode" [measureYear]="measureYear" [enabled]="isEnabled"
                                       [chaseIdForAudit]="chaseId" [isOverread]="isInAnyOverread"
                                       [workflowStatus]="chaseStatusToDisplayDe" (onErrorExCon)="onExclusionContraError($event)"
                                       (onLoadExclusions)="onLoadExclusions($event)"></member-exclusion-and-contra>
        </div>

        <div *ngIf="isHedis && !isFormReloading" class="coding-container-section">
          <member-compliance class="coding-container__member-compliance" [formGroup]="formGroup" [formModels]="formModels" [enabled]="isEnabled"></member-compliance>
        </div>

        <div *ngIf="isHedis" class="coding-container-section">
          <member-summary [memberId]="memberId"></member-summary>
        </div>

        <div *ngIf="useRisk" class="coding-container-section">
          <member-risk></member-risk>
        </div>

        <div *ngIf="isEnr" class="coding-container-section">
          <member-enr></member-enr>
        </div>

        <div *ngIf="isAtt" class="coding-container-section">
          <member-att></member-att>
        </div>

        <div *ngIf="isDob" class="coding-container-section">
          <member-dob-gen></member-dob-gen>
        </div>

        <div *ngIf="isGen" class="coding-container-section">
          <member-gender></member-gender>
        </div>

        <div *ngIf="isDobGen" class="coding-container-section">
          <member-dob-gen></member-dob-gen>
        </div>

        <div *ngIf="isRxcp" class="coding-container-section">
          <member-rxcp></member-rxcp>
        </div>

        <div *ngIf="isRxcm" class="coding-container-section">
          <member-rxcm></member-rxcm>
        </div>

        <div *ngIf="isAdrv" class="coding-container-section">
          <member-adrv></member-adrv>
        </div>
        <member-yes-no-radio class="coding-container-section"
        [researchDisable]="isResearchDisable" (researchEnabled)="setResearchButtonsEnabled($event)" (researchValidated)="setResearchButtonsValidated($event)">
        </member-yes-no-radio>
        <!-- END HACK -->


        <div class="coding-container-section">
          <app-button class="coding-container__submit-button" classes="small" title="{{pcInfo}}" (onClick)="submit()" text="Submit Chase" [disabled]="isSubmitButtonDisabled"></app-button>
        </div>

      </div>
    </div>
  </div>
</div>

<member-feedback-modal *ngIf="isOverreadFeedbackEnabled && isStatusNotManagerReview && !duplicateTab" [isRiskOrIva]="isRisk || isIva"
  (onWorkflowStatusToggle)="toggleCodingData($event)" [chaseId]="chaseId"
  [chaseOverreadDetail]="chaseOverreadDetail"
  >
</member-feedback-modal>
<member-challenge-modal *ngIf="isOverreadFeedbackEnabled && isManagerReview && !duplicateTab" [isRiskOrIva]="isRisk || isIva"
  (onWorkflowStatusToggle)="toggleCodingData($event)" [chaseId]="chaseId"
  [chaseOverreadDetail]="chaseOverreadDetail"
  >
</member-challenge-modal>

<member-entity-error-modal *ngIf="!useRisk && !isHedis && !duplicateTab"></member-entity-error-modal>
<member-resolve-pend-warning-modal [(visible)]="isResolvePendWarningVisible" [pageType]="fromPage" (onUpdate)="updateChaseData()">
</member-resolve-pend-warning-modal>

<app-confirmation-modal [(visible)]="showConfirmation" header="WARNING" acceptText="Confirm" rejectText="CANCEL"
  (onYes)="deleteControls()">
  This is a permanent action and cannot be undone. Are you sure you want to delete?
</app-confirmation-modal>
