import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Observable, combineLatest } from "rxjs";
import { filter, finalize, map } from "rxjs/operators";
import { SubSink } from "subsink";
import { AuthService } from "../../../../../../../../../auth/auth.service";
import { AutomapperService } from "../../../../../../../../../core/automapper/automapper.service";
import { MessagingService } from "../../../../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../../../../core/messaging/severity-type.enum";
import { LocalService } from "../../../../../../../../../core/storage/local.service";
import { FormService } from "../../../../../../../../../dynamic-forms/form.service";
import { Checkbox } from "../../../../../../../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { Dropdown } from "../../../../../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { DynamicInput } from "../../../../../../../../../dynamic-forms/inputs/dynamic-input.model";
import { NoConfirmProvider } from "../../../../../../../../../dynamic-forms/inputs/no-confirm-provider/no-confirm-provider.model";
import { TextboxType } from "../../../../../../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { dateBetweenValidator } from "../../../../../../../../../dynamic-forms/validators/date-between.validator";
import { ArrayHelper } from "../../../../../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../../../../../../utilities/contracts/number-helper";
import { ObjectHelper } from "../../../../../../../../../utilities/contracts/object-helper";
import { StringHelper } from "../../../../../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../../../../../utilities/reg-Ex-Helper";
import { DynamicEntityAttribute } from "../../../../../../../../api/member-validation/dynamic-entity-attribute.model";
import { CHART_PAGE_NUMBER, CLAIM_ID, ENCOUNTER_FOUND, ENCOUNTER_TYPE, EXEMPT_FROM_SCORING, FROM_DATE, NLP_REVIEW_RESULT, PROVIDER_ID, THRU_DATE } from "../../../../../../chase-detail/chase-detail-chart/attributes";
import { ChartService } from "../../../../../../chase-detail/chase-detail-chart/chart.service";
import { Encounter } from "../../../../../../chase-detail/chase-detail-chart/risk/encounter/encounter.model";
import { RiskEntity } from "../../../../../../chase-detail/chase-detail-chart/risk/risk-entity.model";
import { RiskHelper } from "../../../../../../chase-detail/chase-detail-chart/risk/risk-helper.model";
import { RiskState } from "../../../../../../chase-detail/chase-detail-chart/risk/risk-state.model";
import { RiskService } from "../../../../../../chase-detail/chase-detail-chart/risk/risk.service";
import { ChaseDetailState } from "../../../../../../chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../../../../../chase-detail/chase-detail-state.service";
import { DocumentViewerSessionService } from "../../../../../document-viewer-session.service";
import { LOCALSTORAGE_RESTRICTENCOUNTERPAGEJUMP } from "../../../../local-storage-keys";

@Component({
  selector: "member-risk-encounter-data-entry",
  templateUrl: "./encounter-data-entry.component.html",
  styleUrls: ["./encounter-data-entry.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EncounterDataEntryComponent implements OnInit, OnDestroy {
  @Output() isReadOnly: EventEmitter<boolean> = new EventEmitter<boolean>();
  private selectedEncounterIndex: number;
  private encounterTypeRequired: string;
  private serviceProviderRequired: string;
  private isEncounterValidationUnselected: boolean;
  private isSaving: boolean;
  private isEveMatchedEncounterAttributesSaved = false;
  private isMrr = false;
  private isOverread2 = false;
  private isProjectEnabledForRiskNlp = false;
  private nlpReviewResultInput: Textbox;
  private selectedEncounter: RiskEntity;
  private encounterFoundAttribute: DynamicEntityAttribute;
  private nlpReviewResultAttribute: DynamicEntityAttribute;
  private sink = new SubSink();

  @Input() enabled = true;

  adminEncounterSelected = false;
  isEnabledByMemberValidation = false;
  encounterTitle = "";
  eveIconClass = "";
  encounter: Encounter;
  exemptCheckbox: Checkbox;
  form: FormGroup;
  providerTextInput: Textbox;
  pageNumberInput: Textbox;
  startDateInput: Textbox;
  endDateInput: Textbox;
  idInput: Textbox;
  providerInput: NoConfirmProvider;
  encounterTypeInput: Dropdown;
  isSaveEnabled: boolean;
  isOverread = false;
  isValidEncounter = null;

  constructor(
    private readonly automapper: AutomapperService,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly formService: FormService,
    private readonly riskService: RiskService,
    private readonly authService: AuthService,
    private readonly chartService: ChartService,
    private readonly documentViewerSessionService: DocumentViewerSessionService,
    private readonly messagingService: MessagingService,
    private readonly localService: LocalService
  ) { }

  get isEve(): boolean {
    return this.encounter?.isEve;
  }
  get showSaveButton(): boolean {
    return !(this.adminEncounterSelected && this.isMrr);
  }
  get showEveIcon(): boolean {
    return (this.isAdmin || this.isEve) && !(this.isOverread || this.isOverread2) && this.isProjectEnabledForRiskNlp;
  }

  get isCoderEncounter(): boolean {
    return this.encounter?.isCoder;
  }

  get showCoderClass(): string {
    return this.isCoderEncounter ? "encounter-v2__coder" : "";
  }

  get showAdminClass(): string {
    if (this.isAdmin) {
      return "encounter-v2__adminEncounterSaveGroup encounter-v2__adminEncounterSaveGroupPadding";
    }
    if (this.isCoderEncounter) {
      return "encounter-v2__coder encounter-v2__encounterSaveGroup";
    }
    return "encounter-v2__encounterSaveGroup";
  }

  get isAdminEncounter(): boolean {
    return this.encounter?.isAdmin;
  }

  private get isServiceProviderRequired(): boolean {
    return this.serviceProviderRequired === "1";
  }

  private get isEncounterTypeRequired(): boolean {
    return this.encounterTypeRequired === "1";
  }

  private get isAdmin(): boolean {
    return this.encounter?.isAdmin;
  }

  private get hasDiagnoses(): boolean {
    return ArrayHelper.isAvailable(this.selectedEncounter.diagnoses);
  }

  private get isEmployeeRole(): boolean {
    return (!this.authService.user.isAdminRole || !this.authService.user.isManagerRole || !this.authService.user.isLeadRole) && this.authService.user.isEmployeeRole;
  }

  private get isEncounterFound(): boolean {
    return ObjectHelper.isEmpty(this.encounterFoundAttribute) ? null : this.encounterFoundAttribute.value === "1";
  }

  ngOnInit(): void {
    this.initializeForm();

    this.sink.add(

      this.chaseDetailStateService.isEncounterValidated$
        .pipe(filter(validation => !ObjectHelper.isEmpty(this.selectedEncounter) && !ObjectHelper.isEmpty(validation)))
        .subscribe(validation => this.isEncounterValidationUnselected = validation[this.selectedEncounter.entityId]?.status === null),

      this.chaseDetailStateService.isEncounterSelected$
        .pipe(
          filter(encounter => !ObjectHelper.isEmpty(encounter)),
          map((encounter: Encounter) => {
            this.isEveMatchedEncounterAttributesSaved = encounter.encounterId === this.encounter?.encounterId;
            this.encounter = encounter;
            this.encounterTitle = encounter.encounterSourceV2;
            this.adminEncounterSelected = encounter.isAdmin;
            this.eveIconClass = (encounter.isAdminWithEveMatch || encounter.isBotSource) ? "encounter-v2__icon--isEve" : "encounter-v2__icon--notEve";
          }))
        .subscribe(),

      this.riskService.dxCardProvider$
        .pipe(
          filter((newProvider: any) => !ObjectHelper.isEmpty(newProvider) && ArrayHelper.isAvailable(newProvider.providers)),
          map(_ => this.tryEnablingSaveButton()))
        .subscribe(),

      combineLatest([
        this.riskService.data,
        this.chaseDetailStateService.state,
      ])
        .pipe(
          filter(([riskState, chaseDetailState]: any[]) =>
            !ObjectHelper.isEmpty(riskState.selectedEncounter &&
              !ObjectHelper.isEmpty(chaseDetailState.projectConfiguration))),
          map(([riskState, chaseDetailState]: any[]) => {
            this.isMrr = chaseDetailState.isMrr;
            this.isOverread = chaseDetailState.isOverread;
            this.isOverread2 = chaseDetailState.isOverread2;
            this.isProjectEnabledForRiskNlp = chaseDetailState.isProjectEnabledForRiskNlp;
            this.updateFormWithProjectConfiguration(chaseDetailState);
            this.disableSaveButton(false);
            this.updateForm(riskState);
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
    this.localService.delete(LOCALSTORAGE_RESTRICTENCOUNTERPAGEJUMP.key);
  }

  showEncountersFilter() {
    this.chaseDetailStateService.dataEntryClicked(this.encounter);
  }

  save(): void {
    if (this.form.invalid) {
      return;
    }

    this.disableSaveButton();
    const controls = Object.values(this.form.controls);

    const attributes = controls
      .filter(this.isAvailableAttribute.bind(this))
      .map(this.getSaveAttribute.bind(this)) as DynamicEntityAttribute[];

    this.persistAttributes(attributes);
  }

  onValidated(event: any): void {
    if (ObjectHelper.isEmpty(this.encounter)) {
      return;
    }

    if (event.isClearedOut) {
      this.unselectEncounterValidation();
      return;
    }

    // Don't save if first selection is a encounter not found for eve dx.
    if (ObjectHelper.isEmpty(this.encounterFoundAttribute) && !event.isValidated && !this.isEncounterValidationUnselected
      || this.isEncounterFound === event.isValidated) {
      return;
    }

    if (!event.isValidated && this.isEve && this.isMrr) {
      this.clearEncounterFoundAttribute();
      return;
    }

    this.isValidEncounter = event.isValidated;
    this.validateEncounterFoundAttribute(event.isValidated);
    this.persistAttributes([this.encounterFoundAttribute]);


  }

  onInputChange(event: any, isCheckbox = false): void {
    switch (event.type) {
      case "save":
      case "change":
      case "notify":
        this.tryEnablingSaveButton();
        break;
      case "clear":
      case "delete":
        this.deleteAttributesByInputs(event.model.controls);
        break;
      default:
        break;
    }

    if (isCheckbox) {
      this.tryEnablingSaveButton();
    }
  }

  private clearEncounterFoundAttribute(): void {
    this.validateEncounterFoundAttribute(false);
    const attributes = [this.encounterFoundAttribute] as DynamicEntityAttribute[];

    if (this.isAdmin) {
      this.removeEncounterFoundAttribute().subscribe(() => {
        this.saveEncounterFoundAttribute(attributes);
      });
      return;
    }

    this.extendAttributes(attributes);
    this.saveEncounterFoundAttribute(attributes);
  }

  private initializeForm(): void {
    this.idInput = new Textbox({
      key: "encounterId",
      label: "Claim ID",
      isAdmin: true,
      validators: [Validators.required],
      errorMessages: {
        required: "Claim ID is required",
      },
      disabled: true,
    });

    this.providerTextInput = new Textbox({
      key: "providerText",
      label: "Provider",
      disabled: true,
    });

    this.encounterTypeInput = new Dropdown({
      key: "encounterType",
      placeholder: "Select...",
      label: "Service Type",
      disabled: true,
    });

    this.exemptCheckbox = new Checkbox({
      key: `exemptCheckbox`,
      label: "Exempt",
    });

    this.pageNumberInput = new Textbox({
      key: "pageNumber",
      label: "Page Number",
      type: TextboxType.NUMBER,
      validators: [
        Validators.min(1),
        Validators.pattern(RegExHelper.wholeNumber),
      ],
      errorMessages: {
        min: "Enter a page number greater than 1",
        pattern: "The page number must be an integer",
      },
      disabled: true,
    });

    this.nlpReviewResultInput = new Textbox({
      key: "nlpReviewResult",
      disabled: true,
      hidden: true,
    });

    this.startDateInput = new Textbox({
      key: "StartDate",
      label: "Start Date",
      dataType: "date",
      disabled: true,
    });

    this.endDateInput = new Textbox({
      key: "EndDate",
      label: "End Date",
      dataType: "date",
      disabled: true,
    });

    this.providerInput = new NoConfirmProvider({
      key: "Provider",
      label: "Provider",
      disabled: true,
      hidden: true,
    });

    this.form = this.formService.createFormGroup([
      this.nlpReviewResultInput,
      this.pageNumberInput,
      this.startDateInput,
      this.endDateInput,
      this.providerInput,
      this.exemptCheckbox,
      this.providerTextInput,
      this.idInput,
      this.encounterTypeInput,
    ]);
  }

  private unselectEncounterValidation(): void {
    if (this.isEve && (this.isOverread || this.isOverread2)) {
      this.chaseDetailStateService.setEncounterValidated(
        this.selectedEncounter.entityId,
        this.isEncounterFound
      );
      return;
    }
    const chaseId = this.riskService.data.value.chaseId;
    this.removeEncounterFoundAttribute().subscribe(() => this.encounterFoundAttribute = null);
    this.chaseDetailStateService.setEncounterValidated(this.selectedEncounter.entityId, null);
    if (this.isEve) {
      this.riskService.refreshData(chaseId, true);
    }
    const attributes = this.selectedEncounter.attributes.filter(attr => attr.attributeCode !== ENCOUNTER_FOUND.attributeCode);
    this.riskService.setEncounterAttributes(this.selectedEncounterIndex, attributes);
  }

  private removeEncounterFoundAttribute(): Observable<void> {
    const attributes = [this.encounterFoundAttribute] as DynamicEntityAttribute[];
    return this.riskService.clearEncounter(attributes);
  }

  private saveEncounterFoundAttribute(attributes: DynamicEntityAttribute[]): void {
    const chaseId = this.riskService.data.value.chaseId;
    this.riskService.save(attributes, true).subscribe(newAttributes => {
      if (this.isEve) {
        this.riskService.refreshData(chaseId, true);
        return;
      }
      this.riskService.setEncounterAttributes(this.selectedEncounterIndex, [this.nlpReviewResultAttribute, ...newAttributes]);
    });
  }

  private validateEncounterFoundAttribute(isFound: boolean): void {
    if (ObjectHelper.isEmpty(this.selectedEncounter)) {
      return;
    }

    if (ObjectHelper.isEmpty(this.encounterFoundAttribute)) {
      this.encounterFoundAttribute = {
        ...ENCOUNTER_FOUND,
        chaseId: this.selectedEncounter.chaseId,
        entityTypeId: this.selectedEncounter.entityTypeId,
        value: isFound ? "1" : "0",
      } as any as DynamicEntityAttribute;
    }

    this.encounterFoundAttribute.entityId = this.selectedEncounter.entityId;
    this.encounterFoundAttribute.value = isFound ? "1" : "0";
    this.chaseDetailStateService.setEncounterValidated(this.selectedEncounter.entityId, isFound);
  }

  private updateFormWithProjectConfiguration({ projectConfiguration }: ChaseDetailState): void {
    if (projectConfiguration != null) {
      const { reviewPeriodFromDate, reviewPeriodThruDate } = projectConfiguration;
      const dateValidators = [dateBetweenValidator(reviewPeriodFromDate, reviewPeriodThruDate)];
      const datebetween = `The date must be between ${DateHelper.format(reviewPeriodFromDate)} - ${DateHelper.format(reviewPeriodThruDate)}`;

      this.form.get(this.startDateInput.getMasterKey()).setValidators(dateValidators);
      this.startDateInput.validators = dateValidators;
      this.startDateInput.errorMessages = {
        ...this.startDateInput.errorMessages,
        datebetween,
      };

      this.form.get(this.endDateInput.getMasterKey()).setValidators(dateValidators);
      this.endDateInput.validators = dateValidators;
      this.endDateInput.errorMessages = {
        ...this.endDateInput.errorMessages,
        datebetween,
      };

      this.serviceProviderRequired =
        StringHelper.isAvailable(projectConfiguration.serviceProviderRequired) ? projectConfiguration.serviceProviderRequired : "1";

      this.encounterTypeRequired =
        StringHelper.isAvailable(projectConfiguration.encounterTypeRequired) ? projectConfiguration.encounterTypeRequired : "1";

      if (this.isServiceProviderRequired) {
        this.form.get(this.providerInput.getMasterKey())?.setValidators([Validators.required]);
        this.providerInput.errorMessages = {
          required: "Provider is Required",
        };
      }

      if (this.isEncounterTypeRequired) {
        this.form.get(this.encounterTypeInput.getMasterKey())?.setValidators([Validators.required]);
        this.encounterTypeInput.errorMessages = {
          required: "Encounter Type is required",
        };
      }
    }
  }

  private setupEncounterFoundAttribute(selectedEncounter: RiskEntity, encounterIndex?: number): void {
    this.encounterFoundAttribute = RiskHelper.getAttribute(ENCOUNTER_FOUND.attributeCode, selectedEncounter);
    this.chaseDetailStateService.setEncounterValidated(selectedEncounter.entityId, this.isEncounterFound);
    if (this.encounterFoundAttribute.isNlp) {
      const attributes = selectedEncounter.attributes.filter(attr => attr.attributeCode !== ENCOUNTER_FOUND.attributeCode);
      this.riskService.setEncounterAttributes(encounterIndex, attributes);
      this.isReadOnly.emit(true);
    }
  }

  private setupNlpReviewResultAttribute(selectedEncounter: RiskEntity): void {
    this.nlpReviewResultAttribute = RiskHelper.getAttribute(NLP_REVIEW_RESULT.attributeCode, selectedEncounter);
  }

  private updateForm(state: RiskState): void {
    if (state.hasSelectedEncounterIndex) {
      this.selectedEncounter = state.selectedEncounter;
      this.isEnabledByMemberValidation = state.isEnabled;
      this.setupEncounterFoundAttribute(state.selectedEncounter, state.selectedEncounterIndex);
      this.setupNlpReviewResultAttribute(state.selectedEncounter);
      const singleEntity = this.selectedEncounter;
      const chaseId = state.chaseId;
      const workflowStatusName = state.workflowStatusName.toLowerCase();
      const {entityId, entityTypeId} = this.selectedEncounter;
      const enableForm = this.isEnabledByMemberValidation && state.isEncYes;
      const isAdminEncounter = RiskHelper.getAttribute("ClaimID", singleEntity).isAdmin;
      const enableServiceProvider = enableForm && this.isServiceProviderRequired;
      const disabled = !enableForm;
      this.form.patchValue({ providerText: RiskHelper.getAttribute("ProviderName", singleEntity)?.value });
      this.setupAttribute({
        chaseId,
        entityId,
        entityTypeId,
        disabled: true,
        disableControl: true,
        workflowStatusName,
        singleEntity,
        input: this.idInput,
        ATTRIBUTE_DATA: CLAIM_ID,
        hidden: this.isCoderEncounter,
      });

      this.setupAttribute({
        chaseId,
        entityId,
        entityTypeId,
        disabled,
        disableControl: !(enableForm && !isAdminEncounter),
        workflowStatusName,
        singleEntity,
        input: this.encounterTypeInput,
        ATTRIBUTE_DATA: ENCOUNTER_TYPE,
      });
      this.encounterTypeInput.options = state.encounterTypes;
      this.encounterTypeInput.isAdmin = this.encounterTypeInput.saveInfo.isAdmin;

      this.setupAttribute({
        chaseId,
        entityId,
        entityTypeId,
        disabled: !(enableForm),
        workflowStatusName,
        singleEntity,
        input: this.exemptCheckbox,
        ATTRIBUTE_DATA: EXEMPT_FROM_SCORING,
        hidden: this.isEmployeeRole ? true : !this.isOverread,
      });
      this.form.controls.exemptCheckbox.setValue(this.encounter.exempt);

      this.setupAttribute({
        chaseId,
        entityId,
        entityTypeId,
        disabled: true,
        disableControl: true,
        workflowStatusName,
        singleEntity,
        input: this.nlpReviewResultInput,
        ATTRIBUTE_DATA: NLP_REVIEW_RESULT,
      });

      this.setupAttribute({
        chaseId,
        entityId,
        entityTypeId,
        disabled,
        workflowStatusName,
        singleEntity,
        input: this.pageNumberInput,
        ATTRIBUTE_DATA: CHART_PAGE_NUMBER,
        hidden: isAdminEncounter,
      });

      this.setupAttribute({
        chaseId,
        entityId,
        entityTypeId,
        disabled: disabled || this.encounter.isBotSource,
        disableControl: !(enableForm && !isAdminEncounter),
        workflowStatusName,
        singleEntity,
        input: this.startDateInput,
        ATTRIBUTE_DATA: FROM_DATE,
        hidden: isAdminEncounter,
      });

      this.setupAttribute({
        chaseId,
        entityId,
        entityTypeId,
        disabled: disabled || this.encounter.isBotSource,
        disableControl: !(enableForm && !isAdminEncounter),
        workflowStatusName,
        singleEntity,
        input: this.endDateInput,
        ATTRIBUTE_DATA: THRU_DATE,
        hidden: isAdminEncounter,
      });

      this.setupAttribute({
        chaseId,
        entityId,
        entityTypeId,
        disabled,
        disableControl: !((enableForm && enableServiceProvider) || !isAdminEncounter),
        workflowStatusName,
        singleEntity,
        input: this.providerInput,
        ATTRIBUTE_DATA: PROVIDER_ID,
        hidden: true,
      });
      this.providerInput.placeholder = "Select...";
      this.providerInput.options = state.providers.map(this.automapper.curry("Provider", "SelectableInput"));

      if (this.selectedEncounterIndex !== state.selectedEncounterIndex) {
        this.selectedEncounterIndex = state.selectedEncounterIndex;
      }

      const restrictEncounterPageJump = this.localService.get(LOCALSTORAGE_RESTRICTENCOUNTERPAGEJUMP.key, null);
      if (restrictEncounterPageJump === "0") {
        this.documentViewerSessionService.updateDataEntryPageNumberEncounterSelected(
          this.encounter.pageNumber, this.encounter.encounterFound, state.hasSelectedDiagnosisIndex);
      }
    }
  }

  private setupAttribute({
    input,
    ATTRIBUTE_DATA,
    singleEntity,
    chaseId,
    entityId,
    entityTypeId,
    disabled,
    disableControl,
    workflowStatusName,
    hidden = false,
  }: any): void {
    const attribute = {
      ...RiskHelper.getAttribute(ATTRIBUTE_DATA.attributeCode, singleEntity),
      ...ATTRIBUTE_DATA,
      chaseId,
      entityId,
      entityTypeId,
    };

    input.hidden = hidden;
    input.saveInfo = attribute;
    input.value = attribute.value;
    input.disabled = disabled;
    input.readonly = disableControl;
    input.isChanged = attribute.isChanged;
    input.workflowStatusName = workflowStatusName;
    const control = this.form.get(input.getMasterKey());
    (control as any).saveInfo = attribute;
    control.setValue(attribute.value);
    disabled ? control.disable() : control.enable();
  }

  private persistAttributes(attributes: DynamicEntityAttribute[]): void {
    this.extendAttributes(attributes);
    this.saveAttributes(attributes);
  }

  private deleteEveEncounter(): void {
    const encounterHasBeenSaved = NumberHelper.isGreaterThan(this.selectedEncounter.entityId, 0);

    if (encounterHasBeenSaved) {
      this.deleteEveEncounterFromServer();
      return;
    }

    this.deleteEveEncounterFromState();
  }

  private deleteEveEncounterFromServer(): void {
    const attributes = this.selectedEncounter.attributes;
    const processChildEntity = this.hasDiagnoses ? 1 : 0;

    this.chartService.deleteEncounter(attributes, processChildEntity).subscribe(
      () => this.deleteEveEncounterFromState(),
      () => this.messagingService.showMessage("Try to delete the encounter again.", SeverityType.ERROR)
    );
  }

  private deleteEveEncounterFromState(): void {
    this.riskService.deleteEncounter(this.selectedEncounterIndex, this.isEve);
  }

  private extendAttributes(attributes: DynamicEntityAttribute[]): void {
    const isEveEncounter = this.encounter.isBotSource && this.encounter.isNlp;

    if (isEveEncounter && !attributes.some(attr => attr.attributeCode === NLP_REVIEW_RESULT.attributeCode)) {
      attributes.push(RiskHelper.getAttribute(NLP_REVIEW_RESULT.attributeCode, this.selectedEncounter));
    }

    if (isEveEncounter && !attributes.some(attr => attr.attributeCode === FROM_DATE.attributeCode)) {
      attributes.push(RiskHelper.getAttribute(FROM_DATE.attributeCode, this.selectedEncounter));
    }

    if (isEveEncounter && !attributes.some(attr => attr.attributeCode === THRU_DATE.attributeCode)) {
      attributes.push(RiskHelper.getAttribute(THRU_DATE.attributeCode, this.selectedEncounter));
    }

    if (isEveEncounter && !attributes.some(attr => attr.attributeCode === CHART_PAGE_NUMBER.attributeCode)) {
      attributes.push(RiskHelper.getAttribute(CHART_PAGE_NUMBER.attributeCode, this.selectedEncounter));
    }

    if (isEveEncounter && !attributes.some(attr => attr.attributeCode === PROVIDER_ID.attributeCode)) {
      attributes.push(RiskHelper.getAttribute(PROVIDER_ID.attributeCode, this.selectedEncounter));
    }

    if (isEveEncounter && !attributes.some(attr => attr.attributeCode === ENCOUNTER_TYPE.attributeCode)) {
      attributes.push(RiskHelper.getAttribute(ENCOUNTER_TYPE.attributeCode, this.selectedEncounter));
    }

  }

  private deleteAttributesByInputs(controls: DynamicInput[]): void {
    const allControlsDisabled = controls.every(a => a.disabled);
    if (!allControlsDisabled) {
      const attributesToDelete = controls
        .filter(this.isAvailableAttribute.bind(this))
        .map(this.getDeleteAttribute.bind(this)) as DynamicEntityAttribute[];
      this.saveAttributes(attributesToDelete);
    }
  }

  private isAvailableAttribute(attribute: DynamicInput): boolean {
    return attribute != null && attribute.saveInfo != null && NumberHelper.isGreaterThan(attribute.saveInfo.attributeId, 0);
  }

  private getSaveAttribute(control: DynamicInput): DynamicEntityAttribute {
    const saveAttribute = (control as any).saveInfo as DynamicEntityAttribute;
    saveAttribute.value = control.value;
    return saveAttribute;
  }

  private getDeleteAttribute(control: DynamicInput): DynamicEntityAttribute {
    const deleteAttribute = this.getSaveAttribute(control);
    if (!deleteAttribute.isAdmin) {
      deleteAttribute.value = "";
      deleteAttribute.pageNumber = null;
      deleteAttribute.validationId = null;
      deleteAttribute.validationNote = "";
    }
    return deleteAttribute;
  }

  private saveAttributes(attributes: DynamicEntityAttribute[]): void {
    this.riskService
      .save(attributes, true)
      .pipe(
        finalize(() => this.isSaving = false)
      )
      .subscribe(
        newAttributes => {
          this.riskService.setEncounterAttributes(this.selectedEncounterIndex, [this.nlpReviewResultAttribute, ...newAttributes]);
          if (!this.isValidEncounter) {
            const chaseId = this.riskService.data.value.chaseId;
            this.riskService.refreshData(chaseId, true);
          }
        },
        (e: HttpErrorResponse) => this.form.setErrors({ saveError: e.error })
      );
  }

  private tryEnablingSaveButton(): void {
    this.isSaveEnabled = !this.isSaving && this.form.valid;
  }

  private disableSaveButton(saving = true): void {
    this.isSaving = saving;
    this.isSaveEnabled = false;
  }
}
