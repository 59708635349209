import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { List } from "immutable";
import { SelectItem } from "primeng/api";
import { map, tap } from "rxjs/operators";
import { SubSink } from "subsink";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../core/user/user.service";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { CheckboxGroup } from "../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { DynamicInput } from "../../../../dynamic-forms/inputs/dynamic-input.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../dynamic-forms/inputs/textbox/textbox.model";
import { GridView } from "../../../../shared/grid/grid-menu/grid-views/grid-view.model";
import { GridViewsState } from "../../../../shared/grid/grid-menu/grid-views/grid-views-state.model";
import { GridViewsService } from "../../../../shared/grid/grid-menu/grid-views/grid-views.service";
import { GridPipeName } from "../../../../shared/grid/grid-pipe.enum";
import { GridStateService } from "../../../../shared/grid/grid-state.service";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { GridFilter } from "../../../../shared/grid/models/grid-filter.model";
import { GridRequest } from "../../../../shared/grid/models/grid-request.model";
import { ServerGridComponent } from "../../../../shared/grid/server-grid/server-grid.component";
import { LandingFilterStateService } from "../../../../shared/kpi/kpi-filter/landing-filter/landing-filter-state.service";
import { CreatePendService } from "../../../../shared/pend/create-pend.service";
import { PendStatus } from "../../../../shared/pend/pend-status.enum";
import { IOnUpload, UploadComponent } from "../../../../shared/upload/upload.component";
import { ArrayHelper } from "../../../../utilities/contracts/array-helper";
import { DateFormats } from "../../../../utilities/contracts/helper-types";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";
import { ActionButton } from "../../../../zdevcontrols/action-button/action-button.model";
import { DevControllerService } from "../../../../zdevcontrols/dev-controller/dev-controller.service";
import { ChaseItem } from "../../../api/chase-search/chase-search-result-item";
import { DocumentService } from "../../../api/document/document.service";
import { MedicalRecordUploadService } from "../../../api/medical-record-upload/medical-record-upload.service";
import { ClinicalPageService } from "../../clinical/clinical-page/clinical-page.service";
import { CHASE_UPLOAD_GRID } from "../../member/chase-detail/chase-detail-chart/attributes";
import { RetrievalPageService } from "../retrieval-page/retrieval-page.service";
import { ChaseUploadDocumentService } from "./chase-upload-document.service";

@Component({
  selector: "retrieval-chase-upload-document",
  templateUrl: "./chase-upload-document.component.html",
  styleUrls: ["./chase-upload-document.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaseUploadDocumentComponent implements OnInit, OnDestroy {

  @ViewChild(ServerGridComponent, { static: true }) serverGridComponent: ServerGridComponent;

  backButtonText = "Go Back";
  chaseRequiredDocumentItems = List<string>();
  private retrievalId: number;
  chaseGridConfiguration = new GridConfiguration();
  chaseGridData: any[] = [];
  chaseGridSelection: ChaseItem;
  startRecord: number;
  endRecord: number;
  assignedTo: number;
  private AllRecords = 2147483647;
  private functionalRole: number;
  private documentRetrieval = 5;
  private pageSize = 10;
  private documentRetrievalLead = 4;
  private PSRUpload = 8;
  private EMRUpload = 1;
  private FieldTechUpload = 4;
  private thirdPartyUpload = 15;
  isdisplay = false;
  isCreatePendView = false;
  isUploadReady = false;
  isPendReady = false;
  chaseIds: number[] = [];
  pendStatusId: number;
  selectedFileList: any[] = [];
  retrievaltypeId: number;
  formData: FormData = new FormData();
  requiredDocumentsForm: FormGroup;
  requirementsOptions: SelectItem[];
  selectedRequirements: string;
  selectAllButtonOptions: SelectItem[];
  selectedButton: any;
  selectedAllButton = [];
  isPendModalVisible = false;
  selectedChases: any[];
  uploadSuccessMessage = "Thank you for uploading this medical record.  We are now processing the file.  Please check back shortly and your document will be attached.";
  views: GridViewsState;
  request: GridRequest;
  private sink = new SubSink();
  refreshViews = new EventEmitter<GridView>(true);
  refreshGrid = new EventEmitter<null>(true);
  landingFilter: any;
  @ViewChild("fileUpload", { static: true }) fileUpload: UploadComponent;
  private usePresignedURLs: boolean;


  getInput<T extends DynamicInput>(key: string): T {
    if (this.request == null) {
      return null;
    }

    return this.request.getInput<T>(key);
  }

  setInput<T extends DynamicInput>(key: string, value: T): void {
    if (this.request == null) {
      return null;
    }

    this.request.setInput<T>(key, value);
  }

  get assignedToInput(): Autocomplete {
    return this.getInput("assignedToUserId");
  }
  set assignedToInput(value: Autocomplete) {
    this.setInput("assignedToUserId", value);
  }

  get pendCode(): CheckboxGroup {
    return this.getInput("pendCodeList") as CheckboxGroup;
  }

  set pendCode(value: CheckboxGroup) {
    this.setInput("pendCodeList", value);
  }

  get pendStatus(): CheckboxGroup {
    return this.getInput("pendStatus") as CheckboxGroup;
  }

  set pendStatus(value: CheckboxGroup) {
    this.setInput("pendStatus", value);
  }

  get projectsInput(): CheckboxGroup {
    return this.getInput("ProjectList");
  }

  set projectsInput(value: CheckboxGroup) {
    this.setInput("ProjectList", value);
  }

  get measuresInput(): CheckboxGroup {
    return this.getInput("MeasureList");
  }

  set measuresInput(value: CheckboxGroup) {
    this.setInput("MeasureList", value);
  }

  get isLandingFilterApplied(): boolean {

    return NumberHelper.isAvailable(this.landingFilter.clientId) || NumberHelper.isAvailable(this.landingFilter.projectId);
  }

  constructor(
    private service: ChaseUploadDocumentService,
    private readonly devService: DevControllerService,
    private userService: UserService,
    private medicalRecordUploadService: MedicalRecordUploadService,
    private documentService: DocumentService,
    private messagingService: MessagingService,
    private changeDetector: ChangeDetectorRef,
    private gridViewsService: GridViewsService,
    private readonly formService: FormService,
    private readonly gridStateService: GridStateService,
    private readonly landingFilterStateService: LandingFilterStateService,
    private readonly createPendService: CreatePendService,
    private readonly automapper: AutomapperService,
    private readonly retrievalPageService: RetrievalPageService,
    private readonly clinicalPageService: ClinicalPageService,
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private fb: FormBuilder
  ) {
  }

  ngOnInit() {
    this.devService.push([new ActionButton({ name: "Refresh Grid", action: () => this.refreshGrid.emit() })]);
    const documentSourceType = this.service.getRetrievalTypeIdFromPath().toString();

    switch (documentSourceType) {
      case "1": this.retrievaltypeId = this.PSRUpload;
                break;

      case "2": this.retrievaltypeId = this.EMRUpload;
                break;

      case "3": this.retrievaltypeId = this.FieldTechUpload;
                break;

      case "4": this.retrievaltypeId = this.thirdPartyUpload;
                break;

      default: break;

    }

    this.sink.add(
      this.refreshViews.subscribe(gridView => this.getViews(gridView))
    );
    this.functionalRole = this.documentRetrieval;
    this.requiredDocumentsForm = this.fb.group({
      RequiredDoc: new FormArray([]),
      isSelectAll: new FormControl(),
    });
    this.createGrid();
    this.usePresignedURLs = this.userService.getUserToken().isPreSignedUrlUploadEnabled;
  }

  private createGrid(): void {
    this.retrievalId = this.service.getRetrievalIdFromPath();
    const user = this.userService.getUserToken();
    this.startRecord = 1;
    this.endRecord = this.AllRecords;
    this.assignedTo = (user.functionalRoleIds.indexOf(this.documentRetrievalLead) > -1) ? null : user.userId;

    this.chaseGridConfiguration.columns = [
      new GridColumnDefinition({ field: "chaseID", header: "Chase" }),
      new GridColumnDefinition({ field: "memberFirstName", header: "First" }),
      new GridColumnDefinition({ field: "memberLastName", header: "Last" }),
      new GridColumnDefinition({ field: "memberDateOfBirth", header: "DOB", pipeName: GridPipeName.Date, format: DateFormats.GRID_DATE_FORMAT }),
      new GridColumnDefinition({ field: "measureCode", header: "Measure" }),
      new GridColumnDefinition({ field: "serviceProviders", header: "Provider Name" }),
      new GridColumnDefinition({ field: "projectName", header: "Project" }),
      new GridColumnDefinition({ field: "documentRequestId", header: "Doc Request ID" }),
      new GridColumnDefinition({ field: "projectReviewPeriod", header: "Review Period" }),
      new GridColumnDefinition({ field: "workflowStatusName", header: "Status" }),
      new GridColumnDefinition({ field: "assignedTo", header: "Assigned To" }),
      new GridColumnDefinition({ field: "pendStatus", header: "Pend Status" }),
      new GridColumnDefinition({ field: "pendCode", header: "Pend Code" }),
    ];

    this.chaseGridConfiguration.pageSize = this.pageSize;
    this.chaseGridConfiguration.pageSizeOptions = [10, 25, 50, 100];
    this.chaseGridConfiguration.selectionMode = "single";
    this.chaseGridConfiguration.showActionColumn = false;
    this.chaseGridConfiguration.viewAttributeId = CHASE_UPLOAD_GRID.attributeId;
    this.chaseGridConfiguration.stateName = CHASE_UPLOAD_GRID.attributeCode;
    this.chaseGridConfiguration.showViews = true;


    this.requirementsOptions = [
      { label: "No", value: 0 },
      { label: "Yes", value: 1 },
    ];

    this.selectAllButtonOptions = [
      { label: "Select All: NO", value: 0 },
      { label: "Select All: YES", value: 1 },
    ];

    this.request = new GridRequest({
      url: `${this.baseApiUrl}chase/list`,
      filters: this.getFilters(),
    });

    this.getViews();
    this.getDropdownInfo();
  }

  private getDropdownInfo(): void {
    this.getPendCodes();
    this.getPendStatus();
    this.getProjects();
    this.getMeasures();
    this.getAssignedToUsers();
  }

  private getFilters(): GridFilter[] {
    this.landingFilter = this.landingFilterStateService.get();
    if (this.isLandingFilterApplied
      ) {
      this.gridStateService.delete(CHASE_UPLOAD_GRID.attributeCode);
    }
    this.landingFilterStateService.clear();

    return [
      new GridFilter({
        input: new Textbox(),
        key: "functionalRoleId",
        name: "Functional Role",
        value: (this.functionalRole)?.toString(),
        show: false,
      }),
      new GridFilter({
        input: new Textbox(),
        key: "documentSourceId",
        name: "Retrieval Id",
        value: (this.retrievalId)?.toString(),
        show: false,
      }),
      new GridFilter({
        input: new Autocomplete({ placeholder: "Select User..." }),
        key: "assignedToUserId",
        name: "Assigned To",
        value: (this.assignedTo)?.toString(),
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "pendStatus",
        name: "Pend Status",
      }),
      new GridFilter({
        input: new CheckboxGroup({showTooltip: true}),
        key: "pendCodeList",
        name: "Pend Code",
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "ProjectList",
        value: (this.landingFilter.projectId || "").toString(),
      }),
      new GridFilter({
        input: new CheckboxGroup(),
        key: "MeasureList",
        name: "Measure",
      }),
    ];
  }

  private getViews(gridView: GridView | null = null): void {
    if (this.chaseGridConfiguration.showViews) {
      this.gridViewsService.get(this.chaseGridConfiguration.viewAttributeId).subscribe(views => {
        this.views = views;
        if (gridView != null) {
          setTimeout(() => this.serverGridComponent.onViewSelect.emit(gridView));
        }
        this.changeDetector.markForCheck();
      });
    }
  }

  displayDocumentList(event, fileUpload) {
    this.chaseIds = [];
    this.isUploadReady = false;
    this.resetRequiredDocSection();
    const selectedRows = this.chaseGridSelection;
    if (selectedRows != null) {
      this.getRequiredDocumentsForChase(selectedRows.chaseID);
      this.chaseIds.push(selectedRows.chaseID);
      this.pendStatusId = selectedRows.pendStatusId;
    }
    if (event !== null && event.length !== 0) {
      this.isdisplay = event;
    }
  }

  private getPendCodes(): void {
    this.createPendService
      .getPendDropdown(false)
      .pipe(map((result: any) => {
        return result.pendCodes.map(item => new SelectableInput({
          text: `${item.displayName.split("-")[0]} - ${item.displayName.split("-")[1]}`,
          value: item.displayName.split("-")[0],
          extra: item.description,
        }));
      }))
      .subscribe(options => {
        this.pendCode = new CheckboxGroup({ ...this.pendCode, options } as any);
        this.formService.updateDom.next();
      });
  }

  private getPendStatus(): void {
    this.createPendService
      .getPendStatus(false)
      .pipe(map((result: any) => {
        return result.pendStatus.map(item => new SelectableInput({
          text: item.description,
          value: item.description,
        }));
      }))
      .subscribe(result => {
        this.pendStatus = new CheckboxGroup({ ...this.pendStatus, options: result } as any);
        this.changeDetector.markForCheck();
      });
  }

  private getProjects(): void {
    this.retrievalPageService
      .getProjectList()
      .pipe(map((result: any) => {
        return result.map(item => new SelectableInput({
          text: item.description,
          value: item.id,
        }));
      }))
      .subscribe(options => {
        this.projectsInput = new CheckboxGroup({ ...this.projectsInput, options } as any);
        this.projectsInput.label = "Project";
        this.setControlValue(this.projectsInput, false);
        this.formService.updateDom.next();
      });
  }

  private getMeasures(): void {
    this.clinicalPageService
      .getMeasuresList()
      .pipe(map(this.automapper.curryMany("ClinicalMeasureListItem", "SelectableInput")))
      .subscribe(options => {
        this.measuresInput = { ...this.measuresInput, options } as any;
        this.setControlValue(this.measuresInput, false);
        this.formService.updateDom.next();
      });
  }

  private setControlValue(control: CheckboxGroup, isStringValue = true): void {
    const filter = this.request.getFilter(control.key);

    if (StringHelper.isAvailable(filter.input.value)) {
      const statuses = filter.input.value.split(",");
      statuses.forEach(item => {
        if (ArrayHelper.isAvailable(control.options)) {
          filter.inputValue.push(control.options.find(a => a.value === (isStringValue ? item : +item)));
        }
      });
      control.selectedOptions = filter.inputValue;
    }
  }

  private getAssignedToUsers(): void {
    this.userService
      .getUsersWithQuickOptions()
      .pipe(
        map(this.automapper.curryMany("UserModel", "SelectableInput")),
        tap(clients => clients.unshift(new SelectableInput({ text: "*clear filter", value: "" })))
      )
      .subscribe(options => {
        this.assignedToInput = { ...this.assignedToInput, options } as any;
        this.formService.updateDom.next();
      });
  }

  getRequiredDocumentsForChase(chaseId) {

    this.documentService
      .getDocumentsForChase(chaseId)
      .subscribe(items => {
        this.chaseRequiredDocumentItems = this.assignAndNotify(items);
        this.loadRequiredDocs();
      });
  }

  assignAndNotify<T>(data: T[]): List<T> {
    this.changeDetector.markForCheck();
    const dataList = List(data);

    return dataList;
  }

  private setSelectedChases(chaseGridSelection: any): void {
    this.selectedChases = ArrayHelper.isAvailable(chaseGridSelection) ? chaseGridSelection : [chaseGridSelection];
  }

  createPend(): void {
    if (this.pendStatusId === PendStatus.New || this.pendStatusId === PendStatus.InProgress) {
      this.messagingService.showToast("This chase is already Pended.", SeverityType.WARN);
    } else {
      this.setSelectedChases(this.chaseGridSelection);
      this.isPendModalVisible = true;
    }
  }

  get documentSelected(): boolean {
    return this.isUploadReady;
  }

  get pendSelected(): boolean {
    return this.isPendReady;
  }

  get uploadData(): KeyValuePairs<string> {
    if (this.chaseGridSelection == null || Object.keys(this.chaseGridSelection).length === 0 || this.retrievaltypeId == null || this.requiredDocumentsForm.controls.RequiredDoc.value == null) {
      return [];
    }

    const selectedRows = this.chaseGridSelection;

    return [
      { key: "ChaseId", value: selectedRows.chaseID.toString() },
      { key: "ProjectId", value: selectedRows.projectID.toString() },
      { key: "RetrievalTypeId", value: this.retrievaltypeId.toString() },
      { key: "DocumentSelectionList", value: JSON.stringify(this.requiredDocumentsForm.controls.RequiredDoc.value) },
    ];
  }

  uploadDocument(event: IOnUpload): void {
    if (this.usePresignedURLs) {
        let fileNum = 0;
        for (const item in event.originalFilenameList) {
          if (StringHelper.isAvailable(item)) {
            event.formData.append(`OFN${fileNum}`, event.originalFilenameList[fileNum]);
            fileNum++;
          }
        }
        fileNum = 0;
        for (const item in event.presignedURLList) {
            if (StringHelper.isAvailable(item)) {
              event.formData.append(fileNum.toString(), event.presignedURLList[item].toString());
              fileNum++;
            }}
        event.formData.append("NumFiles", event.presignedURLList.length.toString());
        event.formData.delete("Document");
        this.medicalRecordUploadService.uploadPresigned(event.formData).subscribe(
            data => {
              this.resetRequiredDocSection();
              this.changeDetector.markForCheck();
              this.messagingService.showToast(this.uploadSuccessMessage, SeverityType.SUCCESS);
              event.success();
              this.clearAllButton();
            },
            err => {
            }
          );
      } else {
        this.medicalRecordUploadService.upload(event.formData).subscribe(
          data => {
            this.resetRequiredDocSection();
            this.changeDetector.markForCheck();
            this.messagingService.showToast(this.uploadSuccessMessage, SeverityType.SUCCESS);
            event.success();
          },
          err => {
            this.messagingService.showToast("Error while uploading Document, please try again.", SeverityType.ERROR);
          }
        );
      }
  }

  resetRequiredDocSection(): void {
    this.fileUpload.primeUpload.clear();
    this.clearRequiredDocList(this.requiredDocumentsForm.get("RequiredDoc") as FormArray);
    this.isdisplay = false;
  }

  clearRequiredDocList(formArray) {

    let i = formArray.length;
    while (i--) {
      formArray.removeAt(i);
    }
  }

  private loadRequiredDocs() {
    this.chaseRequiredDocumentItems.forEach(data => {
      this.buildListOfRequiredDocs(this.requiredDocumentsForm.controls.RequiredDoc as FormArray, data);
    });
  }

  buildListOfRequiredDocs(formArray, data) {
    formArray.push(
      new FormGroup({
        documentName: new FormControl(data.documentName),
        isUploaded: new FormControl(),
        measureCode: new FormControl(data.measureCode),
      })
    );
  }

  checkButtonStatus() {
    this.selectedButton = null;
    const RequiredDocObj = this.requiredDocumentsForm.controls.RequiredDoc.value;

    const isRequiredList = RequiredDocObj.reduce((type, item) => {
      type[item.isUploaded] = type[item.isUploaded] || [];
      type[item.isUploaded].push(item);
      return type;
    },                                           {});

    if (isRequiredList.null && isRequiredList.null.length > 0) {
      this.isUploadReady = false;
      this.isPendReady = false;
    } else if (isRequiredList["1"] && isRequiredList["1"].length > 0) {
      this.isUploadReady = true;
      this.isPendReady = false;
    } else {
      this.isUploadReady = false;
      this.isPendReady = true;
    }
  }

  trackByIndex(index, item) {
    return index;
  }

  closePendModal(): void {
    this.isPendModalVisible = false;
    this.selectedChases = [];
  }

  selectAllButtons(event): void {
    this.selectedButton = event.value > 0 ? 1 : 0;
    if (this.selectedButton === 0) {
      this.isPendReady = true;
      this.isUploadReady = false;
    } else {
      this.isUploadReady = true;
      this.isPendReady = false;
    }
  }

  clearAllButton(): void {
    this.selectedAllButton = [];
    this.selectedButton = [];
    this.isUploadReady = false;
    this.isPendReady = false;
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }
}

